#jobs{
    border-top: solid 1px $color-black;
}
article.job{
    display: flex;
    border-bottom: solid 1px $color-black;
    padding: $space/2 0 35px 0;
    min-height: 100px;
    position: relative;
    cursor: pointer;
    
    
    h2{
        font-size: $font-size1;
        flex: 0 0 300px;
        margin: 0;
        padding: 0;
        margin-right: 50px;
    }
    div{
        flex: 0 0 200px;        
        margin-right: 50px;
        font-size: $font-size1;
        
        &.percent{
            flex: 0 0 250px;    
        }
        &.deadline{
            flex: 0 0 250px;    
        }
        &.lead{
            flex-grow: 2;
            max-width: 600px;
            font-size: $font-size3;
            
            .overflow {
                max-height: 58px;
                position: relative;
                overflow: hidden;
                display: block;
                /*
                &:after {
                    content: '';  
                    display: block;
                    width: 100%;
                    height: 10px;
                    background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 53%, rgba(255,255,255,1) 100%);
                    background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 53%,rgba(255,255,255,1) 100%);
                    background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 53%,rgba(255,255,255,1) 100%);
                    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );
                    position: absolute;
                    bottom: 0;
                    z-index: 10;
                }*/
            }
            .grey{
                color: $color-grey;
            }
        }
    }
}
#jobs-content,
#jobs-title{
    border-top: solid 1px $color-black;

    width: 100%;
    overflow: hidden;
    border-top: solid 1px $color-black;
    display: flex;

    h1{
        font-size: $font-size1;
        padding: $space $space $space*2 0;
        width: $scale6; 
        width: calc(#{$scale6} + 12px);
    }
    .right{
        padding: $space 0 $space*2 0;
        width: $scale6*5; 
        width: calc(#{$scale6*5} - 50px);
        max-width: 800px;
        font-size: $font-size1;
        
        &.notitle{
            margin-left: $scale6;
            margin-left: calc(#{$scale6} + 12px);
        }
        
        .description{
            font-size: $font-size3;
            margin-top: $space;
        }
        .contact{
            font-size: $font-size3;
            margin-top: $space;
        }
    }
}

@media(max-width:1300px){
    article.job{
        div{
            &.deadline{
                display: none;    
            }
        }
    }
}
@media(max-width:1000px){
    article.job{
        h2{
            flex: 0 0 60%;
        }
        div{
            flex: 0 0 30%;        
            margin-right: 50px;
            
            &.lead{
                display: none;    
            }
        }
    }
    
    #jobs-content,
    #jobs-title{
        display: block;
        h1{
            width: 100%; 
        }
        .right{
            width: 100%;
            &.notitle{
                margin-left: 0;
            }
        }
    }
}
@media(max-width:500px){
    article.job{
        h2{
            flex: 0 0 100%;
        }
        div{
            &.percent{
                display: none;    
            }
        }
    }
}
@media(max-width:450px){
    #jobs-content,
    #jobs-title{
        h1{
            font-size: $font-size2; 
        }
        .right{
            font-size: $font-size2; 
        }
        div{
            &.lead{
                font-size: $font-size2; 
            }
        }
    }
    article.job{
        h2{ 
            font-size: $font-size2; 
        }
    }
}