* {
    box-sizing: border-box;
}
html{
    min-height: 100%;
}

body {
    background: $color-white;
    font-family: $font;
    font-size: $font-size2;
    color: $color-black;
    overflow-x: hidden;
} 
.open-meta,
.close-meta{
    cursor: pointer;
}
.ml{
    margin-left: $space;
}
.mlr{
    margin-left: $space;
    margin-right: $space;
}
.mb{
    margin-bottom: $space;
}
.mt{
    margin-top: $space;
}
.mt2{
    margin-top: 2*$space;
}
.pt{
    padding-top: $space;
}
.pt2{
    padding-top: 2*$space;
}
.bt{
    border-top: solid 1px $color-black;
}
.font_s3{
    font-size: $font-size3;
}
main{
    ul{
        padding-left: $space;
    }
}
.pointer{
    cursor: pointer;
}
.loader{
    clear: both;
    text-align: center;
    padding: 50px;
}
@media(max-width:1200px){
}
@media(max-width:940px){
}
@media(max-width:850px){
}
@media(max-width:550px){
}
