body.shop{

    #shop {

    }
    .hidden {
        display: none;
    }
    .order,
    .intro
    {
        border-top: solid 1px $color-black;
        width: 100%;
        overflow: hidden;
        border-top: solid 1px $color-black;
        display: flex;
        padding-bottom: $space;
        @media(max-width:1000px){
            display: block;
        }
        h1{
            font-size: $font-size1;
            padding: $space 0 $space 0;
            width: $scale6;
            width: calc(#{$scale6} + 12px);
        }
        .right {
            padding: $space 0 $space*2 0;
            width: $scale6*5;
            width: calc(#{$scale6*5} - 50px);
            /*max-width: 800px;*/
            font-size: $font-size3;
            display: flex;
            @media(max-width:1000px){
                display: block;
                width: 100%;
            }
        }
    }
    .products{
        border-top: solid 1px $color-black;
        width: 100%;
        overflow: hidden;
        border-top: solid 1px $color-black;
        display: flex;
        padding-bottom: $space;
        @media(max-width:1000px){
            display: block;
        }
        h1{
            font-size: $font-size1;
            padding: $space 0 $space 0;
            width: $scale6; 
            width: calc(#{$scale6} + 12px);
            margin-right: 10px;
        }
        .right{
            padding: $space 0 $space*2 0;
            width: $scale6*5; 
            width: calc(#{$scale6*5} - 50px);
            /*max-width: 800px;*/
            font-size: $font-size3;
            display: flex;
            @media(max-width:1000px){
                display: block;
                width: 100%;
            }

            .cards-container {
                display: flex;
                flex-wrap: wrap;
                gap: 10px; /* Adjust based on your design */
                margin-left: -10px;
                @media(max-width:1000px){
                    display: block;
                    margin-left: 0;
                }
            }

            article {
                border-radius: 12px;

                /*flex: 0 0 400px; Default card width */
                flex: 0 0 400px; /* Grow and shrink from this base */
                /*max-width: 400px; Maximum width */
                background-color: #eeeeee; /* For visibility */
                /* margin-bottom: 10px; /* Space between rows of cards */
                padding: 10px; /* Space inside the cards */

                @media(max-width:1000px) {
                    display: block;
                    margin-bottom: 24px;
                }

                h2 {
                    /*margin-bottom: 10px;*/
                    margin-top: 10px;
                }

                .lead,
                .description
                {
                    margin-bottom: 10px;
                }


                img {
                    width: 100%; /* Make the image fill the card */
                    height: auto; /* Prevent distortion */
                    border-radius: 12px;
                }
                .shop-table {
                    display: table;
                    width: 100%; /* Adjust based on your preference */
                    /*border-collapse: collapse;*/
                    /*border: 1px #a6a6a6 solid;*/
                    border-radius: 8px;
                    padding: 2px;
                    background-color: #e0e0e0;
                    .shop-quantity-container {
                        display: table-row;

                        @media(max-width:600px){
                            font-size: 16px;
                        }



                        .shop-cell {
                            display: table-cell;
                            padding: 8px; /* Adjust padding as needed */
                            /*border: 1px solid #ccc; /* Optional: adds a border around cells */
                            white-space: nowrap;
                        }
                        .input {
                            width: 100%;
                            @media(max-width:600px){
                                font-size: 16px;
                            }
                        }

                        .shop-quantity-input {
                            width: 100%; /* Make the input field fill the cell */
                            max-width: 54px;
                            padding: 4px;
                            @media(max-width:600px){
                                font-size: 16px;
                            }
                        }
                        .shop-quantity-label {
                            white-space: nowrap; /* Prevents the content from wrapping */
                            width: auto;
                        }
                        .shop-quantity-input {

                        }
                        .shop-quantity-currency {

                        }
                    }
                }



            }

            .card-filler {
                flex: 0 0 200px;
                visibility: hidden; /* Makes fillers invisible */
            }


            /* Media queries for responsive card widths */

            @media (max-width: 800px) {
                article, .card-filler {
                    /*flex: 0 0 150px; /* Smaller card width on smaller screens */
                }
            }

            @media (max-width: 500px) {
                article, .card-filler {
                    display: block;
                    /*flex: 0 0 100px; /* Even smaller card width on very small screens */
                }
            }








        }
        .full{
            padding: $space 0 $space*2 0;
            width: $scale6*5; 
            width: calc(#{$scale6*5} - 50px);
            font-size:  $font-size1;

            .right{
                padding: 0;
            }
        }
        /*
        article{
            margin-bottom: $space;
            margin-right: 40px;
            h2{
                font-size: $font-size1;
                margin: 0 0 $space;
            }
            p{
                font-size: 12px;
                overflow: hidden;
            }
            a.document, a.link{
                color: $color-grey;
                font-size: $font-size3;
                margin-top: 10px;
                display: block;
            }
        }

         */

    }

    .form-container {
        max-width: 630px;
        .separator {
            display: block;
            border-bottom: 1px solid #000;
            height: .001px;
            padding-top: 27px;
            margin-bottom: 20px;
        }
    }

    .orderintro,
    {
        /*max-width: 800px;*/
    }


}

