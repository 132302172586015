body.story,
body.storydetail{
    background: $color-black;
    color: $color-white;
    
    header{
        nav.main{
            /*background: rgba(0,0,0,0.5);*/
        }
        
        nav.context{
            ul{
                li{
                    a {
                        &.selected {
                            color: $color-white;
                        }
                    }
                }
            }
        }
    }
    
    section {
        &.layer{
           background: $color-black; 
        }
    }
    
    a {
        color: $color-white;
    }
}
#history{
    width: 100%;
    overflow: hidden;
    border-top: solid 1px $color-white;
    display: flex;

    h1{
        font-size: $font-size1;
        padding: $space 0 $space*2 0;
        width: $scale6; 
        width: calc(#{$scale6} + 12px);
    }
    .right{
        padding: $space 0 $space*2 0;
        width: $scale6*5; 
        width: calc(#{$scale6*5} - 50px);
        max-width: 800px;
        font-size:  $font-size1;
        
        .content-image{
            margin-bottom: $space;
            max-width: 100%;
        }
        
        a {
            color: $color-white;
        }
    }
}
#history2,
#institutions,
#features{
    width: 100%;
    overflow: hidden;
    border-top: solid 1px $color-white;
    
    h1{
        font-size: $font-size1;
        padding: $space 0 $space*2 0;
        width: 100%; 
    }
    .full{
        padding: $space 0 $space*2 0;
        width: 100%; 
        font-size:  $font-size1;
        
        a {
            color: $color-white;
        }
    }
    .featurerow{
        border-top: solid 1px $color-white;
        padding: 15px 0;
        font-size: $font-size1;
        display: flex;

        a{
            flex-grow: 2; 
            display: inline-block;

            &.name{
                flex: 30% 0 0;
                margin-right: $space;
            }
        }
    }
    .institutionbox{
        margin-right: 25px;
        margin-left: 25px;
        margin-bottom: 50px;
        width: $scale6; // 6 tiles
        width: calc(#{$scale6} - 50px);
        display: inline-block;

        .img-container{
            width: 100%;
            padding-top:68%;
            background: $color-grey;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
        }
        .name{
            display: flex;
            margin-top: $space/2;

            span{
                flex-grow: 2;

                &.time{
                    flex: 30% 0 0;
                    margin-right: $space;
                }
            }
        }
    }
}
#history2{
    .full{
        font-size:  $font-size3;
    }
}
#features{
    .full{
        padding-bottom: 0;
        border-bottom: solid 1px $color-white;
    }
}
#institutions{
    .full{
        width: 100%;
        width: calc(100% + 50px);
        margin-left: -25px;
        margin-right: -25px;
    }
}
#institutions-close{
    position: absolute;
    top: $space;
    right: $space;
    z-index: 560;
    
    svg {
        fill: $color-white;
    }
}
#institutions-title{
    position: absolute;
    top: $space;
    left: 50px;
    z-index: 510;
    
    h1{
        font-size:  $font-size1;
        text-shadow: 1px 1px 3px $color-grey4;
    }
}
#institutions-content{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 500;
    
    .bx-wrapper { 
        box-shadow: 0;
        border: 0;
        background: $color-black;
        margin: 0;
        
        .bx-next{
            right: 20px;
            background: url('/dist/angle-right-white.svg')  no-repeat 50% 50%;
        }
        .bx-prev{
            left: 20px;
            background: url('/dist/angle-left-white.svg')  no-repeat 50% 50%;
        }
        .bx-caption{
            bottom: $space;
            position: absolute;
            z-index: 555;
            background: none;
            
            span{
                font-size: $font-size3;
                color: $color-white;
                max-width: 800px;
                margin: auto;
            }
        }
    }

    .fullscreen{
        height: 100vh;
        width: 100vw;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
    .center{
        height: 100vh;
        width: 100vw;
        position: relative;
        
        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            max-width: 90%;
            max-height: 90%;
        }
    }
    .text{
        height: 100vh;
        width: 100vw;
        overflow-y: auto;
        
        .overflow{
            padding: 0 100px;
            max-width: 1000px;
            margin: 100px auto 100px auto;
        }
        
        small{
            font-size: $font-size3;
        }
    }

}
@media(max-width:2800px){
    #institutions{
        .institutionbox{
            width: $scale5; 
            width: calc(#{$scale5} - 50px);
        }
    }
}
@media(max-width:2000px){
    #institutions{
        .institutionbox{
            width: $scale4; 
            width: calc(#{$scale4} - 50px);
            margin-right: 25px;
        }
    }
}
@media(max-width:1600px){
    #institutions{
        .institutionbox{
            width: $scale3; 
            width: calc(#{$scale3} - 50px);
        }
    }
}
@media(max-width:1200px){
    #institutions{
        .institutionbox{
            width: $scale2; 
            width: calc(#{$scale2} - 50px);
        }
    }
}
@media(max-width:750px){
    #institutions{
        .institutionbox{
            width: $scale1; 
            width: calc(#{$scale1} - 50px);
        }
    }
    #history{
        display: block;

        h1{
            width: 100%; 
        }
        .right{
            width: 100%; 
        }
    }
}
@media(max-width:600px){
    #history2,
    #history2,
    #institutions,
    #features{
        h1{
            font-size: $font-size2;
        }
        .full{
            font-size:  $font-size2;
        }
        .featurerow{
            font-size: $font-size2;
        }
    }
}