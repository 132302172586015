



@charset "UTF-8";


@font-face {
    font-family: "netzhdk";
    src: url("fonts/netzhdk.eot");
    src: url("fonts/netzhdk.eot?#iefix") format("embedded-opentype"),
         url("fonts/netzhdk.woff2") format("woff2"),
         url("fonts/netzhdk.woff") format("woff"),
         url("fonts/netzhdk.ttf") format("ttf"),
         url("fonts/netzhdk.svg#netzhdk") format("svg");
    font-style: normal;
    font-weight: normal;
}

@import url("//hello.myfonts.net/count/3af01d");
@font-face {    font-family: 'TimesLTStd-Semibold';src: url('fonts/3AF01D_0_0.eot');src: url('fonts/3AF01D_0_0.eot?#iefix') format('embedded-opentype'),url('fonts/3AF01D_0_0.woff2') format('woff2'),url('fonts/3AF01D_0_0.woff') format('woff'),url('fonts/3AF01D_0_0.ttf') format('truetype');}
 

/*** main *********************************************************************/
$background-color:                      #fff;

$font:                                  'TimesLTStd-Semibold', serif;
$font2:                                  'netzhdk', serif;
$font-size1:                             25px;
$font-size2:                             20px;
$font-size3:                             16px;

$space:                                 20px;


/*** colors *******************************************************************/
$color-black:                           #000000;
$color-grey:                            #999999;
$color-white:                           #ffffff;
$color-green:                           #00ff00;
$color-rose:                            #ffb1b1;
$color-blue:                            #0000cc;

$color-grey1:                           #e5e5e5;
$color-grey2:                           #cccccc;
$color-grey3:                           #999999;
$color-grey4:                           #666666;
$color-grey5:                           #333333;

$color-highlight:                       $color-green;


$scale6: 16.666%;
$scale5: 20%;
$scale4: 25%;
$scale3: 33.333%;
$scale2: 50%;
$scale1: 100%;
