/* calendar */

.ui-datepicker,
.ui-datepicker table,
.ui-datepicker tr,
.ui-datepicker td,
.ui-datepicker th {
    margin: 0;
    padding: 0;
    border: none;
    border-spacing: 0;
}
.ui-datepicker {
    display: none;
    width: 400px;
    padding: 0;
    cursor: default;

    font-family: 'TimesLTStd-Semibold', serif;
    font-size: $font-size1;

    border-radius: 0;
    border: solid 1px $color-black;
}
.ui-widget.ui-widget-content{
    border: 0;
}
.ui-datepicker-header {
    position: relative;
    padding-bottom: 10px;
    border-radius: 0;
    border: 0;
    border-top: 1px solid $color-black;
    border-bottom: 1px solid $color-black;
    background: none;
    color: $color-black;
}
.ui-datepicker-title { 
    position: relative;
    text-align: center; 
}
.ui-datepicker{
    .ui-datepicker-title { 
        line-height: 31px;
    }
}

.ui-datepicker-month {
    position: relative;
    padding-right: 20px;
    font-weight: normal;
}
.ui-datepicker-year {
    padding-left: 20px;
    font-weight: normal; 
}
select.ui-datepicker-month,
select.ui-datepicker-year{
    font-family: $font;
    font-size: 14px;
    background: $color-white;
    color: $color-black;
}
.ui-datepicker .ui-datepicker-title select{
    font-size: 14px;
}
.ui-datepicker{ 
    .ui-datepicker-prev,
    .ui-datepicker-next{
        position: absolute;
        top: 0px;
        padding: 0;
        cursor: pointer;
        height: 30px;
        width: 30px;

        span{
            display: block;
            width: 34px;
            height: 34px;
            text-indent: -9999px;
            background-size: contain;
            background-position: center center;
            position: relative;
            margin: 0;
            top: 4px;
            left: auto;
        }

        &.ui-state-hover{
            background:  none;
            border: none;
            opacity: 0.5;
        }
    }
    .ui-datepicker-prev {
        left: 5px;
        padding-left: 0;
    }

    .ui-datepicker-next {
        right: 5px;
        padding-right: 0;
    }
}
.ui-widget-header {
    .ui-datepicker-prev span.ui-icon { 
        background-image: url('/dist/angle-left.svg');
    }

    .ui-datepicker-next span { 
        background-image: url('/dist/angle-right.svg');
    }
}



.ui-datepicker-calendar th {
    padding-top: 15px;
    padding-bottom: 10px;

    text-align: center;
    font-weight: normal;
    color: lighten($color-black, 30%);
}
.ui-datepicker-calendar td {
    padding: 0 7px;

    text-align: center;
    line-height: 26px;
}


.ui-datepicker-calendar{
    td{
        padding: 0 7px;

        text-align: center;
        line-height: 26px;

        a {
            display: block;
            outline: none;
            width: 34px;
            height: 34px;
            padding: 4px;
            line-height: 27px;

            text-align: center;
            border-radius: 50%;
            background: $color-black;
            margin: auto;

            &.ui-state-default {
                color: $color-black;
                border: 1px solid transparent;
                background: $color-white;
            }
            &.ui-state-highlight{
                color: $color-black;
                border: 1px solid $color-black;
                background: $color-white;
            }
            &.ui-state-active {
                color: $color-white;
                border: 1px solid $color-black;
                background: $color-black;
            }
        }
    }
}
.ui-datepicker-calendar{
    td.ui-datepicker-other-month{
        .ui-state-default { 
            text-align: center;
            border: 1px solid transparent;
            background: $color-white;
            color: lighten($color-black,50%);
        }
    }
}
.ui-datepicker-calendar{
    td.ui-datepicker-unselectable{
        .ui-state-default { 
            border: none;
        }
    }
}
