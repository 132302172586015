body.no-overflow{
    overflow: hidden;
}
body.story, 
body.storydetail,
body.prix{
    #overlay_inner{
        color: $color-black;
        
        .white{
            color: $color-white;
        }
    }
}
#overlay{
    display: none;
    position: fixed;
    background: rgba(0,0,0,0.5);
    z-index: 999;
    width: 100%;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    top: 0;
}
#overlay_inner{
    .loader{
        width: 200px;
        margin: 100px auto;
    }
    .contenbox{
        padding: $space;
        background: $color-white;
        box-shadow: 0px 0px 16px -4px rgba(0,0,0,0.75);
        margin: 100px auto;
        
        &.small{
            width: 600px;
        }
        &.small2{
            width: 750px;
            
            .dvgui-form-group{
                .dvgui-text,
                .dvgui-select,   
                .dvgui-checkbox,
                .dvgui-file-upload,
                .dvgui-radio{
                    width: 66%;
                    margin-left: 34%;
                    ~ label {
                        &:after {
                            left: 34%;
                        }
                    }
                    &:focus:not([readonly]),  
                    &.focus:not([readonly]) {		 
                        ~ label {
                            &:after {
                                width: 66%; 
                                background: $color-highlight;
                            } 
                        }
                    }
                }
            }   
        }

        &.mid{
            width: 900px;
        }
        &.full{
            width: 90%;
        }
        h1 {
            font-size: $font-size2;
            margin-bottom: $space*2;
        }
        a {
            color: $color-black;
            &.btn{
                &:hover{
                    color: $color-white;
                }
            }
        }

        header{
            display: block;
            border-bottom: solid 1px $color-black;
            position: relative;
            top: auto;
            overflow: hidden;
            padding-bottom: $space/2;
            margin-bottom: 5px;
            background: $color-white;
            
            a {
                float: right;
                color: $color-black;
            }
            
            .steps{
                width: 200px;
                float: left;
                
                a {
                    display: inline-block;
                    float: none;
                    border: solid 1px $color-black;
                    border-radius: 50%;
                    padding: 6px 5px 5px 5px;
                    width: 35px;
                    height: 35px;
                    text-align: center;
                    margin-left: 10px;
                    
                    &.selected{
                        background: $color-green;
                    }
                }
            }
        }
        .separator{
            display: block;
            border-bottom: solid 1px $color-black;
            height: 0.001px;
            padding-top: 27px;
            margin-bottom: $space;
        }
        .cols{
            display: flex;
            
            .col{
                width: 50%;
                width: calc(50% - #{$space/2});
                margin-right: $space;

                &:nth-of-type(2){
                    margin-right: 0;
                }
            }
        }
        div.img{
            padding-bottom: 100%;
            width: 100%;
            background: #ccc;
            background-position: center center;
            background-size: cover;
            margin-top: 0;
            margin-bottom: $space;
        }
        .link-icons{
            overflow: hidden;

            a{
                float: left;
                margin-right: $space/2;
                border: solid 1px $color-black;
                border-radius: 50%;
                width: 36px;
                height: 36px;

                &.selected{
                    background: $color-highlight;
                }

                svg{
                    width: 20px;
                    height: 20px;
                    margin: 7px;
                }
            }
        }
    }
}
.close-icon{
    svg{
        width: 25px;
        height: 25px;
    }
}
@media(max-width:950px){
    #overlay_inner{
        .contenbox{

            &.mid{
                width: 100%;
                width: calc(100% - 40px);
            }
        }
    }
}
@media(max-width:650px){
    #overlay_inner{
        .contenbox{

            &.small,
            &.small2,
            &.mid{
                width: 100%;
                width: calc(100% - 40px);
            }
            .cols{
                display: block;

                .col{
                    width: 100%;
                    margin-right: 0;
                    border-bottom: solid 1px $color-black;
                    padding-bottom: 27px;
                    margin-bottom: $space;               
                }
            }
        }
    }
}
