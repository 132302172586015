body.fixedheader{
    main{
        padding-top: 18vh;
        padding-top: calc(18vh + #{$space+$font-size1});
        transition: all 0.5s;
    }
}
main {
    margin: 0 50px;
}

section.layer{
    background: $color-white;
    padding: 0 50px;
    width: 100vw;
    min-height: 100vh;
    overflow: hidden;
}
body.alumnidetail,
body.agendadetail,
body.jobsdetail,
body.storydetail{
    section.layer{
        padding: 0;
        width: auto;
    }
}
#info{
    width: 100%;
    width: calc(100% - 50px);
    overflow: hidden;
    border-top: solid 1px $color-black;
    
    p{
        font-size: $font-size1;
        padding: $space 0 $space*2 0;
        max-width:1000px;
        margin: auto;
    }
}

@media (orientation: portrait) {
    body.fixedheader{
        main{
            padding-top: 18vw;
            padding-top: calc(18vw + #{$space*2+$font-size1}-4px);
        }
    }
}

@media(max-width:950px){
    main {
        margin: 0 25px;
    }
    section.layer{
        padding: 0 25px;
    }
}