body.errors{
    section{
        border-top: solid 1px $color-black;
        
        width: 100%;
        overflow: hidden;
        border-top: solid 1px $color-black;
        display: flex;
        padding-bottom: $space;

        h1{
            font-size: $font-size1;
            padding: $space 0 $space*2 0;
            width: $scale6; 
            width: calc(#{$scale6} + 12px);
        }
        .right{
            padding: $space 0 $space*2 0;
            width: $scale6*5; 
            width: calc(#{$scale6*5} - 50px);
            max-width: 800px;
            font-size: $font-size3;
        }
        article{
            margin-bottom: $space;

            h2{
                font-size: $font-size2;
                margin: 0 0 $space;
            }
            p{
                font-size: 12px;
                overflow: hidden;
            }
            a.document, a.link{
                color: $color-grey;
                font-size: $font-size3;
                margin-top: 10px;
                display: block;
            }
        }
    }
}
@media(max-width:1000px){
    body.errors{
        section{
            display: block;
            h1{
                width: 100%; 
            }
            .right{
                width: 100%; 
                padding-top: 0;
            }
        }
    }
}