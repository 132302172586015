body.prix{
    background: $color-black;
    color: $color-white;
    
    article.alumni{
        border-top: solid 1px $color-white;
    }
    
    #info{
        border-top: solid 1px $color-white;
        width: 100%;
    }
}
#winner{
    width: 100%;
    overflow: hidden;
    border-top: solid 1px $color-white;
    
    .winner-img{
        max-width: 650px;
        height: 400px;
        background-position: center center;
        background-size: cover;
        background-color: #ccc;
        margin: $space auto;
        position: relative;
    
        h1 {
            position: absolute;
            font-family: $font2;
            font-size: 18vh;
            color: $color-white;
            padding: 0;
            text-align: center;
            letter-spacing: 5px;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%); 
            text-shadow: 4px 4px 6px #666;
        }
    }
    
    h2{
        font-size: $font-size1;
        padding: $space 0 $space*2 0;
        text-align: center
    }
    p {
        max-width: 1000px;
        margin: $space auto $space*3 auto;
        font-size: $font-size3;
    }
}
#nominee{
    width: 100%;
    overflow: hidden;
    border-top: solid 1px $color-white;
    
    h1{
        font-size: $font-size1;
        padding: $space*2 0 $space*2 0;
        text-align: center
    }
    .overflow1,
    .overflow2{
        height: 60px;
        position: relative;
        overflow: hidden;

        &:after {
            content: '';  
            display: block;
            width: 100%;
            height: 20px;
            background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 53%, rgba(0,0,0,1) 100%);
            background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 53%,rgba(0,0,0,1) 100%);
            background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 53%,rgba(0,0,0,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 );
            position: absolute;
            bottom: 0;
            z-index: 10;
        }

        h2,
        div{
            display: block;
            height: 50px;
            overflow-y: auto;
            overflow-x: hidden;
            width: calc(100% + 30px);
            padding-right: 30px;
            margin-bottom: 10px;
            p{
                margin-bottom: 10px;
            }
        }
    }
    .overflow1{
        height: 80px;

        h2{
            height: 70px;
        }
    }
    .overflow2{
        height: 100px;

        div{
            height: 90px;
        }
    }
    .link-icons{
        svg{
            fill: $color-white;
        }
    }
}
#nomination,
#procedure,
#jury{
    width: 100%;
    overflow: hidden;
    border-top: solid 1px $color-white;
    display: flex;

    h1{
        font-size: $font-size1;
        padding: $space 0 $space*2 0;
        width: $scale6; 
        width: calc(#{$scale6} + 12px);
    }
    .right{
        padding: $space 0 $space*2 0;
        width: $scale6*5; 
        width: calc(#{$scale6*5} - 50px);
        max-width: 800px;
        font-size:  $font-size3;
        
        a {
            color: $color-white;
        }
    }
}
@media(max-width:1000px){
    #nomination,
    #procedure,
    #jury{
        display: block;

        .right{
            padding-top: 0;
            width: 100%;
        }
    }
}
@media(max-width:450px){
    #info{

        p{
            font-size: $font-size2;
        }
    }
}