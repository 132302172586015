$scale6: 16.666%;
$scale5: 20%;
$scale4: 25%;
$scale3: 33.333%;
$scale2: 50%;
$scale1: 100%;

body.benefits{
    main{
        margin-right: 0;
    }
}

#benefits2{
    width: 100%;
    overflow: hidden;
    margin-right: -50px;
}
article.benefits2{
    border-top: solid 1px $color-black;
    padding: $space/2 0 $space/2 0;
    margin-right: 50px;
    width: $scale6; // 6 tiles
    width: calc(#{$scale6} - 50px);
    float: left;
    
    
    h2{
        font-size: $font-size1;
        margin: 0;
        padding: 0;
    }
    div.bottom{
        margin-top: 10px;
        height: 115px;
        font-size: $font-size3;
        
        .grey{
            color: $color-grey;
        }
    }
    .header{
       height: 70px;
    }
    .overflow{
        position: relative;
        overflow: hidden;
    }
    
    
    div.img{
        padding-bottom: 100%;
        width: 100%;
        background: #ccc;
        background-position: center center;
        background-size: cover;
    }
}
#benefits-content,
#benefits-title{
    border-top: solid 1px $color-black;

    width: 100%;
    overflow: hidden;
    border-top: solid 1px $color-black;
    display: flex;

    h1{
        font-size: $font-size1;
        padding: $space $space $space*2 0;
        width: $scale6; 
        width: calc(#{$scale6} + 12px);
    }
    .right{
        padding: $space 0 $space*2 0;
        width: $scale6*5; 
        width: calc(#{$scale6*5} - 50px);
        max-width: 800px;
        font-size: $font-size1;
        
        &.notitle{
            margin-left: $scale6;
            margin-left: calc(#{$scale6} + 12px);
        }
        .location{
            /*margin-top: $space;*/
        }
        
        .description{
            font-size: $font-size3;
            margin-top: $space;
        }
        .link{
            font-size: $font-size3;
            margin-top: $space;
            text-decoration: underline;
        }
    }
    .inline-slider{
        margin-top: $space;
    }
    .benefits-notice{
        font-size: $font-size3;   
        margin-top: $space;
    }
}
body.benefits2{
    section{
        &#jobs{
            width: 100%;
            overflow: hidden;
            border-top: solid 1px $color-black;
            display: flex;

            &.layer{
                display: block;
            }

            padding-bottom: $space;

            h1{
                font-size: $font-size1;
                padding: $space 0 $space*2 0;
                width: $scale6; 
                width: calc(#{$scale6} + 12px);
            }
            .right{
                padding: $space 0 $space*2 0;
                width: $scale6*5; 
                width: calc(#{$scale6*5} - 50px);
                max-width: 800px;
                font-size: $font-size3;
            }
            .full{
                padding: $space 0 $space*2 0;
                width: $scale6*5; 
                width: calc(#{$scale6*5} - 50px);
                font-size:  $font-size1;

                .right{
                    padding: 0;
                }
            }
            article{
                margin-bottom: $space;

                h2{
                    font-size: $font-size1;
                    margin: 0 0 $space;
                }
                p{
                    font-size: 12px;
                    overflow: hidden;
                }
                a.document, a.link{
                    color: $color-grey;
                    font-size: $font-size3;
                    margin-top: 10px;
                    display: block;
                }
            }
            article.jobs{
                display: flex;
                width: 100%;
                border-top: solid 1px $color-black;
                padding: $space/2 0 0 0;
                position: relative;
                cursor: pointer;


                h2{
                    font-size: $font-size1;
                    flex-grow: 2;
                    margin: 0;
                    padding: 0;
                    margin-right: 50px;
                }
                .employer{
                    flex: 0 0 300px;    
                }
            }
        }
    }
}
.open-layer{
    cursor: pointer;
}
.close-layer{
    cursor: pointer;
}

@media(max-width:3600px){
    article.benefits{
        width: $scale6; 
        width: calc(#{$scale6} - 50px);
    }
    article.benefits2{
        width: $scale6; 
        width: calc(#{$scale6} - 50px);
    }
}
@media(max-width:2800px){
    article.benefits{
        width: $scale5; 
        width: calc(#{$scale5} - 50px);
    }
    article.benefits2{
        width: $scale5; 
        width: calc(#{$scale5} - 50px);
    }
}
@media(max-width:2200px){
    article.benefits{
        width: $scale4; 
        width: calc(#{$scale4} - 50px);
    }
    article.benefits2{
        width: $scale4; 
        width: calc(#{$scale4} - 50px);
    }
}
@media(max-width:1500px){
    article.benefits{
        width: $scale3; 
        width: calc(#{$scale3} - 50px);
    }
    article.benefits2{
        width: $scale3; 
        width: calc(#{$scale3} - 50px);
    }
}
@media(max-width:1000px){
    article.benefits{
        width: $scale2; 
        width: calc(#{$scale2} - 25px);
        margin-right: 25px;
    }
    article.benefits2{
        width: $scale2; 
        width: calc(#{$scale2} - 25px);
        margin-right: 25px;
    }
    #benefits-content,
    #benefits-title{
        display: block;
        
        h1{
            width: auto;
            padding-right: 0;
        }
        .right{
            width: 100%;
                
            &.notitle{
                margin-left: 0;
            }
        }
    }
}
@media(max-width:600px){
    article.benefits{
        width: $scale1; 
        width: calc(#{$scale1} - 25px);
    }
    article.benefits2{
        width: $scale1; 
        width: calc(#{$scale1} - 25px);
    }
}