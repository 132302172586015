nav.main{
    
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 99;
    
    overflow: hidden;
    perspective: 1000px;
 
    transition: all 0.5s;
    transform: scaleY(0);
    transform-origin: top;
        
    ul{
    
        transform-origin: top;
        transition: all 0.5s;
        transform: scaleX(0.5) rotateX(70deg) perspective(300px);
        opacity: 0;
        
        li{
            list-style: none;
            
            a{
                display: block;
                text-align: center;
                
                svg{
                    height: 11vh;
                    fill: $color-white;
                    filter: drop-shadow(0px 3px 4px #999);
                }
                &:hover{
                    svg{
                        /*height: 11vh;*/
                        filter: drop-shadow(0px 1px 2px #999);
                        /*fill: $color-black;*/
                    }
                }
            }
        }
    }
    .meta{
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
        bottom: $space;
        text-align: center;
        font-size: $font-size1;
        width: 80%;
        
        .inner{
            margin: auto;
            color: $color-grey;
            /*text-shadow: 1px 1px 3px $color-grey4;*/
            overflow: hidden;
            
            .identity{
                display: inline-block;
            }
            
            .languages{
                display: inline-block;
                margin: 0 50px;
                text-align: right;
            }
            .membership{
                display: inline-block;
            }
            a {
                border: solid 1px $color-grey2;
                border-radius: 15px;
                padding: 8px 20px 5px 20px;
                display: inline-block;
                text-align: center;
                cursor: pointer;
                /*color: $color-grey;*/
                color: #ffffff;
                margin-bottom: 10px;
                box-shadow: 4px 4px 6px 0px $color-grey2;
                /*
                color: $color-grey;
                text-shadow: 1px 1px 3px $color-grey4;
                */
                /*
                &.selected{
                    color: $color-white;
                }
                */

                text-shadow:
                        1px 0 0 black,
                        0 1px 0 black,
                        -1px 0 0 black,
                        0 -1px 0 black;

                &:hover{
                    /*color: $color-grey4;*/
                    /*background: $color-black;*/
                    box-shadow: 1px 1px 2px 0px $color-grey2;
                }
                
            }
        }
    }
}
nav.filters,
nav.context{
    text-align: center;
    overflow: hidden;
    padding-bottom: $space;
    
    ul{
        display: inline-block;
        text-align: center;
        
        li{
            list-style: none;
            display: inline-block;
            padding: 0 $space 0 $space;
            
            a {
                font-size: $font-size1;
                color: $color-grey;
                cursor: pointer;
                
                &.selected {
                    color: $color-black;
                }
                &:hover{
                    color: $color-black;                    
                }
            }
        }
    }
    &.text-right{
        text-align: right;
    }
}

body.home{
    .nav-menu{
        fill: $color-white;
    }
    &.feature-read{
        .nav-menu{
            fill: $color-black;
        }
    }
    &.nav-open{
        .nav-menu{
            fill: $color-white;
        }
    }
}
body.prix,
body.story {
    .nav-menu{
        fill: $color-white;
    }
    nav.filters,
    nav.context{
        ul{
            li{
                a {
                    &.selected {
                        color: $color-white;
                    }
                    &:hover{
                        color: $color-white;                    
                    }
                }
            }
        }
    }
}
#nav-bg{
    display: none;
}
body.nav-open{
    #nav-bg{
        display: block;
        position: fixed;
        width: 100vw;
        height: 100vh;
        background: rgba(250,250,250,0.97);
        z-index: 95;
    }
    &.home{
        #nav-bg{
            display: none;
        }
    }
    nav.main{
        transition: all 0.5s;
        transform: scaleY(1);
        padding-top: 3vh;
        ul{
            transform: scaleX(1) rotateX(0) perspective(300px);
            opacity: 1;
        }
    }
}
body.alumnidetail,
section.layer{
    nav.context{
        padding-top: $space;
        position: relative;
        
        h1{
            font-size: $font-size1;
            position: absolute;
            left: 0;
        }
    }
}
nav.filters{
    display: none;
    transition: all 0.2s;
    overflow: hidden;
    
    &.open{
       display: block;
    }
}
nav.search{
    display: none;
    transition: all 0.2s;
    overflow: hidden;
    padding-bottom: $space*2;
    
    &.open{
        display: block;
    }
    
    .search-container{
        width: 50%;
        margin: auto;
        border-bottom: solid 1px $color-black;
        background: $color-white;
        position: relative;
        
        .search-input{
            width: 100%;
            font-family: $font;
            font-size: $font-size1;
            text-align: center;
            border: 0;
            background: $color-white;
            margin: 5px;
            outline: none;
        }
        .search-icon{
            height: 30px;
            width: 30px;
            position: absolute;
            right: 0;
            top: 5px;
            z-index: 10;
            fill: $color-grey;
        }
    }
    
}
@media(max-width:600px){
    nav.filters,
    nav.context{
        ul{
            li{
                padding: 0 $space/2 0 $space/2;
                a {
                    font-size: $font-size2;
                }
            }
        }
    }
    nav.main{
        .meta{
            bottom: 80px;
        }
    }
}
@media (orientation: portrait) {
    nav.main{

        ul{

            li{

                a{

                    svg{
                        height: 9vw;
                    }
                }
            }
        }
        &.open{
            transition: all 0.5s;
            transform: scaleY(1);
            ul{
                transform: scaleX(1) rotateX(0) perspective(300px);
                opacity: 1;
            }
        }
    }
}