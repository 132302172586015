body.newsletter{
    height: 100%;
    min-height: 100%;
    
    background: #39fb00;
    background: -moz-linear-gradient(top,  #39fb00 0%, #00fdfd 100%);
    background: -webkit-linear-gradient(top,  #39fb00 0%,#00fdfd 100%);
    background: linear-gradient(to bottom,  #39fb00 0%,#00fdfd 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#39fb00', endColorstr='#00fdfd',GradientType=0 );
    
    main {
        min-height: 100%;
    }
    
    section.newsletter-header{
            padding: $space 0 $space*2 0;
            width: $scale6*5; 
            width: calc(#{$scale6*5} - 50px);
            max-width: 800px;
            margin-left: $scale6; 
            margin-left: calc(#{$scale6} + 12px);
    }
    
    section.newsletter{
        border-top: solid 1px $color-black;
        
        width: 100%;
        overflow: hidden;
        border-top: solid 1px $color-black;
        display: flex;

        h1{
            font-size: $font-size1;
            padding: $space 0 $space*2 0;
            width: $scale6; 
            width: calc(#{$scale6} + 12px);
        }
        .right{
            font-size: $font-size1;
            padding: $space 0 $space*2 0;
            width: $scale6*5; 
            width: calc(#{$scale6*5} - 50px);
            max-width: 800px;
        }
    }
}