*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
a{
    text-decoration: none;
    color: $color-black;
}
h1,h2,h3,h4,h5,h6{
    font-weight: normal;
}