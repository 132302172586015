$scale6: 16.666%;
$scale5: 20%;
$scale4: 25%;
$scale3: 33.333%;
$scale2: 50%;
$scale1: 100%;

body.alumni{
    main{
        margin-right: 0;
    }
}
body.alumnifeatures{
    section{
        width: 100%;
        overflow: hidden;
        border-top: solid 1px $color-black;
        display: flex;
        
        &.layer{
            display: block;
        }
        
        padding-bottom: $space;

        h1{
            font-size: $font-size1;
            padding: $space 0 $space*2 0;
            width: $scale6; 
            width: calc(#{$scale6} + 12px);
        }
        .right{
            padding: $space 0 $space*2 0;
            width: $scale6*5; 
            width: calc(#{$scale6*5} - 50px);
            max-width: 800px;
            font-size: $font-size3;
        }
        .full{
            padding: $space 0 $space*2 0;
            width: $scale6*5; 
            width: calc(#{$scale6*5} - 50px);
            font-size:  $font-size1;

            .right{
                padding: 0;
            }
        }
    }
    #featured{
        width: 100%;
        overflow: hidden;
        border-top: solid 1px $color-black;

        .full{
            padding: $space 0 $space*2 0;
            width: $scale6*5; 
            width: calc(#{$scale6*5} - 50px);
            font-size:  $font-size1;

            a {
                color: $color-black;
            }
        }
        .featurerow{
            border-top: solid 1px $color-black;
            padding: 15px 0;
            font-size: $font-size1;
            display: flex;

            a{
                flex-grow: 2; 
                display: inline-block;

                &.name{
                    flex: 30% 0 0;
                    margin-right: $space;
                }
                &.podcast{
                    flex: 50px 0 0;
                    margin: -10px 0;
                    height: 50px;

                    svg{
                        width: 50px;
                        height: 50px;
                    }
                }
            }
        }
    }
}

#alumni{
    width: 100%;
    overflow: hidden;
    margin-right: -50px;
}
article.alumni{
    border-top: solid 1px $color-black;
    padding: $space/2 0 $space/2 0;
    margin-right: 50px;
    width: $scale6; // 6 tiles
    width: calc(#{$scale6} - 50px);
    float: left;
    
    
    h2{
        font-size: $font-size1;
        margin: 0;
        padding: 0;
    }
    div.bottom{
        margin-top: 10px;
        height: 165px;
        font-size: $font-size3;
        

        
        .link-icons{
            margin-top: 10px;
            a{
                margin-right: 10px;
                
                svg{
                    width: 25px;
                    height: 25px;
                }
            }
        }
    }
    
    .overflow1,
    .overflow2{
        height: 60px;
        position: relative;
        overflow: hidden;

        &:after {
            content: '';  
            display: block;
            width: 100%;
            height: 20px;
            background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 53%, rgba(255,255,255,1) 100%);
            background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 53%,rgba(255,255,255,1) 100%);
            background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 53%,rgba(255,255,255,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );
            position: absolute;
            bottom: 0;
            z-index: 10;
        }

        h2,
        div{
            display: block;
            height: 50px;
            overflow-y: auto;
            overflow-x: hidden;
            width: calc(100% + 30px);
            padding-right: 30px;
            margin-bottom: 10px;
            p{
                margin-bottom: 10px;
            }
        }
    }
    .overflow1{
        height: 80px;

        h2{
            height: 70px;
        }
    }
    .overflow2{
        height: 100px;

        div{
            height: 90px;
        }
    }
    
    
    div.img{
        padding-bottom: 100%;
        width: 100%;
        background: #ccc;
        background-position: center center;
        background-size: cover;
    }
}

.open-layer{
    cursor: pointer;
}
.close-layer{
    cursor: pointer;
}

@media(max-width:1300px){
    article.alumni{
        width: $scale6; 
        width: calc(#{$scale6} - 50px);
    }
}
@media(max-width:1150px){
    article.alumni{
        width: $scale5; 
        width: calc(#{$scale5} - 50px);
    }
}
@media(max-width:1000px){
    body.alumnifeatures{
        #featured{
            display: block;

            h1{
                width: auto;
                padding-right: 0;
            }
            .right{
                width: 100%;
                margin-bottom: $space/2;

                &.notitle{
                    margin-left: 0;
                }
            }
            .full{
                width: 100%;
            }
        }
    }
}
@media(max-width:950px){
    article.alumni{
        width: $scale4; 
        width: calc(#{$scale4} - 25px);
        margin-right: 25px;
    }
}
@media(max-width:750px){
    article.alumni{
        width: $scale3; 
        width: calc(#{$scale3} - 25px);
    }
}
@media(max-width:575px){
    article.alumni{
        width: $scale2; 
        width: calc(#{$scale2} - 25px);
    }
}
@media(max-width:400px){
    article.alumni{
        width: $scale1; 
        width: calc(#{$scale1} - 25px);
    }
}