body.home,
body.featuredetail{
    main{
        margin: 0;
        padding-top: 0;
    }
    header{
        position: absolute;
        z-index: 990;
        
        .pos{
            display: none;
        }
        .neg{
            display: block;
        }
    }
    &.nav-open{
        header{
            display: none;
        }
    }
    &.feature-read{
        header{
            .pos{
                display: block;
            }
            .neg{
                display: none;
            }
        }
        &.nav-open{
            header{
                .pos{
                    display: none;
                }
                .neg{
                    display: block;
                }
            }
        }
    }
}
body.featuredetail{
    main{
        margin: 0;
        padding: 0;
    }
}
section.layer.featuredetail{
    padding: 0;
    background: $color-white; 
}

#feature-bg{
    width: 100vw;
    height: 100vh;
    background-position: center;
    background-size: cover;
    position: absolute;
    z-index: 15;
    top: 0;
}
#feature-content{
    width: 100%;
    min-height: 100vh;
    position: absolute;
    z-index: 20;
    top: 0;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    
    .feature-title,
    .feature-lead{
        margin: 0;
        width: 100%;
        position: relative;
        height: 10vh;
        
        h1,h2{
            transition: all 0.5s;
            position: absolute;
            text-align: center;
            font-size: 9vh;
            color: $color-white;
            text-shadow: 2px 2px 5px $color-grey;
        }
    }
    .feature-title{
        margin-top: 45vh;
        h1{
            left: 5vw;
        }
    }

    .feature-lead{
        margin-top: 5vh;
        margin-bottom: 20vh;
        h2{
            right: 5vw;
        }
    }
    .feature-questions{
        display: none;
        font-size: $font-size1;
        padding-bottom: 50px;
        margin: 0 auto 40px auto;
        max-width: 900px;

        strong{
            display: block;
            margin: 0 50px 20px 50px;
            text-align: right;
            font-weight: normal;
        }
        p {
            display: block;
            text-align: left;
            margin: 0 100px 0 50px;
        }
    }
    .inline-slider{
        display: none;
        font-size: $font-size2;
        margin: 0 auto 40px auto;
        width: 60%;
        border: none;
    }
}
#feature-close{
    position: absolute;
    top: $space;
    right: $space;
    z-index: 560;
    
    svg {
        fill: $color-black;
    }
}

body.feature-read{
 
    #feature-content{
        background: $color-white;
        color: $color-black;


        .feature-title,
        .feature-lead{
            h1,h2{
                text-align: center;
                color: $color-black;
                text-shadow: none;
                width: max-content;
                max-width: 90vw;
            }
        }
        .feature-title{
            margin-top: 45vh;
            h1{
                left: 50%;
                transform: translate(-50%, 0);
            }
        }

        .feature-lead{
            margin-top: 5vh;
            h2{
                right: 50%;
                transform: translate(50%, 0);
            }
        }
        .feature-questions{
            display: block;
        }
        .inline-slider{
            display: block;
        }
    }
}
body.nav-open{
    overflow: hidden;
    
    #feature-bg{
        filter: blur(30px);
        transform: scale(1.1)
    }
    #feature-content{
        display: none;
    }
    
}
@media(max-width:500px){
    #feature-content{
        .feature-questions{
            strong{
                margin: 0 30px 20px 30px;
                font-size: $font-size2;
            }
            p {
                margin: 0 70px 0 30px;
                font-size: $font-size2;
            }
        }
    }
}

@media (orientation: portrait) {
    @media(max-width:1300px){
        #feature-content{
            .feature-title,
            .feature-lead{
                height: 10vw;

                h1,h2{
                    font-size: 9vw;
                }
            }
        }
    }
}