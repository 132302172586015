@import url("//hello.myfonts.net/count/3af01d");
@font-face {
  font-family: "netzhdk";
  src: url("fonts/netzhdk.eot");
  src: url("fonts/netzhdk.eot?#iefix") format("embedded-opentype"), url("fonts/netzhdk.woff2") format("woff2"), url("fonts/netzhdk.woff") format("woff"), url("fonts/netzhdk.ttf") format("ttf"), url("fonts/netzhdk.svg#netzhdk") format("svg");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: 'TimesLTStd-Semibold';
  src: url("fonts/3AF01D_0_0.eot");
  src: url("fonts/3AF01D_0_0.eot?#iefix") format("embedded-opentype"), url("fonts/3AF01D_0_0.woff2") format("woff2"), url("fonts/3AF01D_0_0.woff") format("woff"), url("fonts/3AF01D_0_0.ttf") format("truetype"); }

/*** main *********************************************************************/
/*** colors *******************************************************************/
* {
  box-sizing: border-box; }

html {
  min-height: 100%; }

body {
  background: #ffffff;
  font-family: "TimesLTStd-Semibold", serif;
  font-size: 20px;
  color: #000000;
  overflow-x: hidden; }

.open-meta,
.close-meta {
  cursor: pointer; }

.ml {
  margin-left: 20px; }

.mlr {
  margin-left: 20px;
  margin-right: 20px; }

.mb {
  margin-bottom: 20px; }

.mt {
  margin-top: 20px; }

.mt2 {
  margin-top: 40px; }

.pt {
  padding-top: 20px; }

.pt2 {
  padding-top: 40px; }

.bt {
  border-top: solid 1px #000000; }

.font_s3 {
  font-size: 16px; }

main ul {
  padding-left: 20px; }

.pointer {
  cursor: pointer; }

.loader {
  clear: both;
  text-align: center;
  padding: 50px; }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

a {
  text-decoration: none;
  color: #000000; }

h1, h2, h3, h4, h5, h6 {
  font-weight: normal; }

body.fixedheader header {
  position: fixed;
  top: 0;
  z-index: 90;
  background: #ffffff; }

body.fixedheader.home header {
  background: none; }

body.noheader header {
  display: none; }

body.prix,
body.story {
  background: #000000; }
  body.prix header,
  body.story header {
    background: #000000; }

body.special.rose {
  background: #ffb1b1; }
  body.special.rose header {
    background: #ffb1b1; }
    body.special.rose header h1 svg {
      fill: #0000cc; }

header {
  position: relative;
  width: 100%; }
  header.fixed {
    position: fixed;
    top: 0; }
  header .menu-button {
    height: 50px;
    width: 59px;
    position: absolute;
    width: 59px;
    height: 50px;
    top: 40px;
    right: 40px;
    z-index: 1200;
    pointer-events: none;
    cursor: pointer;
    fill: #000000;
    filter: drop-shadow(0 1px 2px #999); }
  header h1 {
    display: block;
    text-align: center;
    cursor: pointer; }
    header h1 svg {
      height: 11vh;
      margin: 3vh auto;
      display: block;
      filter: drop-shadow(2px 3px 4px #CCC); }
      header h1 svg:hover {
        filter: drop-shadow(1px 1px 2px #CCC); }

body.home .menu-button {
  fill: #ffffff; }

@media (orientation: portrait) {
  header h1 svg {
    height: 7vw;
    /*height: auto;
                width: calc(100vw - 35px);*/
    margin-left: 20px; } }

@media (max-width: 1350px) {
  header h1 svg {
    padding-right: 8vh; } }

@media (max-width: 950px) {
  header .menu-button {
    top: 20px;
    right: 20px; } }

nav.main {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 99;
  overflow: hidden;
  perspective: 1000px;
  transition: all 0.5s;
  transform: scaleY(0);
  transform-origin: top; }
  nav.main ul {
    transform-origin: top;
    transition: all 0.5s;
    transform: scaleX(0.5) rotateX(70deg) perspective(300px);
    opacity: 0; }
    nav.main ul li {
      list-style: none; }
      nav.main ul li a {
        display: block;
        text-align: center; }
        nav.main ul li a svg {
          height: 11vh;
          fill: #ffffff;
          filter: drop-shadow(0px 3px 4px #999); }
        nav.main ul li a:hover svg {
          /*height: 11vh;*/
          filter: drop-shadow(0px 1px 2px #999);
          /*fill: $color-black;*/ }
  nav.main .meta {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 20px;
    text-align: center;
    font-size: 25px;
    width: 80%; }
    nav.main .meta .inner {
      margin: auto;
      color: #999999;
      /*text-shadow: 1px 1px 3px $color-grey4;*/
      overflow: hidden; }
      nav.main .meta .inner .identity {
        display: inline-block; }
      nav.main .meta .inner .languages {
        display: inline-block;
        margin: 0 50px;
        text-align: right; }
      nav.main .meta .inner .membership {
        display: inline-block; }
      nav.main .meta .inner a {
        border: solid 1px #cccccc;
        border-radius: 15px;
        padding: 8px 20px 5px 20px;
        display: inline-block;
        text-align: center;
        cursor: pointer;
        /*color: $color-grey;*/
        color: #ffffff;
        margin-bottom: 10px;
        box-shadow: 4px 4px 6px 0px #cccccc;
        /*
                color: $color-grey;
                text-shadow: 1px 1px 3px $color-grey4;
                */
        /*
                &.selected{
                    color: $color-white;
                }
                */
        text-shadow: 1px 0 0 black, 0 1px 0 black, -1px 0 0 black, 0 -1px 0 black; }
        nav.main .meta .inner a:hover {
          /*color: $color-grey4;*/
          /*background: $color-black;*/
          box-shadow: 1px 1px 2px 0px #cccccc; }

nav.filters,
nav.context {
  text-align: center;
  overflow: hidden;
  padding-bottom: 20px; }
  nav.filters ul,
  nav.context ul {
    display: inline-block;
    text-align: center; }
    nav.filters ul li,
    nav.context ul li {
      list-style: none;
      display: inline-block;
      padding: 0 20px 0 20px; }
      nav.filters ul li a,
      nav.context ul li a {
        font-size: 25px;
        color: #999999;
        cursor: pointer; }
        nav.filters ul li a.selected,
        nav.context ul li a.selected {
          color: #000000; }
        nav.filters ul li a:hover,
        nav.context ul li a:hover {
          color: #000000; }
  nav.filters.text-right,
  nav.context.text-right {
    text-align: right; }

body.home .nav-menu {
  fill: #ffffff; }

body.home.feature-read .nav-menu {
  fill: #000000; }

body.home.nav-open .nav-menu {
  fill: #ffffff; }

body.prix .nav-menu,
body.story .nav-menu {
  fill: #ffffff; }

body.prix nav.filters ul li a.selected,
body.prix nav.context ul li a.selected,
body.story nav.filters ul li a.selected,
body.story nav.context ul li a.selected {
  color: #ffffff; }

body.prix nav.filters ul li a:hover,
body.prix nav.context ul li a:hover,
body.story nav.filters ul li a:hover,
body.story nav.context ul li a:hover {
  color: #ffffff; }

#nav-bg {
  display: none; }

body.nav-open #nav-bg {
  display: block;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(250, 250, 250, 0.97);
  z-index: 95; }

body.nav-open.home #nav-bg {
  display: none; }

body.nav-open nav.main {
  transition: all 0.5s;
  transform: scaleY(1);
  padding-top: 3vh; }
  body.nav-open nav.main ul {
    transform: scaleX(1) rotateX(0) perspective(300px);
    opacity: 1; }

body.alumnidetail nav.context,
section.layer nav.context {
  padding-top: 20px;
  position: relative; }
  body.alumnidetail nav.context h1,
  section.layer nav.context h1 {
    font-size: 25px;
    position: absolute;
    left: 0; }

nav.filters {
  display: none;
  transition: all 0.2s;
  overflow: hidden; }
  nav.filters.open {
    display: block; }

nav.search {
  display: none;
  transition: all 0.2s;
  overflow: hidden;
  padding-bottom: 40px; }
  nav.search.open {
    display: block; }
  nav.search .search-container {
    width: 50%;
    margin: auto;
    border-bottom: solid 1px #000000;
    background: #ffffff;
    position: relative; }
    nav.search .search-container .search-input {
      width: 100%;
      font-family: "TimesLTStd-Semibold", serif;
      font-size: 25px;
      text-align: center;
      border: 0;
      background: #ffffff;
      margin: 5px;
      outline: none; }
    nav.search .search-container .search-icon {
      height: 30px;
      width: 30px;
      position: absolute;
      right: 0;
      top: 5px;
      z-index: 10;
      fill: #999999; }

@media (max-width: 600px) {
  nav.filters ul li,
  nav.context ul li {
    padding: 0 10px 0 10px; }
    nav.filters ul li a,
    nav.context ul li a {
      font-size: 20px; }
  nav.main .meta {
    bottom: 80px; } }

@media (orientation: portrait) {
  nav.main ul li a svg {
    height: 9vw; }
  nav.main.open {
    transition: all 0.5s;
    transform: scaleY(1); }
    nav.main.open ul {
      transform: scaleX(1) rotateX(0) perspective(300px);
      opacity: 1; } }

body.fixedheader main {
  padding-top: 18vh;
  padding-top: calc(18vh + 45px);
  transition: all 0.5s; }

main {
  margin: 0 50px; }

section.layer {
  background: #ffffff;
  padding: 0 50px;
  width: 100vw;
  min-height: 100vh;
  overflow: hidden; }

body.alumnidetail section.layer,
body.agendadetail section.layer,
body.jobsdetail section.layer,
body.storydetail section.layer {
  padding: 0;
  width: auto; }

#info {
  width: 100%;
  width: calc(100% - 50px);
  overflow: hidden;
  border-top: solid 1px #000000; }
  #info p {
    font-size: 25px;
    padding: 20px 0 40px 0;
    max-width: 1000px;
    margin: auto; }

@media (orientation: portrait) {
  body.fixedheader main {
    padding-top: 18vw;
    padding-top: calc(18vw + 65px-4px); } }

@media (max-width: 950px) {
  main {
    margin: 0 25px; }
  section.layer {
    padding: 0 25px; } }

footer {
  margin: 50px 0;
  padding: 20px 0;
  border-top: solid 1px #000000;
  font-size: 16px; }
  footer ul {
    overflow: hidden;
    margin: 20px 0;
    padding: 0; }
    footer ul li {
      float: left;
      list-style: none;
      margin-right: 20px; }
      footer ul li a {
        color: #999999; }

body.alumni footer {
  margin-right: 50px; }

body.prix footer {
  border-top: solid 1px #ffffff; }

body.special.rose footer {
  border-top: solid 1px #0000cc; }

body.home footer {
  display: none; }

body.home.feature-read footer {
  display: block;
  margin: 50px; }

/*! jQuery UI - v1.12.1 - 2019-06-13
* http://jqueryui.com
* Includes: draggable.css, core.css, resizable.css, selectable.css, sortable.css, autocomplete.css, menu.css, datepicker.css, theme.css
* To view and modify this theme, visit http://jqueryui.com/themeroller/?scope=&folderName=base&cornerRadiusShadow=8px&offsetLeftShadow=0px&offsetTopShadow=0px&thicknessShadow=5px&opacityShadow=30&bgImgOpacityShadow=0&bgTextureShadow=flat&bgColorShadow=666666&opacityOverlay=30&bgImgOpacityOverlay=0&bgTextureOverlay=flat&bgColorOverlay=aaaaaa&iconColorError=cc0000&fcError=5f3f3f&borderColorError=f1a899&bgTextureError=flat&bgColorError=fddfdf&iconColorHighlight=777620&fcHighlight=777620&borderColorHighlight=dad55e&bgTextureHighlight=flat&bgColorHighlight=fffa90&iconColorActive=ffffff&fcActive=ffffff&borderColorActive=003eff&bgTextureActive=flat&bgColorActive=007fff&iconColorHover=555555&fcHover=2b2b2b&borderColorHover=cccccc&bgTextureHover=flat&bgColorHover=ededed&iconColorDefault=777777&fcDefault=454545&borderColorDefault=c5c5c5&bgTextureDefault=flat&bgColorDefault=f6f6f6&iconColorContent=444444&fcContent=333333&borderColorContent=dddddd&bgTextureContent=flat&bgColorContent=ffffff&iconColorHeader=444444&fcHeader=333333&borderColorHeader=dddddd&bgTextureHeader=flat&bgColorHeader=e9e9e9&cornerRadius=3px&fwDefault=normal&fsDefault=1em&ffDefault=Arial%2CHelvetica%2Csans-serif
* Copyright jQuery Foundation and other contributors; Licensed MIT */
.ui-draggable-handle {
  -ms-touch-action: none;
  touch-action: none; }

.ui-helper-hidden {
  display: none; }

.ui-helper-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.ui-helper-reset {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  line-height: 1.3;
  text-decoration: none;
  font-size: 100%;
  list-style: none; }

.ui-helper-clearfix:before, .ui-helper-clearfix:after {
  content: "";
  display: table;
  border-collapse: collapse; }

.ui-helper-clearfix:after {
  clear: both; }

.ui-helper-zfix {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  opacity: 0;
  filter: Alpha(Opacity=0); }

.ui-front {
  z-index: 100; }

.ui-state-disabled {
  cursor: default !important;
  pointer-events: none; }

.ui-icon {
  display: inline-block;
  vertical-align: middle;
  margin-top: -.25em;
  position: relative;
  text-indent: -99999px;
  overflow: hidden;
  background-repeat: no-repeat; }

.ui-widget-icon-block {
  left: 50%;
  margin-left: -8px;
  display: block; }

.ui-widget-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.ui-resizable {
  position: relative; }

.ui-resizable-handle {
  position: absolute;
  font-size: 0.1px;
  display: block;
  -ms-touch-action: none;
  touch-action: none; }

.ui-resizable-disabled .ui-resizable-handle, .ui-resizable-autohide .ui-resizable-handle {
  display: none; }

.ui-resizable-n {
  cursor: n-resize;
  height: 7px;
  width: 100%;
  top: -5px;
  left: 0; }

.ui-resizable-s {
  cursor: s-resize;
  height: 7px;
  width: 100%;
  bottom: -5px;
  left: 0; }

.ui-resizable-e {
  cursor: e-resize;
  width: 7px;
  right: -5px;
  top: 0;
  height: 100%; }

.ui-resizable-w {
  cursor: w-resize;
  width: 7px;
  left: -5px;
  top: 0;
  height: 100%; }

.ui-resizable-se {
  cursor: se-resize;
  width: 12px;
  height: 12px;
  right: 1px;
  bottom: 1px; }

.ui-resizable-sw {
  cursor: sw-resize;
  width: 9px;
  height: 9px;
  left: -5px;
  bottom: -5px; }

.ui-resizable-nw {
  cursor: nw-resize;
  width: 9px;
  height: 9px;
  left: -5px;
  top: -5px; }

.ui-resizable-ne {
  cursor: ne-resize;
  width: 9px;
  height: 9px;
  right: -5px;
  top: -5px; }

.ui-selectable {
  -ms-touch-action: none;
  touch-action: none; }

.ui-selectable-helper {
  position: absolute;
  z-index: 100;
  border: 1px dotted black; }

.ui-sortable-handle {
  -ms-touch-action: none;
  touch-action: none; }

.ui-autocomplete {
  position: absolute;
  top: 0;
  left: 0;
  cursor: default; }

.ui-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  display: block;
  outline: 0; }

.ui-menu .ui-menu {
  position: absolute; }

.ui-menu .ui-menu-item {
  margin: 0;
  cursor: pointer;
  list-style-image: url("data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"); }

.ui-menu .ui-menu-item-wrapper {
  position: relative;
  padding: 3px 1em 3px .4em; }

.ui-menu .ui-menu-divider {
  margin: 5px 0;
  height: 0;
  font-size: 0;
  line-height: 0;
  border-width: 1px 0 0 0; }

.ui-menu .ui-state-focus, .ui-menu .ui-state-active {
  margin: -1px; }

.ui-menu-icons {
  position: relative; }

.ui-menu-icons .ui-menu-item-wrapper {
  padding-left: 2em; }

.ui-menu .ui-icon {
  position: absolute;
  top: 0;
  bottom: 0;
  left: .2em;
  margin: auto 0; }

.ui-menu .ui-menu-icon {
  left: auto;
  right: 0; }

.ui-datepicker {
  width: 17em;
  padding: .2em .2em 0;
  display: none; }

.ui-datepicker .ui-datepicker-header {
  position: relative;
  padding: .2em 0; }

.ui-datepicker .ui-datepicker-prev, .ui-datepicker .ui-datepicker-next {
  position: absolute;
  top: 2px;
  width: 1.8em;
  height: 1.8em; }

.ui-datepicker .ui-datepicker-prev-hover, .ui-datepicker .ui-datepicker-next-hover {
  top: 1px; }

.ui-datepicker .ui-datepicker-prev {
  left: 2px; }

.ui-datepicker .ui-datepicker-next {
  right: 2px; }

.ui-datepicker .ui-datepicker-prev-hover {
  left: 1px; }

.ui-datepicker .ui-datepicker-next-hover {
  right: 1px; }

.ui-datepicker .ui-datepicker-prev span, .ui-datepicker .ui-datepicker-next span {
  display: block;
  position: absolute;
  left: 50%;
  margin-left: -8px;
  top: 50%;
  margin-top: -8px; }

.ui-datepicker .ui-datepicker-title {
  margin: 0 2.3em;
  line-height: 1.8em;
  text-align: center; }

.ui-datepicker .ui-datepicker-title select {
  font-size: 1em;
  margin: 1px 0; }

.ui-datepicker select.ui-datepicker-month, .ui-datepicker select.ui-datepicker-year {
  width: 45%; }

.ui-datepicker table {
  width: 100%;
  font-size: .9em;
  border-collapse: collapse;
  margin: 0 0 .4em; }

.ui-datepicker th {
  padding: .7em .3em;
  text-align: center;
  font-weight: bold;
  border: 0; }

.ui-datepicker td {
  border: 0;
  padding: 1px; }

.ui-datepicker td span, .ui-datepicker td a {
  display: block;
  padding: .2em;
  text-align: right;
  text-decoration: none; }

.ui-datepicker .ui-datepicker-buttonpane {
  background-image: none;
  margin: .7em 0 0 0;
  padding: 0 .2em;
  border-left: 0;
  border-right: 0;
  border-bottom: 0; }

.ui-datepicker .ui-datepicker-buttonpane button {
  float: right;
  margin: .5em .2em .4em;
  cursor: pointer;
  padding: .2em .6em .3em .6em;
  width: auto;
  overflow: visible; }

.ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-current {
  float: left; }

.ui-datepicker.ui-datepicker-multi {
  width: auto; }

.ui-datepicker-multi .ui-datepicker-group {
  float: left; }

.ui-datepicker-multi .ui-datepicker-group table {
  width: 95%;
  margin: 0 auto .4em; }

.ui-datepicker-multi-2 .ui-datepicker-group {
  width: 50%; }

.ui-datepicker-multi-3 .ui-datepicker-group {
  width: 33.3%; }

.ui-datepicker-multi-4 .ui-datepicker-group {
  width: 25%; }

.ui-datepicker-multi .ui-datepicker-group-last .ui-datepicker-header, .ui-datepicker-multi .ui-datepicker-group-middle .ui-datepicker-header {
  border-left-width: 0; }

.ui-datepicker-multi .ui-datepicker-buttonpane {
  clear: left; }

.ui-datepicker-row-break {
  clear: both;
  width: 100%;
  font-size: 0; }

.ui-datepicker-rtl {
  direction: rtl; }

.ui-datepicker-rtl .ui-datepicker-prev {
  right: 2px;
  left: auto; }

.ui-datepicker-rtl .ui-datepicker-next {
  left: 2px;
  right: auto; }

.ui-datepicker-rtl .ui-datepicker-prev:hover {
  right: 1px;
  left: auto; }

.ui-datepicker-rtl .ui-datepicker-next:hover {
  left: 1px;
  right: auto; }

.ui-datepicker-rtl .ui-datepicker-buttonpane {
  clear: right; }

.ui-datepicker-rtl .ui-datepicker-buttonpane button {
  float: left; }

.ui-datepicker-rtl .ui-datepicker-buttonpane button.ui-datepicker-current, .ui-datepicker-rtl .ui-datepicker-group {
  float: right; }

.ui-datepicker-rtl .ui-datepicker-group-last .ui-datepicker-header, .ui-datepicker-rtl .ui-datepicker-group-middle .ui-datepicker-header {
  border-right-width: 0;
  border-left-width: 1px; }

.ui-datepicker .ui-icon {
  display: block;
  text-indent: -99999px;
  overflow: hidden;
  background-repeat: no-repeat;
  left: .5em;
  top: .3em; }

.ui-widget {
  font-family: Arial,Helvetica,sans-serif;
  font-size: 1em; }

.ui-widget .ui-widget {
  font-size: 1em; }

.ui-widget input, .ui-widget select, .ui-widget textarea, .ui-widget button {
  font-family: Arial,Helvetica,sans-serif;
  font-size: 1em; }

.ui-widget.ui-widget-content {
  border: 1px solid #c5c5c5; }

.ui-widget-content {
  border: 1px solid #ddd;
  background: #fff;
  color: #333; }

.ui-widget-content a {
  color: #333; }

.ui-widget-header {
  border: 1px solid #ddd;
  background: #e9e9e9;
  color: #333;
  font-weight: bold; }

.ui-widget-header a {
  color: #333; }

.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default, .ui-button, html .ui-button.ui-state-disabled:hover, html .ui-button.ui-state-disabled:active {
  border: 1px solid #c5c5c5;
  background: #f6f6f6;
  font-weight: normal;
  color: #454545; }

.ui-state-default a, .ui-state-default a:link, .ui-state-default a:visited, a.ui-button, a:link.ui-button, a:visited.ui-button, .ui-button {
  color: #454545;
  text-decoration: none; }

.ui-state-hover, .ui-widget-content .ui-state-hover, .ui-widget-header .ui-state-hover, .ui-state-focus, .ui-widget-content .ui-state-focus, .ui-widget-header .ui-state-focus, .ui-button:hover, .ui-button:focus {
  border: 1px solid #ccc;
  background: #ededed;
  font-weight: normal;
  color: #2b2b2b; }

.ui-state-hover a, .ui-state-hover a:hover, .ui-state-hover a:link, .ui-state-hover a:visited, .ui-state-focus a, .ui-state-focus a:hover, .ui-state-focus a:link, .ui-state-focus a:visited, a.ui-button:hover, a.ui-button:focus {
  color: #2b2b2b;
  text-decoration: none; }

.ui-visual-focus {
  box-shadow: 0 0 3px 1px #5e9ed6; }

.ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active, a.ui-button:active, .ui-button:active, .ui-button.ui-state-active:hover {
  border: 1px solid #003eff;
  background: #007fff;
  font-weight: normal;
  color: #fff; }

.ui-icon-background, .ui-state-active .ui-icon-background {
  border: #003eff;
  background-color: #fff; }

.ui-state-active a, .ui-state-active a:link, .ui-state-active a:visited {
  color: #fff;
  text-decoration: none; }

.ui-state-highlight, .ui-widget-content .ui-state-highlight, .ui-widget-header .ui-state-highlight {
  border: 1px solid #dad55e;
  background: #fffa90;
  color: #777620; }

.ui-state-checked {
  border: 1px solid #dad55e;
  background: #fffa90; }

.ui-state-highlight a, .ui-widget-content .ui-state-highlight a, .ui-widget-header .ui-state-highlight a {
  color: #777620; }

.ui-state-error, .ui-widget-content .ui-state-error, .ui-widget-header .ui-state-error {
  border: 1px solid #f1a899;
  background: #fddfdf;
  color: #5f3f3f; }

.ui-state-error a, .ui-widget-content .ui-state-error a, .ui-widget-header .ui-state-error a {
  color: #5f3f3f; }

.ui-state-error-text, .ui-widget-content .ui-state-error-text, .ui-widget-header .ui-state-error-text {
  color: #5f3f3f; }

.ui-priority-primary, .ui-widget-content .ui-priority-primary, .ui-widget-header .ui-priority-primary {
  font-weight: bold; }

.ui-priority-secondary, .ui-widget-content .ui-priority-secondary, .ui-widget-header .ui-priority-secondary {
  opacity: .7;
  filter: Alpha(Opacity=70);
  font-weight: normal; }

.ui-state-disabled, .ui-widget-content .ui-state-disabled, .ui-widget-header .ui-state-disabled {
  opacity: .35;
  filter: Alpha(Opacity=35);
  background-image: none; }

.ui-state-disabled .ui-icon {
  filter: Alpha(Opacity=35); }

.ui-icon {
  width: 16px;
  height: 16px; }

.ui-icon, .ui-widget-content .ui-icon {
  background-image: url("/dist/ui-icons_444444_256x240.png"); }

.ui-widget-header .ui-icon {
  background-image: url("/dist/ui-icons_444444_256x240.png"); }

.ui-state-hover .ui-icon, .ui-state-focus .ui-icon, .ui-button:hover .ui-icon, .ui-button:focus .ui-icon {
  background-image: url("/dist/ui-icons_555555_256x240.png"); }

.ui-state-active .ui-icon, .ui-button:active .ui-icon {
  background-image: url("/dist/ui-icons_ffffff_256x240.png"); }

.ui-state-highlight .ui-icon, .ui-button .ui-state-highlight.ui-icon {
  background-image: url("/dist/ui-icons_777620_256x240.png"); }

.ui-state-error .ui-icon, .ui-state-error-text .ui-icon {
  background-image: url("/dist/ui-icons_cc0000_256x240.png"); }

.ui-button .ui-icon {
  background-image: url("/dist/ui-icons_777777_256x240.png"); }

.ui-icon-blank {
  background-position: 16px 16px; }

.ui-icon-caret-1-n {
  background-position: 0 0; }

.ui-icon-caret-1-ne {
  background-position: -16px 0; }

.ui-icon-caret-1-e {
  background-position: -32px 0; }

.ui-icon-caret-1-se {
  background-position: -48px 0; }

.ui-icon-caret-1-s {
  background-position: -65px 0; }

.ui-icon-caret-1-sw {
  background-position: -80px 0; }

.ui-icon-caret-1-w {
  background-position: -96px 0; }

.ui-icon-caret-1-nw {
  background-position: -112px 0; }

.ui-icon-caret-2-n-s {
  background-position: -128px 0; }

.ui-icon-caret-2-e-w {
  background-position: -144px 0; }

.ui-icon-triangle-1-n {
  background-position: 0 -16px; }

.ui-icon-triangle-1-ne {
  background-position: -16px -16px; }

.ui-icon-triangle-1-e {
  background-position: -32px -16px; }

.ui-icon-triangle-1-se {
  background-position: -48px -16px; }

.ui-icon-triangle-1-s {
  background-position: -65px -16px; }

.ui-icon-triangle-1-sw {
  background-position: -80px -16px; }

.ui-icon-triangle-1-w {
  background-position: -96px -16px; }

.ui-icon-triangle-1-nw {
  background-position: -112px -16px; }

.ui-icon-triangle-2-n-s {
  background-position: -128px -16px; }

.ui-icon-triangle-2-e-w {
  background-position: -144px -16px; }

.ui-icon-arrow-1-n {
  background-position: 0 -32px; }

.ui-icon-arrow-1-ne {
  background-position: -16px -32px; }

.ui-icon-arrow-1-e {
  background-position: -32px -32px; }

.ui-icon-arrow-1-se {
  background-position: -48px -32px; }

.ui-icon-arrow-1-s {
  background-position: -65px -32px; }

.ui-icon-arrow-1-sw {
  background-position: -80px -32px; }

.ui-icon-arrow-1-w {
  background-position: -96px -32px; }

.ui-icon-arrow-1-nw {
  background-position: -112px -32px; }

.ui-icon-arrow-2-n-s {
  background-position: -128px -32px; }

.ui-icon-arrow-2-ne-sw {
  background-position: -144px -32px; }

.ui-icon-arrow-2-e-w {
  background-position: -160px -32px; }

.ui-icon-arrow-2-se-nw {
  background-position: -176px -32px; }

.ui-icon-arrowstop-1-n {
  background-position: -192px -32px; }

.ui-icon-arrowstop-1-e {
  background-position: -208px -32px; }

.ui-icon-arrowstop-1-s {
  background-position: -224px -32px; }

.ui-icon-arrowstop-1-w {
  background-position: -240px -32px; }

.ui-icon-arrowthick-1-n {
  background-position: 1px -48px; }

.ui-icon-arrowthick-1-ne {
  background-position: -16px -48px; }

.ui-icon-arrowthick-1-e {
  background-position: -32px -48px; }

.ui-icon-arrowthick-1-se {
  background-position: -48px -48px; }

.ui-icon-arrowthick-1-s {
  background-position: -64px -48px; }

.ui-icon-arrowthick-1-sw {
  background-position: -80px -48px; }

.ui-icon-arrowthick-1-w {
  background-position: -96px -48px; }

.ui-icon-arrowthick-1-nw {
  background-position: -112px -48px; }

.ui-icon-arrowthick-2-n-s {
  background-position: -128px -48px; }

.ui-icon-arrowthick-2-ne-sw {
  background-position: -144px -48px; }

.ui-icon-arrowthick-2-e-w {
  background-position: -160px -48px; }

.ui-icon-arrowthick-2-se-nw {
  background-position: -176px -48px; }

.ui-icon-arrowthickstop-1-n {
  background-position: -192px -48px; }

.ui-icon-arrowthickstop-1-e {
  background-position: -208px -48px; }

.ui-icon-arrowthickstop-1-s {
  background-position: -224px -48px; }

.ui-icon-arrowthickstop-1-w {
  background-position: -240px -48px; }

.ui-icon-arrowreturnthick-1-w {
  background-position: 0 -64px; }

.ui-icon-arrowreturnthick-1-n {
  background-position: -16px -64px; }

.ui-icon-arrowreturnthick-1-e {
  background-position: -32px -64px; }

.ui-icon-arrowreturnthick-1-s {
  background-position: -48px -64px; }

.ui-icon-arrowreturn-1-w {
  background-position: -64px -64px; }

.ui-icon-arrowreturn-1-n {
  background-position: -80px -64px; }

.ui-icon-arrowreturn-1-e {
  background-position: -96px -64px; }

.ui-icon-arrowreturn-1-s {
  background-position: -112px -64px; }

.ui-icon-arrowrefresh-1-w {
  background-position: -128px -64px; }

.ui-icon-arrowrefresh-1-n {
  background-position: -144px -64px; }

.ui-icon-arrowrefresh-1-e {
  background-position: -160px -64px; }

.ui-icon-arrowrefresh-1-s {
  background-position: -176px -64px; }

.ui-icon-arrow-4 {
  background-position: 0 -80px; }

.ui-icon-arrow-4-diag {
  background-position: -16px -80px; }

.ui-icon-extlink {
  background-position: -32px -80px; }

.ui-icon-newwin {
  background-position: -48px -80px; }

.ui-icon-refresh {
  background-position: -64px -80px; }

.ui-icon-shuffle {
  background-position: -80px -80px; }

.ui-icon-transfer-e-w {
  background-position: -96px -80px; }

.ui-icon-transferthick-e-w {
  background-position: -112px -80px; }

.ui-icon-folder-collapsed {
  background-position: 0 -96px; }

.ui-icon-folder-open {
  background-position: -16px -96px; }

.ui-icon-document {
  background-position: -32px -96px; }

.ui-icon-document-b {
  background-position: -48px -96px; }

.ui-icon-note {
  background-position: -64px -96px; }

.ui-icon-mail-closed {
  background-position: -80px -96px; }

.ui-icon-mail-open {
  background-position: -96px -96px; }

.ui-icon-suitcase {
  background-position: -112px -96px; }

.ui-icon-comment {
  background-position: -128px -96px; }

.ui-icon-person {
  background-position: -144px -96px; }

.ui-icon-print {
  background-position: -160px -96px; }

.ui-icon-trash {
  background-position: -176px -96px; }

.ui-icon-locked {
  background-position: -192px -96px; }

.ui-icon-unlocked {
  background-position: -208px -96px; }

.ui-icon-bookmark {
  background-position: -224px -96px; }

.ui-icon-tag {
  background-position: -240px -96px; }

.ui-icon-home {
  background-position: 0 -112px; }

.ui-icon-flag {
  background-position: -16px -112px; }

.ui-icon-calendar {
  background-position: -32px -112px; }

.ui-icon-cart {
  background-position: -48px -112px; }

.ui-icon-pencil {
  background-position: -64px -112px; }

.ui-icon-clock {
  background-position: -80px -112px; }

.ui-icon-disk {
  background-position: -96px -112px; }

.ui-icon-calculator {
  background-position: -112px -112px; }

.ui-icon-zoomin {
  background-position: -128px -112px; }

.ui-icon-zoomout {
  background-position: -144px -112px; }

.ui-icon-search {
  background-position: -160px -112px; }

.ui-icon-wrench {
  background-position: -176px -112px; }

.ui-icon-gear {
  background-position: -192px -112px; }

.ui-icon-heart {
  background-position: -208px -112px; }

.ui-icon-star {
  background-position: -224px -112px; }

.ui-icon-link {
  background-position: -240px -112px; }

.ui-icon-cancel {
  background-position: 0 -128px; }

.ui-icon-plus {
  background-position: -16px -128px; }

.ui-icon-plusthick {
  background-position: -32px -128px; }

.ui-icon-minus {
  background-position: -48px -128px; }

.ui-icon-minusthick {
  background-position: -64px -128px; }

.ui-icon-close {
  background-position: -80px -128px; }

.ui-icon-closethick {
  background-position: -96px -128px; }

.ui-icon-key {
  background-position: -112px -128px; }

.ui-icon-lightbulb {
  background-position: -128px -128px; }

.ui-icon-scissors {
  background-position: -144px -128px; }

.ui-icon-clipboard {
  background-position: -160px -128px; }

.ui-icon-copy {
  background-position: -176px -128px; }

.ui-icon-contact {
  background-position: -192px -128px; }

.ui-icon-image {
  background-position: -208px -128px; }

.ui-icon-video {
  background-position: -224px -128px; }

.ui-icon-script {
  background-position: -240px -128px; }

.ui-icon-alert {
  background-position: 0 -144px; }

.ui-icon-info {
  background-position: -16px -144px; }

.ui-icon-notice {
  background-position: -32px -144px; }

.ui-icon-help {
  background-position: -48px -144px; }

.ui-icon-check {
  background-position: -64px -144px; }

.ui-icon-bullet {
  background-position: -80px -144px; }

.ui-icon-radio-on {
  background-position: -96px -144px; }

.ui-icon-radio-off {
  background-position: -112px -144px; }

.ui-icon-pin-w {
  background-position: -128px -144px; }

.ui-icon-pin-s {
  background-position: -144px -144px; }

.ui-icon-play {
  background-position: 0 -160px; }

.ui-icon-pause {
  background-position: -16px -160px; }

.ui-icon-seek-next {
  background-position: -32px -160px; }

.ui-icon-seek-prev {
  background-position: -48px -160px; }

.ui-icon-seek-end {
  background-position: -64px -160px; }

.ui-icon-seek-start {
  background-position: -80px -160px; }

.ui-icon-seek-first {
  background-position: -80px -160px; }

.ui-icon-stop {
  background-position: -96px -160px; }

.ui-icon-eject {
  background-position: -112px -160px; }

.ui-icon-volume-off {
  background-position: -128px -160px; }

.ui-icon-volume-on {
  background-position: -144px -160px; }

.ui-icon-power {
  background-position: 0 -176px; }

.ui-icon-signal-diag {
  background-position: -16px -176px; }

.ui-icon-signal {
  background-position: -32px -176px; }

.ui-icon-battery-0 {
  background-position: -48px -176px; }

.ui-icon-battery-1 {
  background-position: -64px -176px; }

.ui-icon-battery-2 {
  background-position: -80px -176px; }

.ui-icon-battery-3 {
  background-position: -96px -176px; }

.ui-icon-circle-plus {
  background-position: 0 -192px; }

.ui-icon-circle-minus {
  background-position: -16px -192px; }

.ui-icon-circle-close {
  background-position: -32px -192px; }

.ui-icon-circle-triangle-e {
  background-position: -48px -192px; }

.ui-icon-circle-triangle-s {
  background-position: -64px -192px; }

.ui-icon-circle-triangle-w {
  background-position: -80px -192px; }

.ui-icon-circle-triangle-n {
  background-position: -96px -192px; }

.ui-icon-circle-arrow-e {
  background-position: -112px -192px; }

.ui-icon-circle-arrow-s {
  background-position: -128px -192px; }

.ui-icon-circle-arrow-w {
  background-position: -144px -192px; }

.ui-icon-circle-arrow-n {
  background-position: -160px -192px; }

.ui-icon-circle-zoomin {
  background-position: -176px -192px; }

.ui-icon-circle-zoomout {
  background-position: -192px -192px; }

.ui-icon-circle-check {
  background-position: -208px -192px; }

.ui-icon-circlesmall-plus {
  background-position: 0 -208px; }

.ui-icon-circlesmall-minus {
  background-position: -16px -208px; }

.ui-icon-circlesmall-close {
  background-position: -32px -208px; }

.ui-icon-squaresmall-plus {
  background-position: -48px -208px; }

.ui-icon-squaresmall-minus {
  background-position: -64px -208px; }

.ui-icon-squaresmall-close {
  background-position: -80px -208px; }

.ui-icon-grip-dotted-vertical {
  background-position: 0 -224px; }

.ui-icon-grip-dotted-horizontal {
  background-position: -16px -224px; }

.ui-icon-grip-solid-vertical {
  background-position: -32px -224px; }

.ui-icon-grip-solid-horizontal {
  background-position: -48px -224px; }

.ui-icon-gripsmall-diagonal-se {
  background-position: -64px -224px; }

.ui-icon-grip-diagonal-se {
  background-position: -80px -224px; }

.ui-corner-all, .ui-corner-top, .ui-corner-left, .ui-corner-tl {
  border-top-left-radius: 3px; }

.ui-corner-all, .ui-corner-top, .ui-corner-right, .ui-corner-tr {
  border-top-right-radius: 3px; }

.ui-corner-all, .ui-corner-bottom, .ui-corner-left, .ui-corner-bl {
  border-bottom-left-radius: 3px; }

.ui-corner-all, .ui-corner-bottom, .ui-corner-right, .ui-corner-br {
  border-bottom-right-radius: 3px; }

.ui-widget-overlay {
  background: #aaa;
  opacity: .3;
  filter: Alpha(Opacity=30); }

.ui-widget-shadow {
  -webkit-box-shadow: 0 0 5px #666;
  box-shadow: 0 0 5px #666; }

/** VARIABLES
===================================*/
/** RESET AND LAYOUT
===================================*/
.bx-wrapper {
  position: relative;
  margin-bottom: 60px;
  padding: 0;
  *zoom: 1;
  -ms-touch-action: pan-y;
  touch-action: pan-y; }

.bx-wrapper img {
  max-width: 100%;
  display: block; }

.bxslider {
  margin: 0;
  padding: 0; }

ul.bxslider {
  list-style: none; }

.bx-viewport {
  /*fix other elements on the page moving (on Chrome)*/
  -webkit-transform: translatez(0); }

/** THEME
===================================*/
.bx-wrapper {
  -moz-box-shadow: 0 0 5px #ccc;
  -webkit-box-shadow: 0 0 5px #ccc;
  box-shadow: 0 0 5px #ccc;
  border: 5px solid #fff;
  background: #fff; }

.bx-wrapper .bx-pager,
.bx-wrapper .bx-controls-auto {
  position: absolute;
  bottom: -30px;
  width: 100%; }

/* LOADER */
.bx-wrapper .bx-loading {
  min-height: 50px;
  background: url("images/bx_loader.gif") center center no-repeat #ffffff;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2000; }

/* PAGER */
.bx-wrapper .bx-pager {
  text-align: center;
  font-size: .85em;
  font-family: Arial;
  font-weight: bold;
  color: #666;
  padding-top: 20px; }

.bx-wrapper .bx-pager.bx-default-pager a {
  background: #666;
  text-indent: -9999px;
  display: block;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  outline: 0;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px; }

.bx-wrapper .bx-pager.bx-default-pager a:hover,
.bx-wrapper .bx-pager.bx-default-pager a.active,
.bx-wrapper .bx-pager.bx-default-pager a:focus {
  background: #000; }

.bx-wrapper .bx-pager-item,
.bx-wrapper .bx-controls-auto .bx-controls-auto-item {
  display: inline-block;
  vertical-align: bottom;
  *zoom: 1;
  *display: inline; }

.bx-wrapper .bx-pager-item {
  font-size: 0;
  line-height: 0; }

/* DIRECTION CONTROLS (NEXT / PREV) */
.bx-wrapper .bx-prev {
  left: 10px;
  background: url("images/controls.png") no-repeat 0 -32px; }

.bx-wrapper .bx-prev:hover,
.bx-wrapper .bx-prev:focus {
  background-position: 0 0; }

.bx-wrapper .bx-next {
  right: 10px;
  background: url("images/controls.png") no-repeat -43px -32px; }

.bx-wrapper .bx-next:hover,
.bx-wrapper .bx-next:focus {
  background-position: -43px 0; }

.bx-wrapper .bx-controls-direction a {
  position: absolute;
  top: 50%;
  margin-top: -16px;
  outline: 0;
  width: 32px;
  height: 32px;
  text-indent: -9999px;
  z-index: 9999; }

.bx-wrapper .bx-controls-direction a.disabled {
  display: none; }

/* AUTO CONTROLS (START / STOP) */
.bx-wrapper .bx-controls-auto {
  text-align: center; }

.bx-wrapper .bx-controls-auto .bx-start {
  display: block;
  text-indent: -9999px;
  width: 10px;
  height: 11px;
  outline: 0;
  background: url("images/controls.png") -86px -11px no-repeat;
  margin: 0 3px; }

.bx-wrapper .bx-controls-auto .bx-start:hover,
.bx-wrapper .bx-controls-auto .bx-start.active,
.bx-wrapper .bx-controls-auto .bx-start:focus {
  background-position: -86px 0; }

.bx-wrapper .bx-controls-auto .bx-stop {
  display: block;
  text-indent: -9999px;
  width: 9px;
  height: 11px;
  outline: 0;
  background: url("images/controls.png") -86px -44px no-repeat;
  margin: 0 3px; }

.bx-wrapper .bx-controls-auto .bx-stop:hover,
.bx-wrapper .bx-controls-auto .bx-stop.active,
.bx-wrapper .bx-controls-auto .bx-stop:focus {
  background-position: -86px -33px; }

/* PAGER WITH AUTO-CONTROLS HYBRID LAYOUT */
.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-pager {
  text-align: left;
  width: 80%; }

.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-controls-auto {
  right: 0;
  width: 35px; }

/* IMAGE CAPTIONS */
.bx-wrapper .bx-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  background: #666;
  background: rgba(80, 80, 80, 0.75);
  width: 100%; }

.bx-wrapper .bx-caption span {
  color: #fff;
  font-family: Arial;
  display: block;
  font-size: .85em;
  padding: 10px; }

.btn {
  border: solid 1px #000000;
  border-radius: 15px;
  padding: 8px 10px 5px 10px;
  width: 100%;
  display: block;
  text-align: center;
  cursor: pointer; }
  .btn:hover {
    background: #000000;
    color: #ffffff; }

.loadmore {
  padding: 20px 50px; }
  .loadmore a {
    max-width: 300px;
    margin: auto; }

.dvgui-form-group {
  position: relative;
  margin: 0 0 5px 0;
  width: 100%;
  z-index: 0;
  clear: both; }
  .dvgui-form-group .dvgui-text,
  .dvgui-form-group .dvgui-select,
  .dvgui-form-group .dvgui-checkbox,
  .dvgui-form-group .dvgui-file-upload,
  .dvgui-form-group .dvgui-document-upload,
  .dvgui-form-group .dvgui-image-upload,
  .dvgui-form-group .dvgui-radio {
    display: block;
    width: 75%;
    margin-left: 25%;
    padding: 2px 10px;
    border-radius: 0;
    border: 0;
    background: #ffffff;
    color: #000000;
    font-size: 20px;
    font-weight: 400;
    box-shadow: none;
    position: relative;
    border-bottom: solid 1px #000000;
    font-family: "TimesLTStd-Semibold", serif;
    outline: none;
    /*
		&:focus:not([readonly]) {
			~ .help-block {
				color: $color-black;    
                display: block;
				opacity: 1;
			}
		} */
    /*
        &[readonly],
        &.edited,
        &:focus:not([readonly]),  
        &.focus:not([readonly]) {			 
            ~ label {
				left: 0; 
				color: $color-black;  
                top: 0; 
                font-size: 12px;
            }
        }
        */ }
    .dvgui-form-group .dvgui-text ~ label,
    .dvgui-form-group .dvgui-select ~ label,
    .dvgui-form-group .dvgui-checkbox ~ label,
    .dvgui-form-group .dvgui-file-upload ~ label,
    .dvgui-form-group .dvgui-document-upload ~ label,
    .dvgui-form-group .dvgui-image-upload ~ label,
    .dvgui-form-group .dvgui-radio ~ label {
      width: 100%;
      position: absolute;
      top: 9px;
      left: 0;
      bottom: 0;
      pointer-events: none;
      /*font-size: 16px;*/
      transition: 0.2s ease all;
      color: #000000;
      margin: 0; }
      .dvgui-form-group .dvgui-text ~ label:after,
      .dvgui-form-group .dvgui-select ~ label:after,
      .dvgui-form-group .dvgui-checkbox ~ label:after,
      .dvgui-form-group .dvgui-file-upload ~ label:after,
      .dvgui-form-group .dvgui-document-upload ~ label:after,
      .dvgui-form-group .dvgui-image-upload ~ label:after,
      .dvgui-form-group .dvgui-radio ~ label:after {
        content: '';
        position: absolute;
        z-index: 5;
        bottom: 0;
        left: 25%;
        width: 0;
        height: 3px;
        width: 0;
        visibility: hidden;
        transition: 0.2s ease all; }
    .dvgui-form-group .dvgui-text ~ .help-block,
    .dvgui-form-group .dvgui-select ~ .help-block,
    .dvgui-form-group .dvgui-checkbox ~ .help-block,
    .dvgui-form-group .dvgui-file-upload ~ .help-block,
    .dvgui-form-group .dvgui-document-upload ~ .help-block,
    .dvgui-form-group .dvgui-image-upload ~ .help-block,
    .dvgui-form-group .dvgui-radio ~ .help-block {
      color: #000000;
      display: none; }
      .dvgui-form-group .dvgui-text ~ .help-block:not(:empty),
      .dvgui-form-group .dvgui-select ~ .help-block:not(:empty),
      .dvgui-form-group .dvgui-checkbox ~ .help-block:not(:empty),
      .dvgui-form-group .dvgui-file-upload ~ .help-block:not(:empty),
      .dvgui-form-group .dvgui-document-upload ~ .help-block:not(:empty),
      .dvgui-form-group .dvgui-image-upload ~ .help-block:not(:empty),
      .dvgui-form-group .dvgui-radio ~ .help-block:not(:empty) {
        opacity: 1; }
    .dvgui-form-group .dvgui-text[readonly],
    .dvgui-form-group .dvgui-select[readonly],
    .dvgui-form-group .dvgui-checkbox[readonly],
    .dvgui-form-group .dvgui-file-upload[readonly],
    .dvgui-form-group .dvgui-document-upload[readonly],
    .dvgui-form-group .dvgui-image-upload[readonly],
    .dvgui-form-group .dvgui-radio[readonly] {
      color: #999999;
      cursor: not-allowed; }
    .dvgui-form-group .dvgui-text:focus:not([readonly]) ~ label:after, .dvgui-form-group .dvgui-text.focus:not([readonly]) ~ label:after,
    .dvgui-form-group .dvgui-select:focus:not([readonly]) ~ label:after,
    .dvgui-form-group .dvgui-select.focus:not([readonly]) ~ label:after,
    .dvgui-form-group .dvgui-checkbox:focus:not([readonly]) ~ label:after,
    .dvgui-form-group .dvgui-checkbox.focus:not([readonly]) ~ label:after,
    .dvgui-form-group .dvgui-file-upload:focus:not([readonly]) ~ label:after,
    .dvgui-form-group .dvgui-file-upload.focus:not([readonly]) ~ label:after,
    .dvgui-form-group .dvgui-document-upload:focus:not([readonly]) ~ label:after,
    .dvgui-form-group .dvgui-document-upload.focus:not([readonly]) ~ label:after,
    .dvgui-form-group .dvgui-image-upload:focus:not([readonly]) ~ label:after,
    .dvgui-form-group .dvgui-image-upload.focus:not([readonly]) ~ label:after,
    .dvgui-form-group .dvgui-radio:focus:not([readonly]) ~ label:after,
    .dvgui-form-group .dvgui-radio.focus:not([readonly]) ~ label:after {
      visibility: visible;
      right: 0;
      width: 75%;
      background: #00ff00; }
    .dvgui-form-group .dvgui-text.dvForm_missed,
    .dvgui-form-group .dvgui-select.dvForm_missed,
    .dvgui-form-group .dvgui-checkbox.dvForm_missed,
    .dvgui-form-group .dvgui-file-upload.dvForm_missed,
    .dvgui-form-group .dvgui-document-upload.dvForm_missed,
    .dvgui-form-group .dvgui-image-upload.dvForm_missed,
    .dvgui-form-group .dvgui-radio.dvForm_missed {
      background: #ccffcc; }
  .dvgui-form-group .dvgui-select {
    padding: 2px 30px 2px 10px;
    appearance: none;
    outline: 0;
    appearance: none;
    text-indent: -1px;
    outline: 0; }
    .dvgui-form-group .dvgui-select ~ label {
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='15' height='10' viewBox='7 10 10 5' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23666666' fill-rule='evenodd' opacity='1' d='M7 10l5 5 5-5z'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: right 20px bottom 10px; }
    .dvgui-form-group .dvgui-select.edited:not([readonly]) ~ label, .dvgui-form-group .dvgui-select:focus:not([readonly]) ~ label {
      background-position: right 10px bottom 10px; }
  .dvgui-form-group .dvgui-checkbox {
    position: relative;
    border: 0;
    background: transparent;
    padding-top: 0;
    width: 100%;
    margin-left: 0;
    min-height: 45px;
    padding: 10px 0 0 40px; }
    .dvgui-form-group .dvgui-checkbox input[type=checkbox] {
      visibility: hidden;
      position: absolute; }
    .dvgui-form-group .dvgui-checkbox label {
      cursor: pointer;
      padding: 0 0 0 30px;
      margin: 0;
      width: 100%;
      text-align: left;
      display: block; }
    .dvgui-form-group .dvgui-checkbox ~ label {
      left: 0;
      color: #000000;
      top: 0;
      font-size: 12px; }
    .dvgui-form-group .dvgui-checkbox label > span {
      display: block;
      position: absolute;
      left: 0;
      -webkit-transition-duration: 0.3s;
      -moz-transition-duration: 0.3s;
      transition-duration: 0.3s; }
    .dvgui-form-group .dvgui-checkbox label > span.inc {
      background: #fff;
      left: -20px;
      top: -16px;
      height: 60px;
      width: 60px;
      opacity: 0;
      border-radius: 50% !important;
      -moz-border-radius: 50% !important;
      -webkit-border-radius: 50% !important; }
    .dvgui-form-group .dvgui-checkbox label > .box {
      top: 4px;
      height: 40px;
      width: 40px;
      z-index: 5;
      -webkit-transition-delay: 0.2s;
      -moz-transition-delay: 0.2s;
      transition-delay: 0.2s;
      border: 1px solid #000000;
      background: #ffffff; }
    .dvgui-form-group .dvgui-checkbox label > .check {
      top: 5px;
      left: 13px;
      width: 15px;
      height: 30px;
      border: 4px solid #00ff00;
      border-top: none;
      border-left: none;
      opacity: 0;
      z-index: 5;
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      transform: rotate(180deg);
      -webkit-transition-delay: 0.3s;
      -moz-transition-delay: 0.3s;
      transition-delay: 0.3s; }
    .dvgui-form-group .dvgui-checkbox label > span.inc {
      -webkit-animation: growCircle 0.3s ease;
      -moz-animation: growCircle 0.3s ease;
      animation: growCircle 0.3s ease; }
    .dvgui-form-group .dvgui-checkbox input[type=checkbox]:checked ~ label > .box {
      /*
          opacity:1;
          -webkit-transform   :scale(0) rotate(-180deg);
          -moz-transform        :scale(0) rotate(-180deg);
          transform                 :scale(0) rotate(-180deg);*/
      background: none; }
    .dvgui-form-group .dvgui-checkbox input[type=checkbox]:checked ~ label > .check {
      opacity: 1;
      -webkit-transform: scale(1) rotate(45deg);
      -moz-transform: scale(1) rotate(45deg);
      transform: scale(1) rotate(45deg); }
    .dvgui-form-group .dvgui-checkbox input[type=checkbox]:disabled ~ label,
    .dvgui-form-group .dvgui-checkbox input[type=checkbox][disabled] ~ label {
      cursor: not-allowed;
      opacity: 0.5; }
    .dvgui-form-group .dvgui-checkbox input[type=checkbox]:disabled ~ label > .box,
    .dvgui-form-group .dvgui-checkbox input[type=checkbox][disabled] ~ label > .box {
      cursor: not-allowed;
      opacity: 0.5; }
    .dvgui-form-group .dvgui-checkbox input[type=checkbox]:disabled:checked ~ label > .check,
    .dvgui-form-group .dvgui-checkbox input[type=checkbox][disabled]:checked ~ label > .check {
      cursor: not-allowed;
      opacity: 0.5; }
  .dvgui-form-group .dvgui-file-upload,
  .dvgui-form-group .dvgui-image-upload,
  .dvgui-form-group .dvgui-document-upload {
    display: block;
    visibility: hidden;
    width: 0;
    height: 0; }
    .dvgui-form-group .dvgui-file-upload ~ label,
    .dvgui-form-group .dvgui-image-upload ~ label,
    .dvgui-form-group .dvgui-document-upload ~ label {
      left: 0;
      color: #000000;
      top: 0;
      font-size: 12px; }
    .dvgui-form-group .dvgui-file-upload ~ .dvgui-file-result,
    .dvgui-form-group .dvgui-image-upload ~ .dvgui-file-result,
    .dvgui-form-group .dvgui-document-upload ~ .dvgui-file-result {
      display: inline-block;
      margin-left: 20px; }
    .dvgui-form-group .dvgui-file-upload ~ .dvgui-file-progress,
    .dvgui-form-group .dvgui-image-upload ~ .dvgui-file-progress,
    .dvgui-form-group .dvgui-document-upload ~ .dvgui-file-progress {
      width: 100%;
      background: #ffffff;
      margin: 5px 0 5px 0;
      clear: both;
      overflow: hidden;
      display: none; }
      .dvgui-form-group .dvgui-file-upload ~ .dvgui-file-progress div,
      .dvgui-form-group .dvgui-image-upload ~ .dvgui-file-progress div,
      .dvgui-form-group .dvgui-document-upload ~ .dvgui-file-progress div {
        color: #FFFFFF;
        text-align: right;
        padding: 2px 0;
        overflow: hidden;
        background: #cccccc; }
        .dvgui-form-group .dvgui-file-upload ~ .dvgui-file-progress div span,
        .dvgui-form-group .dvgui-image-upload ~ .dvgui-file-progress div span,
        .dvgui-form-group .dvgui-document-upload ~ .dvgui-file-progress div span {
          display: block;
          margin: 4px 10px 0 0; }
    .dvgui-form-group .dvgui-file-upload ~ .dvgui-file-notice,
    .dvgui-form-group .dvgui-image-upload ~ .dvgui-file-notice,
    .dvgui-form-group .dvgui-document-upload ~ .dvgui-file-notice {
      margin: 0 0 20px 0;
      border: 0;
      padding: 10px;
      background: #fffdca;
      border-left: 5px solid #ffc71a;
      display: none; }
  .dvgui-form-group .dvgui-radio {
    position: relative;
    border: 0;
    background: transparent;
    padding-top: 0;
    width: 100%;
    margin-left: 0;
    min-height: 45px;
    padding: 0 0 0 40px;
    margin-bottom: 20px; }
    .dvgui-form-group .dvgui-radio.inline {
      display: inline-block;
      margin-right: 20px; }
    .dvgui-form-group .dvgui-radio input[type=radio] {
      visibility: hidden;
      position: absolute; }
    .dvgui-form-group .dvgui-radio label {
      cursor: pointer;
      padding: 0 0 0 20px;
      margin: 0;
      width: 100%;
      text-align: left;
      display: block; }
    .dvgui-form-group .dvgui-radio ~ label {
      left: 0;
      color: #000000;
      top: 0;
      font-size: 12px; }
    .dvgui-form-group .dvgui-radio label > span {
      display: block;
      position: absolute;
      left: 0;
      transition-duration: 0.3s; }
    .dvgui-form-group .dvgui-radio label > span.inc {
      background: #fff;
      left: -20px;
      top: -20px;
      height: 60px;
      width: 60px;
      opacity: 0;
      border-radius: 50% !important; }
    .dvgui-form-group .dvgui-radio label > .box {
      top: 0px;
      height: 40px;
      width: 40px;
      border-radius: 50% !important;
      z-index: 5;
      border: solid 1px #000000;
      background: #ffffff; }
    .dvgui-form-group .dvgui-radio label > .check {
      top: 1px;
      left: 1px;
      width: 38px;
      height: 38px;
      background: #00ff00;
      opacity: 0;
      z-index: 6;
      border-radius: 50% !important;
      transform: scale(0); }
    .dvgui-form-group .dvgui-radio label > span.inc {
      -webkit-animation: growCircle 0.3s ease;
      -moz-animation: growCircle 0.3s ease;
      animation: growCircle 0.3s ease; }
    .dvgui-form-group .dvgui-radio input[type=radio]:checked ~ label > .check {
      opacity: 1;
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      transform: scale(1); }
    .dvgui-form-group .dvgui-radio input[type=radio]:disabled ~ label,
    .dvgui-form-group .dvgui-radio input[type=radio][disabled] ~ label {
      cursor: not-allowed;
      opacity: 0.5; }
    .dvgui-form-group .dvgui-radio input[type=radio]:disabled ~ label > .box,
    .dvgui-form-group .dvgui-radio input[type=radio][disabled] ~ label > .box {
      cursor: not-allowed;
      opacity: 0.5; }
    .dvgui-form-group .dvgui-radio input[type=radio]:disabled:checked ~ label > .check,
    .dvgui-form-group .dvgui-radio input[type=radio][disabled]:checked ~ label > .check {
      cursor: not-allowed;
      opacity: 0.5; }
  .dvgui-form-group .help-block {
    position: absolute;
    margin: 2px 0 0 0;
    opacity: 0;
    font-size: 13px; }
  .dvgui-form-group .help-block {
    position: absolute;
    margin: 2px 0 0 0;
    opacity: 0;
    font-size: 13px; }
  .dvgui-form-group.descriptors-container {
    overflow: hidden; }
    .dvgui-form-group.descriptors-container .dvgui-checkbox {
      width: 150px;
      float: left; }
      .dvgui-form-group.descriptors-container .dvgui-checkbox b {
        background: #ffffff;
        padding: 5px;
        margin-right: 5px;
        display: inline-block;
        border: solid 1px #cccccc;
        line-height: 12px;
        font-weight: normal;
        color: #000000;
        font-size: 12px; }
  .dvgui-form-group.w20 {
    width: 20%;
    display: inline-block; }
  .dvgui-form-group.w30 {
    width: 30%;
    display: inline-block;
    width: calc(30% - 20px);
    float: left;
    clear: none; }
  .dvgui-form-group.w50 {
    width: 50%;
    width: calc(50% - 20px);
    display: inline-block;
    float: left;
    clear: none; }
  .dvgui-form-group.w70 {
    width: 70%;
    width: calc(70% - 20px);
    display: inline-block;
    float: left;
    clear: none; }
  .dvgui-form-group.w160 {
    width: 160px;
    display: inline-block; }
  .dvgui-form-group.inline .dvgui-radio {
    display: inline-block;
    width: 150px; }

.dvgui-form-break {
  width: 100%;
  height: 1px; }

.form-content {
  font-size: 16px;
  margin: 10px 0 0 25%; }

@-webkit-keyframes growCircle {
  0%, 100% {
    -webkit-transform: scale(0);
    opacity: 0.5; }
  70% {
    background: #00ff00;
    -webkit-transform: scale(1.25); } }

@-moz-keyframes growCircle {
  0%, 100% {
    -moz-transform: scale(0);
    opacity: 0.5; }
  70% {
    background: #00ff00;
    -moz-transform: scale(1.25); } }

@keyframes growCircle {
  0%, 100% {
    transform: scale(0);
    opacity: 0.5; }
  70% {
    background: #00ff00;
    transform: scale(1.25); } }

.form-actions,
.notice-container {
  overflow: hidden;
  padding: 20px 0 20px 0;
  display: block;
  margin-top: -8px;
  margin-right: 20px;
  width: 100%;
  width: calc(100% - 20px); }
  .form-actions .btn,
  .notice-container .btn {
    float: right; }
    .form-actions .btn.nofr,
    .notice-container .btn.nofr {
      float: none; }

.actions .dvgui-form-group {
  display: inline-block;
  width: 150px;
  margin-top: -18px; }
  .actions .dvgui-form-group .dvgui-text {
    border: #cccccc solid 1px; }

.toggle-condition {
  display: none;
  overflow: hidden;
  clear: both; }
  .toggle-condition .show {
    display: block; }

.documents {
  list-style: none;
  margin-top: 10px; }
  .documents li {
    margin-bottom: 10px; }
    .documents li a {
      font-size: 16px; }
      .documents li a svg {
        fill: #000000;
        width: 30px;
        height: 30px;
        vertical-align: middle;
        margin-right: 10px; }

@media (max-width: 650px) {
  .dvgui-form-group {
    padding: 20px 0 0 0; }
    .dvgui-form-group .dvgui-text,
    .dvgui-form-group .dvgui-select,
    .dvgui-form-group .dvgui-checkbox,
    .dvgui-form-group .dvgui-file-upload,
    .dvgui-form-group .dvgui-image-upload,
    .dvgui-form-group .dvgui-document-upload,
    .dvgui-form-group .dvgui-radio {
      width: 100%;
      margin-left: 0;
      padding: 2px 0px; }
      .dvgui-form-group .dvgui-text ~ label,
      .dvgui-form-group .dvgui-select ~ label,
      .dvgui-form-group .dvgui-checkbox ~ label,
      .dvgui-form-group .dvgui-file-upload ~ label,
      .dvgui-form-group .dvgui-image-upload ~ label,
      .dvgui-form-group .dvgui-document-upload ~ label,
      .dvgui-form-group .dvgui-radio ~ label {
        top: 0;
        left: 0;
        bottom: 0; }
        .dvgui-form-group .dvgui-text ~ label:after,
        .dvgui-form-group .dvgui-select ~ label:after,
        .dvgui-form-group .dvgui-checkbox ~ label:after,
        .dvgui-form-group .dvgui-file-upload ~ label:after,
        .dvgui-form-group .dvgui-image-upload ~ label:after,
        .dvgui-form-group .dvgui-document-upload ~ label:after,
        .dvgui-form-group .dvgui-radio ~ label:after {
          bottom: 0;
          left: 0;
          width: 0; }
      .dvgui-form-group .dvgui-text label,
      .dvgui-form-group .dvgui-select label,
      .dvgui-form-group .dvgui-checkbox label,
      .dvgui-form-group .dvgui-file-upload label,
      .dvgui-form-group .dvgui-image-upload label,
      .dvgui-form-group .dvgui-document-upload label,
      .dvgui-form-group .dvgui-radio label {
        padding: 0 0 0 50px; }
      .dvgui-form-group .dvgui-text:focus:not([readonly]) ~ label:after, .dvgui-form-group .dvgui-text.focus:not([readonly]) ~ label:after,
      .dvgui-form-group .dvgui-select:focus:not([readonly]) ~ label:after,
      .dvgui-form-group .dvgui-select.focus:not([readonly]) ~ label:after,
      .dvgui-form-group .dvgui-checkbox:focus:not([readonly]) ~ label:after,
      .dvgui-form-group .dvgui-checkbox.focus:not([readonly]) ~ label:after,
      .dvgui-form-group .dvgui-file-upload:focus:not([readonly]) ~ label:after,
      .dvgui-form-group .dvgui-file-upload.focus:not([readonly]) ~ label:after,
      .dvgui-form-group .dvgui-image-upload:focus:not([readonly]) ~ label:after,
      .dvgui-form-group .dvgui-image-upload.focus:not([readonly]) ~ label:after,
      .dvgui-form-group .dvgui-document-upload:focus:not([readonly]) ~ label:after,
      .dvgui-form-group .dvgui-document-upload.focus:not([readonly]) ~ label:after,
      .dvgui-form-group .dvgui-radio:focus:not([readonly]) ~ label:after,
      .dvgui-form-group .dvgui-radio.focus:not([readonly]) ~ label:after {
        width: 100%; } }

#agenda-container {
  display: flex;
  height: 80vh;
  height: calc(100vh - 25vh); }

#events {
  width: 67%;
  overflow: hidden;
  border-top: solid 1px #000000;
  flex-grow: 2;
  cursor: pointer; }
  #events h1 {
    font-size: 25px;
    font-weight: normal;
    padding: 13px 0 7px 0;
    border-bottom: 1px solid #000000;
    height: 48px;
    margin-right: 25px; }
  #events .inner {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    width: 110%;
    width: calc(100% + 40px);
    -webkit-overflow-scrolling: touch; }

#separator-vertical {
  margin-left: 15px;
  padding-right: 15px;
  border-left: 1px solid #000000;
  flex-grow: 0; }

#day {
  width: 32%;
  overflow: hidden;
  border-top: solid 1px #000000;
  flex-grow: 1; }
  #day .inner {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100%;
    width: 110%;
    width: calc(100% + 40px); }

#calendar {
  padding-bottom: 10px;
  margin-right: 28px;
  border-bottom: solid 1px #000000; }

#events_day {
  margin-right: 28px; }
  #events_day .inner {
    overflow: auto;
    height: 100%;
    width: 110%;
    width: calc(100% + 40px); }
  #events_day h2.date {
    padding: 10px 0 10px 0;
    font-size: 25px;
    border-bottom: solid 1px #000000; }
  #events_day article {
    padding-bottom: 10px; }
    #events_day article small {
      display: none; }

article.event {
  display: flex;
  border-bottom: solid 1px #000000;
  padding: 10px 0 10px 0;
  margin-right: 25px;
  min-height: 100px;
  position: relative; }
  article.event h2 {
    font-size: 25px;
    flex-grow: 1;
    margin: 0;
    padding: 0;
    margin-right: 20px;
    padding-bottom: 35px; }
  article.event span {
    flex: 0 0 250px;
    margin-right: 20px;
    font-size: 25px; }
    article.event span.time {
      flex: 0 0 100px; }
  article.event div {
    flex: 0 0 150px;
    height: 150px;
    width: 150px;
    background: #ccc;
    background-size: cover;
    margin-right: 15px; }
  article.event small {
    position: absolute;
    bottom: 10px;
    font-size: 16px; }

#agenda-content,
#agenda-title {
  border-top: solid 1px #000000;
  width: 100%;
  overflow: hidden;
  border-top: solid 1px #000000;
  display: flex; }
  #agenda-content h1,
  #agenda-title h1 {
    font-size: 25px;
    padding: 20px 20px 40px 0;
    width: 16.666%;
    width: calc(16.666% + 12px); }
  #agenda-content .right,
  #agenda-title .right {
    padding: 20px 0 40px 0;
    width: 83.33%;
    width: calc(83.33% - 50px);
    max-width: 800px;
    font-size: 25px; }
    #agenda-content .right.notitle,
    #agenda-title .right.notitle {
      margin-left: 16.666%;
      margin-left: calc(16.666% + 12px); }
    #agenda-content .right .location,
    #agenda-title .right .location {
      /*margin-top: $space;*/ }
    #agenda-content .right .description,
    #agenda-title .right .description {
      font-size: 16px;
      margin-top: 20px; }
    #agenda-content .right .link,
    #agenda-title .right .link {
      font-size: 16px;
      margin-top: 20px;
      text-decoration: underline; }
  #agenda-content .inline-slider,
  #agenda-title .inline-slider {
    margin-top: 20px; }

#calendar .ui-datepicker {
  width: 100%; }

#calendar .ui-datepicker-header {
  border-top: 0; }

#calendar .ui-datepicker-month {
  padding-right: 0; }

#calendar .ui-datepicker-year {
  padding-left: 0; }

@media (max-width: 1000px) {
  article.event {
    min-height: 170px;
    flex-direction: column; }
    article.event h2 {
      padding-bottom: 10px;
      padding-right: 150px;
      flex-grow: 0; }
    article.event span {
      padding-bottom: 30px;
      padding-right: 150px;
      flex-basis: auto; }
    article.event div {
      position: absolute;
      top: 10px;
      right: 0; }
  #separator-vertical {
    display: none; }
  #day {
    display: none; }
  #events {
    margin-right: 0; }
  #agenda-content,
  #agenda-title {
    display: block; }
    #agenda-content h1,
    #agenda-title h1 {
      width: 100%; }
    #agenda-content .right,
    #agenda-title .right {
      width: 100%; }
      #agenda-content .right.notitle,
      #agenda-title .right.notitle {
        margin-left: 0; } }

@media (max-width: 750px) {
  .mbh {
    display: none; } }

@media (max-width: 450px) {
  #events h1 {
    font-size: 20px; }
  article.event {
    min-height: 120px; }
    article.event h2 {
      font-size: 20px;
      padding-right: 100px; }
    article.event span {
      font-size: 20px;
      padding-right: 100px;
      flex-basis: auto;
      padding-bottom: 10px; }
    article.event div {
      flex: 0 0 100px;
      height: 100px;
      width: 100px; }
    article.event small {
      display: none; } }

/*
@media(max-width:450px){
    #agenda-content,
    #agenda-title{
        h1{
            font-size: $font-size2; 
        }
        .right{
            font-size: $font-size2; 
        }
        div{
            &.lead{
                font-size: $font-size2; 
            }
        }
    }
    article.event{
        display: block;
        
        h2{
            width: 100%;
            width: calc(100% - 40px);
            padding-top: 35px;
            padding-bottom: 10px;
            padding-right: 0;
            font-size:  $font-size2
        }
        span{
            margin-right: $space;
            font-size: $font-size2;
            padding-right: 0;

            &.time{
                flex: 0 0 100px;        
            }
        }
        div{
            width: 100vw;
            height: 100vw;
            width: calc(100vw - 40px);
            height: calc(100vw - 40px);
            margin-bottom: 15px;
            position: relative;
        }
        small{
            position: absolute;
            bottom: auto;
            top: 10px
        }
    }
}
*/
body.news main {
  margin-right: 0; }

#news {
  width: 100%;
  overflow: hidden;
  margin-right: -50px; }

article.news {
  border-top: solid 1px #000000;
  padding: 10px 0 10px 0;
  margin-right: 50px;
  width: 16.666%;
  width: calc(16.666% - 50px);
  float: left; }
  article.news h2 {
    font-size: 25px;
    margin: 0;
    padding: 0; }
  article.news div.bottom {
    margin-top: 10px;
    height: 115px;
    font-size: 16px; }
    article.news div.bottom .grey {
      color: #999999; }
  article.news .header {
    height: 100px; }
  article.news .overflow {
    position: relative;
    overflow: hidden; }
  article.news div.img {
    padding-bottom: 100%;
    width: 100%;
    background: #ccc;
    background-position: center center;
    background-size: cover; }

#news-content,
#news-title {
  border-top: solid 1px #000000;
  width: 100%;
  overflow: hidden;
  border-top: solid 1px #000000;
  display: flex; }
  #news-content h1,
  #news-title h1 {
    font-size: 25px;
    padding: 20px 20px 40px 0;
    width: 16.666%;
    width: calc(16.666% + 12px); }
  #news-content .right,
  #news-title .right {
    padding: 20px 0 40px 0;
    width: 83.33%;
    width: calc(83.33% - 50px);
    max-width: 800px;
    font-size: 25px; }
    #news-content .right.notitle,
    #news-title .right.notitle {
      margin-left: 16.666%;
      margin-left: calc(16.666% + 12px); }
    #news-content .right .location,
    #news-title .right .location {
      /*margin-top: $space;*/ }
    #news-content .right .description,
    #news-title .right .description {
      font-size: 16px;
      margin-top: 20px; }
    #news-content .right .link,
    #news-title .right .link {
      font-size: 16px;
      margin-top: 20px;
      text-decoration: underline; }
  #news-content .inline-slider,
  #news-title .inline-slider {
    margin-top: 20px; }

.open-layer {
  cursor: pointer; }

.close-layer {
  cursor: pointer; }

@media (max-width: 3600px) {
  article.news {
    width: 16.666%;
    width: calc(16.666% - 50px); } }

@media (max-width: 2800px) {
  article.news {
    width: 20%;
    width: calc(20% - 50px); } }

@media (max-width: 2200px) {
  article.news {
    width: 25%;
    width: calc(25% - 50px); } }

@media (max-width: 1500px) {
  article.news {
    width: 33.333%;
    width: calc(33.333% - 50px); } }

@media (max-width: 1000px) {
  article.news {
    width: 50%;
    width: calc(50% - 25px);
    margin-right: 25px; }
  #news-content,
  #news-title {
    display: block; }
    #news-content h1,
    #news-title h1 {
      width: auto;
      padding-right: 0; }
    #news-content .right,
    #news-title .right {
      width: 100%; }
      #news-content .right.notitle,
      #news-title .right.notitle {
        margin-left: 0; } }

@media (max-width: 600px) {
  article.news {
    width: 100%;
    width: calc(100% - 25px); } }

body.benefits main {
  margin-right: 0; }

#benefits2 {
  width: 100%;
  overflow: hidden;
  margin-right: -50px; }

article.benefits2 {
  border-top: solid 1px #000000;
  padding: 10px 0 10px 0;
  margin-right: 50px;
  width: 16.666%;
  width: calc(16.666% - 50px);
  float: left; }
  article.benefits2 h2 {
    font-size: 25px;
    margin: 0;
    padding: 0; }
  article.benefits2 div.bottom {
    margin-top: 10px;
    height: 115px;
    font-size: 16px; }
    article.benefits2 div.bottom .grey {
      color: #999999; }
  article.benefits2 .header {
    height: 70px; }
  article.benefits2 .overflow {
    position: relative;
    overflow: hidden; }
  article.benefits2 div.img {
    padding-bottom: 100%;
    width: 100%;
    background: #ccc;
    background-position: center center;
    background-size: cover; }

#benefits-content,
#benefits-title {
  border-top: solid 1px #000000;
  width: 100%;
  overflow: hidden;
  border-top: solid 1px #000000;
  display: flex; }
  #benefits-content h1,
  #benefits-title h1 {
    font-size: 25px;
    padding: 20px 20px 40px 0;
    width: 16.666%;
    width: calc(16.666% + 12px); }
  #benefits-content .right,
  #benefits-title .right {
    padding: 20px 0 40px 0;
    width: 83.33%;
    width: calc(83.33% - 50px);
    max-width: 800px;
    font-size: 25px; }
    #benefits-content .right.notitle,
    #benefits-title .right.notitle {
      margin-left: 16.666%;
      margin-left: calc(16.666% + 12px); }
    #benefits-content .right .location,
    #benefits-title .right .location {
      /*margin-top: $space;*/ }
    #benefits-content .right .description,
    #benefits-title .right .description {
      font-size: 16px;
      margin-top: 20px; }
    #benefits-content .right .link,
    #benefits-title .right .link {
      font-size: 16px;
      margin-top: 20px;
      text-decoration: underline; }
  #benefits-content .inline-slider,
  #benefits-title .inline-slider {
    margin-top: 20px; }
  #benefits-content .benefits-notice,
  #benefits-title .benefits-notice {
    font-size: 16px;
    margin-top: 20px; }

body.benefits2 section#jobs {
  width: 100%;
  overflow: hidden;
  border-top: solid 1px #000000;
  display: flex;
  padding-bottom: 20px; }
  body.benefits2 section#jobs.layer {
    display: block; }
  body.benefits2 section#jobs h1 {
    font-size: 25px;
    padding: 20px 0 40px 0;
    width: 16.666%;
    width: calc(16.666% + 12px); }
  body.benefits2 section#jobs .right {
    padding: 20px 0 40px 0;
    width: 83.33%;
    width: calc(83.33% - 50px);
    max-width: 800px;
    font-size: 16px; }
  body.benefits2 section#jobs .full {
    padding: 20px 0 40px 0;
    width: 83.33%;
    width: calc(83.33% - 50px);
    font-size: 25px; }
    body.benefits2 section#jobs .full .right {
      padding: 0; }
  body.benefits2 section#jobs article {
    margin-bottom: 20px; }
    body.benefits2 section#jobs article h2 {
      font-size: 25px;
      margin: 0 0 20px; }
    body.benefits2 section#jobs article p {
      font-size: 12px;
      overflow: hidden; }
    body.benefits2 section#jobs article a.document, body.benefits2 section#jobs article a.link {
      color: #999999;
      font-size: 16px;
      margin-top: 10px;
      display: block; }
  body.benefits2 section#jobs article.jobs {
    display: flex;
    width: 100%;
    border-top: solid 1px #000000;
    padding: 10px 0 0 0;
    position: relative;
    cursor: pointer; }
    body.benefits2 section#jobs article.jobs h2 {
      font-size: 25px;
      flex-grow: 2;
      margin: 0;
      padding: 0;
      margin-right: 50px; }
    body.benefits2 section#jobs article.jobs .employer {
      flex: 0 0 300px; }

.open-layer {
  cursor: pointer; }

.close-layer {
  cursor: pointer; }

@media (max-width: 3600px) {
  article.benefits {
    width: 16.666%;
    width: calc(16.666% - 50px); }
  article.benefits2 {
    width: 16.666%;
    width: calc(16.666% - 50px); } }

@media (max-width: 2800px) {
  article.benefits {
    width: 20%;
    width: calc(20% - 50px); }
  article.benefits2 {
    width: 20%;
    width: calc(20% - 50px); } }

@media (max-width: 2200px) {
  article.benefits {
    width: 25%;
    width: calc(25% - 50px); }
  article.benefits2 {
    width: 25%;
    width: calc(25% - 50px); } }

@media (max-width: 1500px) {
  article.benefits {
    width: 33.333%;
    width: calc(33.333% - 50px); }
  article.benefits2 {
    width: 33.333%;
    width: calc(33.333% - 50px); } }

@media (max-width: 1000px) {
  article.benefits {
    width: 50%;
    width: calc(50% - 25px);
    margin-right: 25px; }
  article.benefits2 {
    width: 50%;
    width: calc(50% - 25px);
    margin-right: 25px; }
  #benefits-content,
  #benefits-title {
    display: block; }
    #benefits-content h1,
    #benefits-title h1 {
      width: auto;
      padding-right: 0; }
    #benefits-content .right,
    #benefits-title .right {
      width: 100%; }
      #benefits-content .right.notitle,
      #benefits-title .right.notitle {
        margin-left: 0; } }

@media (max-width: 600px) {
  article.benefits {
    width: 100%;
    width: calc(100% - 25px); }
  article.benefits2 {
    width: 100%;
    width: calc(100% - 25px); } }

body.program main {
  margin-right: 0; }

.agenda-program-entries-container {
  width: 100%;
  overflow: hidden;
  margin-right: -50px;
  margin-top: 2px;
  /* added by furbo paolo */
  display: grid;
  /*grid-template-columns: repeat(auto-fill, minmax(374px, 1fr));*/
  gap: 0;
  grid-template-columns: repeat(6, 1fr); }

article.program {
  border-top: solid 1px #000000;
  padding: 10px 0 10px 0;
  margin-right: 50px;
  /*
    width: $scale6; // 6 tiles
    width: calc(16.666% - 50px);
    float: left;
    */ }
  article.program svg {
    width: 18px;
    height: auto;
    float: right; }
  article.program h2 {
    font-size: 25px;
    margin: 0;
    padding: 0; }
  article.program div.bottom {
    margin-top: 10px;
    height: 115px;
    font-size: 16px; }
    article.program div.bottom .grey {
      color: #999999; }
  article.program .header {
    min-height: 100px; }
  article.program .overflow {
    position: relative;
    overflow: hidden; }
  article.program div.img {
    padding-bottom: 100%;
    width: 100%;
    background: #ccc;
    background-position: center center;
    background-size: cover; }

#program-content,
#program-title {
  border-top: solid 1px #000000;
  width: 100%;
  overflow: hidden;
  border-top: solid 1px #000000;
  display: flex; }
  #program-content h1,
  #program-title h1 {
    font-size: 25px;
    padding: 20px 20px 40px 0;
    width: 16.666%;
    width: calc(16.666% + 12px); }
  #program-content .right,
  #program-title .right {
    padding: 20px 0 40px 0;
    width: 83.33%;
    width: calc(83.33% - 50px);
    max-width: 800px;
    font-size: 25px; }
    #program-content .right.notitle,
    #program-title .right.notitle {
      margin-left: 16.666%;
      margin-left: calc(16.666% + 12px); }
    #program-content .right .location,
    #program-title .right .location {
      /*margin-top: $space;*/ }
    #program-content .right .description,
    #program-title .right .description {
      font-size: 16px;
      margin-top: 20px; }
    #program-content .right .link,
    #program-title .right .link {
      font-size: 16px;
      margin-top: 20px;
      text-decoration: underline; }
  #program-content .inline-slider,
  #program-title .inline-slider {
    margin-top: 20px; }

.open-layer {
  cursor: pointer; }

.close-layer {
  cursor: pointer; }

@media (min-width: 2561px) {
  .agenda-program-entries-container {
    grid-template-columns: repeat(6, 1fr); }
  /*
    article.program{
        width: $scale6; 
        width: calc(16.666% - 50px);
    }
     */ }

@media (max-width: 2560px) {
  .agenda-program-entries-container {
    grid-template-columns: repeat(5, 1fr); }
  /*
    article.program{
        width: $scale5; 
        width: calc(20% - 50px);
    }
     */ }

@media (max-width: 2200px) {
  .agenda-program-entries-container {
    grid-template-columns: repeat(4, 1fr); }
  /*
    article.program{
        width: $scale4; 
        width: calc(25% - 50px);
    }

     */ }

@media (max-width: 1500px) {
  .agenda-program-entries-container {
    grid-template-columns: repeat(3, 1fr); }
  /*
    article.program{
        width: $scale3; 
        width: calc(33.333% - 50px);
    }

     */ }

@media (max-width: 1000px) {
  .agenda-program-entries-container {
    grid-template-columns: repeat(2, 1fr); }
  article.program {
    margin-right: 25px; }
  article.program {
    margin-right: 25px; }
  /*
    article.program{
        width: $scale2; 
        width: calc(50% - 25px);
        margin-right: 25px;
    }
    #program-content,
    #program-title{
        display: block;
        
        h1{
            width: auto;
            padding-right: 0;
        }
        .right{
            width: 100%;
                
            &.notitle{
                margin-left: 0;
            }
        }
    }

     */ }

@media (max-width: 600px) {
  .agenda-program-entries-container {
    display: block; }
  article.program .header {
    height: auto;
    min-height: 0;
    margin-bottom: 10px; }
  /*
    article.program{
        width: $scale1; 
        width: calc(100% - 25px);
        
        .header {
            height: auto;
            margin-bottom: $space/2;
        }
    }

     */ }

#jobs {
  border-top: solid 1px #000000; }

article.job {
  display: flex;
  border-bottom: solid 1px #000000;
  padding: 10px 0 35px 0;
  min-height: 100px;
  position: relative;
  cursor: pointer; }
  article.job h2 {
    font-size: 25px;
    flex: 0 0 300px;
    margin: 0;
    padding: 0;
    margin-right: 50px; }
  article.job div {
    flex: 0 0 200px;
    margin-right: 50px;
    font-size: 25px; }
    article.job div.percent {
      flex: 0 0 250px; }
    article.job div.deadline {
      flex: 0 0 250px; }
    article.job div.lead {
      flex-grow: 2;
      max-width: 600px;
      font-size: 16px; }
      article.job div.lead .overflow {
        max-height: 58px;
        position: relative;
        overflow: hidden;
        display: block;
        /*
                &:after {
                    content: '';  
                    display: block;
                    width: 100%;
                    height: 10px;
                    background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 53%, rgba(255,255,255,1) 100%);
                    background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 53%,rgba(255,255,255,1) 100%);
                    background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 53%,rgba(255,255,255,1) 100%);
                    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );
                    position: absolute;
                    bottom: 0;
                    z-index: 10;
                }*/ }
      article.job div.lead .grey {
        color: #999999; }

#jobs-content,
#jobs-title {
  border-top: solid 1px #000000;
  width: 100%;
  overflow: hidden;
  border-top: solid 1px #000000;
  display: flex; }
  #jobs-content h1,
  #jobs-title h1 {
    font-size: 25px;
    padding: 20px 20px 40px 0;
    width: 16.666%;
    width: calc(16.666% + 12px); }
  #jobs-content .right,
  #jobs-title .right {
    padding: 20px 0 40px 0;
    width: 83.33%;
    width: calc(83.33% - 50px);
    max-width: 800px;
    font-size: 25px; }
    #jobs-content .right.notitle,
    #jobs-title .right.notitle {
      margin-left: 16.666%;
      margin-left: calc(16.666% + 12px); }
    #jobs-content .right .description,
    #jobs-title .right .description {
      font-size: 16px;
      margin-top: 20px; }
    #jobs-content .right .contact,
    #jobs-title .right .contact {
      font-size: 16px;
      margin-top: 20px; }

@media (max-width: 1300px) {
  article.job div.deadline {
    display: none; } }

@media (max-width: 1000px) {
  article.job h2 {
    flex: 0 0 60%; }
  article.job div {
    flex: 0 0 30%;
    margin-right: 50px; }
    article.job div.lead {
      display: none; }
  #jobs-content,
  #jobs-title {
    display: block; }
    #jobs-content h1,
    #jobs-title h1 {
      width: 100%; }
    #jobs-content .right,
    #jobs-title .right {
      width: 100%; }
      #jobs-content .right.notitle,
      #jobs-title .right.notitle {
        margin-left: 0; } }

@media (max-width: 500px) {
  article.job h2 {
    flex: 0 0 100%; }
  article.job div.percent {
    display: none; } }

@media (max-width: 450px) {
  #jobs-content h1,
  #jobs-title h1 {
    font-size: 20px; }
  #jobs-content .right,
  #jobs-title .right {
    font-size: 20px; }
  #jobs-content div.lead,
  #jobs-title div.lead {
    font-size: 20px; }
  article.job h2 {
    font-size: 20px; } }

body.alumni main {
  margin-right: 0; }

body.alumnifeatures section {
  width: 100%;
  overflow: hidden;
  border-top: solid 1px #000000;
  display: flex;
  padding-bottom: 20px; }
  body.alumnifeatures section.layer {
    display: block; }
  body.alumnifeatures section h1 {
    font-size: 25px;
    padding: 20px 0 40px 0;
    width: 16.666%;
    width: calc(16.666% + 12px); }
  body.alumnifeatures section .right {
    padding: 20px 0 40px 0;
    width: 83.33%;
    width: calc(83.33% - 50px);
    max-width: 800px;
    font-size: 16px; }
  body.alumnifeatures section .full {
    padding: 20px 0 40px 0;
    width: 83.33%;
    width: calc(83.33% - 50px);
    font-size: 25px; }
    body.alumnifeatures section .full .right {
      padding: 0; }

body.alumnifeatures #featured {
  width: 100%;
  overflow: hidden;
  border-top: solid 1px #000000; }
  body.alumnifeatures #featured .full {
    padding: 20px 0 40px 0;
    width: 83.33%;
    width: calc(83.33% - 50px);
    font-size: 25px; }
    body.alumnifeatures #featured .full a {
      color: #000000; }
  body.alumnifeatures #featured .featurerow {
    border-top: solid 1px #000000;
    padding: 15px 0;
    font-size: 25px;
    display: flex; }
    body.alumnifeatures #featured .featurerow a {
      flex-grow: 2;
      display: inline-block; }
      body.alumnifeatures #featured .featurerow a.name {
        flex: 30% 0 0;
        margin-right: 20px; }
      body.alumnifeatures #featured .featurerow a.podcast {
        flex: 50px 0 0;
        margin: -10px 0;
        height: 50px; }
        body.alumnifeatures #featured .featurerow a.podcast svg {
          width: 50px;
          height: 50px; }

#alumni {
  width: 100%;
  overflow: hidden;
  margin-right: -50px; }

article.alumni {
  border-top: solid 1px #000000;
  padding: 10px 0 10px 0;
  margin-right: 50px;
  width: 16.666%;
  width: calc(16.666% - 50px);
  float: left; }
  article.alumni h2 {
    font-size: 25px;
    margin: 0;
    padding: 0; }
  article.alumni div.bottom {
    margin-top: 10px;
    height: 165px;
    font-size: 16px; }
    article.alumni div.bottom .link-icons {
      margin-top: 10px; }
      article.alumni div.bottom .link-icons a {
        margin-right: 10px; }
        article.alumni div.bottom .link-icons a svg {
          width: 25px;
          height: 25px; }
  article.alumni .overflow1,
  article.alumni .overflow2 {
    height: 60px;
    position: relative;
    overflow: hidden; }
    article.alumni .overflow1:after,
    article.alumni .overflow2:after {
      content: '';
      display: block;
      width: 100%;
      height: 20px;
      background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, white 53%, white 100%);
      background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, white 53%, white 100%);
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 53%, white 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );
      position: absolute;
      bottom: 0;
      z-index: 10; }
    article.alumni .overflow1 h2,
    article.alumni .overflow1 div,
    article.alumni .overflow2 h2,
    article.alumni .overflow2 div {
      display: block;
      height: 50px;
      overflow-y: auto;
      overflow-x: hidden;
      width: calc(100% + 30px);
      padding-right: 30px;
      margin-bottom: 10px; }
      article.alumni .overflow1 h2 p,
      article.alumni .overflow1 div p,
      article.alumni .overflow2 h2 p,
      article.alumni .overflow2 div p {
        margin-bottom: 10px; }
  article.alumni .overflow1 {
    height: 80px; }
    article.alumni .overflow1 h2 {
      height: 70px; }
  article.alumni .overflow2 {
    height: 100px; }
    article.alumni .overflow2 div {
      height: 90px; }
  article.alumni div.img {
    padding-bottom: 100%;
    width: 100%;
    background: #ccc;
    background-position: center center;
    background-size: cover; }

.open-layer {
  cursor: pointer; }

.close-layer {
  cursor: pointer; }

@media (max-width: 1300px) {
  article.alumni {
    width: 16.666%;
    width: calc(16.666% - 50px); } }

@media (max-width: 1150px) {
  article.alumni {
    width: 20%;
    width: calc(20% - 50px); } }

@media (max-width: 1000px) {
  body.alumnifeatures #featured {
    display: block; }
    body.alumnifeatures #featured h1 {
      width: auto;
      padding-right: 0; }
    body.alumnifeatures #featured .right {
      width: 100%;
      margin-bottom: 10px; }
      body.alumnifeatures #featured .right.notitle {
        margin-left: 0; }
    body.alumnifeatures #featured .full {
      width: 100%; } }

@media (max-width: 950px) {
  article.alumni {
    width: 25%;
    width: calc(25% - 25px);
    margin-right: 25px; } }

@media (max-width: 750px) {
  article.alumni {
    width: 33.333%;
    width: calc(33.333% - 25px); } }

@media (max-width: 575px) {
  article.alumni {
    width: 50%;
    width: calc(50% - 25px); } }

@media (max-width: 400px) {
  article.alumni {
    width: 100%;
    width: calc(100% - 25px); } }

body.service section {
  width: 100%;
  overflow: hidden;
  border-top: solid 1px #000000;
  display: flex;
  padding-bottom: 20px; }
  body.service section.layer {
    display: block; }
  body.service section h1 {
    font-size: 25px;
    padding: 20px 0 40px 0;
    width: 16.666%;
    width: calc(16.666% + 12px); }
  body.service section .right {
    padding: 20px 0 40px 0;
    width: 83.33%;
    width: calc(83.33% - 50px);
    max-width: 800px;
    font-size: 16px; }
  body.service section .full {
    padding: 20px 0 40px 0;
    width: 83.33%;
    width: calc(83.33% - 50px);
    font-size: 25px; }
    body.service section .full .right {
      padding: 0; }
  body.service section article {
    margin-bottom: 20px; }
    body.service section article h2 {
      font-size: 25px;
      margin: 0 0 20px; }
    body.service section article p {
      font-size: 12px;
      overflow: hidden; }
    body.service section article a.document, body.service section article a.link {
      color: #999999;
      font-size: 16px;
      margin-top: 10px;
      display: block; }

body.service #benefits {
  display: flex; }
  body.service #benefits h1 {
    font-size: 25px;
    padding: 20px 0 40px 0;
    width: 16.666%;
    width: calc(16.666% + 12px); }
  body.service #benefits article {
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;
    border-top: solid 1px #000000; }
    body.service #benefits article h2 {
      padding: 20px 0 40px 0;
      font-size: 25px;
      width: 33.332%;
      width: calc(33.332% + 12px);
      margin: 0; }
    body.service #benefits article p {
      padding: 20px 0 20px 0;
      width: 66.664%;
      width: calc(66.664% - 50px);
      max-width: 800px;
      font-size: 16px; }
    body.service #benefits article:first-of-type {
      border-top: 0; }
      body.service #benefits article:first-of-type h2,
      body.service #benefits article:first-of-type p {
        padding-top: 0; }
    body.service #benefits article:nth-of-type(6n + 6) {
      margin-right: 0; }
    body.service #benefits article a.document, body.service #benefits article a.link {
      color: #999999;
      font-size: 16px;
      margin-top: 10px;
      display: block; }
  body.service #benefits .right {
    padding: 20px 0 40px 0;
    width: 83.33%;
    /*width: calc(83.33% - 50px);*/
    max-width: 800px;
    font-size: 16px; }
    body.service #benefits .right.noflex article {
      display: block; }

body.service #featured {
  width: 100%;
  overflow: hidden;
  border-top: solid 1px #000000; }
  body.service #featured .full {
    padding: 20px 0 40px 0;
    width: 83.33%;
    width: calc(83.33% - 50px);
    font-size: 25px; }
    body.service #featured .full a {
      color: #000000; }
  body.service #featured .featurerow {
    border-top: solid 1px #000000;
    padding: 15px 0;
    font-size: 25px;
    display: flex; }
    body.service #featured .featurerow a {
      flex-grow: 2;
      display: inline-block; }
      body.service #featured .featurerow a.name {
        flex: 30% 0 0;
        margin-right: 20px; }
      body.service #featured .featurerow a.podcast {
        flex: 50px 0 0;
        margin: -10px 0;
        height: 50px; }
        body.service #featured .featurerow a.podcast svg {
          width: 50px;
          height: 50px; }

body.service #jobs article.jobs {
  display: flex;
  width: 100%;
  border-top: solid 1px #000000;
  padding: 10px 0 0 0;
  position: relative;
  cursor: pointer; }
  body.service #jobs article.jobs h2 {
    font-size: 25px;
    flex-grow: 2;
    margin: 0;
    padding: 0;
    margin-right: 50px; }
  body.service #jobs article.jobs .employer {
    flex: 0 0 300px; }

@media (max-width: 1100px) {
  body.service #benefits .right,
  body.service #featured .right,
  body.service #jobs .right,
  body.service section .right {
    max-width: 100%; }
  body.service #benefits .full,
  body.service #featured .full,
  body.service #jobs .full,
  body.service section .full {
    width: 100%; }
  body.service section .right {
    max-width: 100%; } }

@media (max-width: 1000px) {
  body.service #benefits,
  body.service #featured,
  body.service #jobs,
  body.service section {
    display: block; }
    body.service #benefits h1,
    body.service #featured h1,
    body.service #jobs h1,
    body.service section h1 {
      width: 100%; }
    body.service #benefits .right,
    body.service #featured .right,
    body.service #jobs .right,
    body.service section .right {
      width: 100%; }
  body.service #benefits article {
    border: 0; }
    body.service #benefits article h2 {
      font-size: 20px; }
    body.service #benefits article p {
      padding-bottom: 0; } }

@media (max-width: 800px) {
  body.service #benefits article {
    display: block; }
    body.service #benefits article h2 {
      width: 100%;
      font-size: 20px;
      margin-bottom: 10px;
      padding-bottom: 0; }
    body.service #benefits article p {
      width: 100%;
      padding: 0; }
    body.service #benefits article a.document, body.service #benefits article a.link {
      margin-left: 0; }
  body.service #featured .featurerow {
    font-size: 20px; }
  body.service #jobs article.jobs {
    font-size: 20px; }
    body.service #jobs article.jobs h2 {
      font-size: 20px; } }

@media (max-width: 600px) {
  body.service #featured .featurerow a.name {
    flex: calc(100% - 70px) 0 0; }
  body.service #featured .featurerow a.hm {
    display: none; }
  body.service #jobs article.jobs .employer {
    display: none; } }

body.about section {
  border-top: solid 1px #000000;
  width: 100%;
  overflow: hidden;
  border-top: solid 1px #000000;
  display: flex;
  padding-bottom: 20px; }
  body.about section h1 {
    font-size: 25px;
    padding: 20px 0 40px 0;
    width: 16.666%;
    width: calc(16.666% + 12px); }
  body.about section .right {
    padding: 20px 0 40px 0;
    width: 83.33%;
    width: calc(83.33% - 50px);
    max-width: 800px;
    font-size: 16px; }
  body.about section .full {
    padding: 20px 0 40px 0;
    width: 83.33%;
    width: calc(83.33% - 50px);
    font-size: 25px; }
    body.about section .full .right {
      padding: 0; }
  body.about section article {
    margin-bottom: 20px; }
    body.about section article h2 {
      font-size: 25px;
      margin: 0 0 20px; }
    body.about section article p {
      font-size: 12px;
      overflow: hidden; }
    body.about section article a.document, body.about section article a.link {
      color: #999999;
      font-size: 16px;
      margin-top: 10px;
      display: block; }

#sponsors .sponsors {
  padding: 10px;
  float: left; }
  #sponsors .sponsors img {
    height: 100px; }

#benefits {
  width: 100%;
  overflow: hidden;
  border-top: solid 1px #000000;
  display: block; }
  #benefits h1 {
    width: 100%;
    font-size: 25px;
    padding: 20px 0 60px 0; }
  #benefits article {
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px; }
    #benefits article h2 {
      font-size: 25px;
      width: 16.666%;
      width: calc(16.666% + 12px);
      margin: 0; }
    #benefits article p {
      width: 83.33%;
      width: calc(83.33% - 50px);
      max-width: 800px;
      font-size: 16px; }
    #benefits article:nth-of-type(6n + 6) {
      margin-right: 0; }
    #benefits article a.document, #benefits article a.link {
      color: #999999;
      font-size: 16px;
      margin-top: 10px;
      display: block; }

#disclaimer1 article, #disclaimer2 article, #disclaimer3 article, #disclaimer4 article, #disclaimer5 article, #disclaimer6 article {
  width: 83.33%;
  width: calc(83.33% - 50px);
  overflow: hidden;
  display: flex;
  margin-bottom: 20px; }
  #disclaimer1 article h2, #disclaimer2 article h2, #disclaimer3 article h2, #disclaimer4 article h2, #disclaimer5 article h2, #disclaimer6 article h2 {
    font-size: 16px;
    flex: 0 0 50px;
    margin: 0; }
  #disclaimer1 article p, #disclaimer2 article p, #disclaimer3 article p, #disclaimer4 article p, #disclaimer5 article p, #disclaimer6 article p {
    max-width: 800px;
    font-size: 16px;
    flex-grow: 1; }

#board {
  width: 100%;
  overflow: hidden;
  border-top: solid 1px #000000;
  display: block; }
  #board .right {
    padding: 20px 0 40px 0;
    width: 83.33%;
    width: calc(83.33% - 50px);
    max-width: 800px; }
  #board article {
    margin-bottom: 20px;
    display: flex; }
    #board article h1, #board article h2 {
      font-size: 25px;
      padding: 20px 20px 40px 0;
      width: 16.666%;
      width: calc(16.666% + 12px); }
    #board article .right {
      padding: 20px 0 40px 0;
      width: 83.33%;
      width: calc(83.33% - 50px);
      max-width: 800px; }

@media (max-width: 1000px) {
  body.about section {
    display: block; }
    body.about section h1 {
      width: 100%; }
    body.about section .right {
      width: 100%;
      padding-top: 0; }
  #benefits article {
    display: block; }
    #benefits article h2 {
      width: 100%;
      font-size: 20px;
      margin-bottom: 10px; }
    #benefits article p {
      width: 100%; }
    #benefits article a.document, #benefits article a.link {
      margin-left: 0; }
  #board article {
    display: block; }
    #board article h2 {
      width: 100%;
      font-size: 20px;
      margin-bottom: 10px;
      padding: 0 20px 0 0; }
    #board article .right {
      width: 100%;
      padding-top: 0; } }

body.shop .hidden {
  display: none; }

body.shop .order,
body.shop .intro {
  border-top: solid 1px #000000;
  width: 100%;
  overflow: hidden;
  border-top: solid 1px #000000;
  display: flex;
  padding-bottom: 20px; }
  @media (max-width: 1000px) {
    body.shop .order,
    body.shop .intro {
      display: block; } }
  body.shop .order h1,
  body.shop .intro h1 {
    font-size: 25px;
    padding: 20px 0 20px 0;
    width: 16.666%;
    width: calc(16.666% + 12px); }
  body.shop .order .right,
  body.shop .intro .right {
    padding: 20px 0 40px 0;
    width: 83.33%;
    width: calc(83.33% - 50px);
    /*max-width: 800px;*/
    font-size: 16px;
    display: flex; }
    @media (max-width: 1000px) {
      body.shop .order .right,
      body.shop .intro .right {
        display: block;
        width: 100%; } }

body.shop .products {
  border-top: solid 1px #000000;
  width: 100%;
  overflow: hidden;
  border-top: solid 1px #000000;
  display: flex;
  padding-bottom: 20px;
  /*
        article{
            margin-bottom: $space;
            margin-right: 40px;
            h2{
                font-size: $font-size1;
                margin: 0 0 $space;
            }
            p{
                font-size: 12px;
                overflow: hidden;
            }
            a.document, a.link{
                color: $color-grey;
                font-size: $font-size3;
                margin-top: 10px;
                display: block;
            }
        }

         */ }
  @media (max-width: 1000px) {
    body.shop .products {
      display: block; } }
  body.shop .products h1 {
    font-size: 25px;
    padding: 20px 0 20px 0;
    width: 16.666%;
    width: calc(16.666% + 12px);
    margin-right: 10px; }
  body.shop .products .right {
    padding: 20px 0 40px 0;
    width: 83.33%;
    width: calc(83.33% - 50px);
    /*max-width: 800px;*/
    font-size: 16px;
    display: flex;
    /* Media queries for responsive card widths */ }
    @media (max-width: 1000px) {
      body.shop .products .right {
        display: block;
        width: 100%; } }
    body.shop .products .right .cards-container {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      /* Adjust based on your design */
      margin-left: -10px; }
      @media (max-width: 1000px) {
        body.shop .products .right .cards-container {
          display: block;
          margin-left: 0; } }
    body.shop .products .right article {
      border-radius: 12px;
      /*flex: 0 0 400px; Default card width */
      flex: 0 0 400px;
      /* Grow and shrink from this base */
      /*max-width: 400px; Maximum width */
      background-color: #eeeeee;
      /* For visibility */
      /* margin-bottom: 10px; /* Space between rows of cards */
      padding: 10px;
      /* Space inside the cards */ }
      @media (max-width: 1000px) {
        body.shop .products .right article {
          display: block;
          margin-bottom: 24px; } }
      body.shop .products .right article h2 {
        /*margin-bottom: 10px;*/
        margin-top: 10px; }
      body.shop .products .right article .lead,
      body.shop .products .right article .description {
        margin-bottom: 10px; }
      body.shop .products .right article img {
        width: 100%;
        /* Make the image fill the card */
        height: auto;
        /* Prevent distortion */
        border-radius: 12px; }
      body.shop .products .right article .shop-table {
        display: table;
        width: 100%;
        /* Adjust based on your preference */
        /*border-collapse: collapse;*/
        /*border: 1px #a6a6a6 solid;*/
        border-radius: 8px;
        padding: 2px;
        background-color: #e0e0e0; }
        body.shop .products .right article .shop-table .shop-quantity-container {
          display: table-row; }
          @media (max-width: 600px) {
            body.shop .products .right article .shop-table .shop-quantity-container {
              font-size: 16px; } }
          body.shop .products .right article .shop-table .shop-quantity-container .shop-cell {
            display: table-cell;
            padding: 8px;
            /* Adjust padding as needed */
            /*border: 1px solid #ccc; /* Optional: adds a border around cells */
            white-space: nowrap; }
          body.shop .products .right article .shop-table .shop-quantity-container .input {
            width: 100%; }
            @media (max-width: 600px) {
              body.shop .products .right article .shop-table .shop-quantity-container .input {
                font-size: 16px; } }
          body.shop .products .right article .shop-table .shop-quantity-container .shop-quantity-input {
            width: 100%;
            /* Make the input field fill the cell */
            max-width: 54px;
            padding: 4px; }
            @media (max-width: 600px) {
              body.shop .products .right article .shop-table .shop-quantity-container .shop-quantity-input {
                font-size: 16px; } }
          body.shop .products .right article .shop-table .shop-quantity-container .shop-quantity-label {
            white-space: nowrap;
            /* Prevents the content from wrapping */
            width: auto; }
    body.shop .products .right .card-filler {
      flex: 0 0 200px;
      visibility: hidden;
      /* Makes fillers invisible */ }
    @media (max-width: 800px) {
      body.shop .products .right article, body.shop .products .right .card-filler {
        /*flex: 0 0 150px; /* Smaller card width on smaller screens */ } }
    @media (max-width: 500px) {
      body.shop .products .right article, body.shop .products .right .card-filler {
        display: block;
        /*flex: 0 0 100px; /* Even smaller card width on very small screens */ } }
  body.shop .products .full {
    padding: 20px 0 40px 0;
    width: 83.33%;
    width: calc(83.33% - 50px);
    font-size: 25px; }
    body.shop .products .full .right {
      padding: 0; }

body.shop .form-container {
  max-width: 630px; }
  body.shop .form-container .separator {
    display: block;
    border-bottom: 1px solid #000;
    height: .001px;
    padding-top: 27px;
    margin-bottom: 20px; }

body.shop .orderintro {
  /*max-width: 800px;*/ }

body.prix {
  background: #000000;
  color: #ffffff; }
  body.prix article.alumni {
    border-top: solid 1px #ffffff; }
  body.prix #info {
    border-top: solid 1px #ffffff;
    width: 100%; }

#winner {
  width: 100%;
  overflow: hidden;
  border-top: solid 1px #ffffff; }
  #winner .winner-img {
    max-width: 650px;
    height: 400px;
    background-position: center center;
    background-size: cover;
    background-color: #ccc;
    margin: 20px auto;
    position: relative; }
    #winner .winner-img h1 {
      position: absolute;
      font-family: "netzhdk", serif;
      font-size: 18vh;
      color: #ffffff;
      padding: 0;
      text-align: center;
      letter-spacing: 5px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-shadow: 4px 4px 6px #666; }
  #winner h2 {
    font-size: 25px;
    padding: 20px 0 40px 0;
    text-align: center; }
  #winner p {
    max-width: 1000px;
    margin: 20px auto 60px auto;
    font-size: 16px; }

#nominee {
  width: 100%;
  overflow: hidden;
  border-top: solid 1px #ffffff; }
  #nominee h1 {
    font-size: 25px;
    padding: 40px 0 40px 0;
    text-align: center; }
  #nominee .overflow1,
  #nominee .overflow2 {
    height: 60px;
    position: relative;
    overflow: hidden; }
    #nominee .overflow1:after,
    #nominee .overflow2:after {
      content: '';
      display: block;
      width: 100%;
      height: 20px;
      background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, black 53%, black 100%);
      background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, black 53%, black 100%);
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, black 53%, black 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 );
      position: absolute;
      bottom: 0;
      z-index: 10; }
    #nominee .overflow1 h2,
    #nominee .overflow1 div,
    #nominee .overflow2 h2,
    #nominee .overflow2 div {
      display: block;
      height: 50px;
      overflow-y: auto;
      overflow-x: hidden;
      width: calc(100% + 30px);
      padding-right: 30px;
      margin-bottom: 10px; }
      #nominee .overflow1 h2 p,
      #nominee .overflow1 div p,
      #nominee .overflow2 h2 p,
      #nominee .overflow2 div p {
        margin-bottom: 10px; }
  #nominee .overflow1 {
    height: 80px; }
    #nominee .overflow1 h2 {
      height: 70px; }
  #nominee .overflow2 {
    height: 100px; }
    #nominee .overflow2 div {
      height: 90px; }
  #nominee .link-icons svg {
    fill: #ffffff; }

#nomination,
#procedure,
#jury {
  width: 100%;
  overflow: hidden;
  border-top: solid 1px #ffffff;
  display: flex; }
  #nomination h1,
  #procedure h1,
  #jury h1 {
    font-size: 25px;
    padding: 20px 0 40px 0;
    width: 16.666%;
    width: calc(16.666% + 12px); }
  #nomination .right,
  #procedure .right,
  #jury .right {
    padding: 20px 0 40px 0;
    width: 83.33%;
    width: calc(83.33% - 50px);
    max-width: 800px;
    font-size: 16px; }
    #nomination .right a,
    #procedure .right a,
    #jury .right a {
      color: #ffffff; }

@media (max-width: 1000px) {
  #nomination,
  #procedure,
  #jury {
    display: block; }
    #nomination .right,
    #procedure .right,
    #jury .right {
      padding-top: 0;
      width: 100%; } }

@media (max-width: 450px) {
  #info p {
    font-size: 20px; } }

body.story,
body.storydetail {
  background: #000000;
  color: #ffffff; }
  body.story header nav.main,
  body.storydetail header nav.main {
    /*background: rgba(0,0,0,0.5);*/ }
  body.story header nav.context ul li a.selected,
  body.storydetail header nav.context ul li a.selected {
    color: #ffffff; }
  body.story section.layer,
  body.storydetail section.layer {
    background: #000000; }
  body.story a,
  body.storydetail a {
    color: #ffffff; }

#history {
  width: 100%;
  overflow: hidden;
  border-top: solid 1px #ffffff;
  display: flex; }
  #history h1 {
    font-size: 25px;
    padding: 20px 0 40px 0;
    width: 16.666%;
    width: calc(16.666% + 12px); }
  #history .right {
    padding: 20px 0 40px 0;
    width: 83.33%;
    width: calc(83.33% - 50px);
    max-width: 800px;
    font-size: 25px; }
    #history .right .content-image {
      margin-bottom: 20px;
      max-width: 100%; }
    #history .right a {
      color: #ffffff; }

#history2,
#institutions,
#features {
  width: 100%;
  overflow: hidden;
  border-top: solid 1px #ffffff; }
  #history2 h1,
  #institutions h1,
  #features h1 {
    font-size: 25px;
    padding: 20px 0 40px 0;
    width: 100%; }
  #history2 .full,
  #institutions .full,
  #features .full {
    padding: 20px 0 40px 0;
    width: 100%;
    font-size: 25px; }
    #history2 .full a,
    #institutions .full a,
    #features .full a {
      color: #ffffff; }
  #history2 .featurerow,
  #institutions .featurerow,
  #features .featurerow {
    border-top: solid 1px #ffffff;
    padding: 15px 0;
    font-size: 25px;
    display: flex; }
    #history2 .featurerow a,
    #institutions .featurerow a,
    #features .featurerow a {
      flex-grow: 2;
      display: inline-block; }
      #history2 .featurerow a.name,
      #institutions .featurerow a.name,
      #features .featurerow a.name {
        flex: 30% 0 0;
        margin-right: 20px; }
  #history2 .institutionbox,
  #institutions .institutionbox,
  #features .institutionbox {
    margin-right: 25px;
    margin-left: 25px;
    margin-bottom: 50px;
    width: 16.666%;
    width: calc(16.666% - 50px);
    display: inline-block; }
    #history2 .institutionbox .img-container,
    #institutions .institutionbox .img-container,
    #features .institutionbox .img-container {
      width: 100%;
      padding-top: 68%;
      background: #999999;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center; }
    #history2 .institutionbox .name,
    #institutions .institutionbox .name,
    #features .institutionbox .name {
      display: flex;
      margin-top: 10px; }
      #history2 .institutionbox .name span,
      #institutions .institutionbox .name span,
      #features .institutionbox .name span {
        flex-grow: 2; }
        #history2 .institutionbox .name span.time,
        #institutions .institutionbox .name span.time,
        #features .institutionbox .name span.time {
          flex: 30% 0 0;
          margin-right: 20px; }

#history2 .full {
  font-size: 16px; }

#features .full {
  padding-bottom: 0;
  border-bottom: solid 1px #ffffff; }

#institutions .full {
  width: 100%;
  width: calc(100% + 50px);
  margin-left: -25px;
  margin-right: -25px; }

#institutions-close {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 560; }
  #institutions-close svg {
    fill: #ffffff; }

#institutions-title {
  position: absolute;
  top: 20px;
  left: 50px;
  z-index: 510; }
  #institutions-title h1 {
    font-size: 25px;
    text-shadow: 1px 1px 3px #666666; }

#institutions-content {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 500; }
  #institutions-content .bx-wrapper {
    box-shadow: 0;
    border: 0;
    background: #000000;
    margin: 0; }
    #institutions-content .bx-wrapper .bx-next {
      right: 20px;
      background: url("/dist/angle-right-white.svg") no-repeat 50% 50%; }
    #institutions-content .bx-wrapper .bx-prev {
      left: 20px;
      background: url("/dist/angle-left-white.svg") no-repeat 50% 50%; }
    #institutions-content .bx-wrapper .bx-caption {
      bottom: 20px;
      position: absolute;
      z-index: 555;
      background: none; }
      #institutions-content .bx-wrapper .bx-caption span {
        font-size: 16px;
        color: #ffffff;
        max-width: 800px;
        margin: auto; }
  #institutions-content .fullscreen {
    height: 100vh;
    width: 100vw;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; }
  #institutions-content .center {
    height: 100vh;
    width: 100vw;
    position: relative; }
    #institutions-content .center img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 90%;
      max-height: 90%; }
  #institutions-content .text {
    height: 100vh;
    width: 100vw;
    overflow-y: auto; }
    #institutions-content .text .overflow {
      padding: 0 100px;
      max-width: 1000px;
      margin: 100px auto 100px auto; }
    #institutions-content .text small {
      font-size: 16px; }

@media (max-width: 2800px) {
  #institutions .institutionbox {
    width: 20%;
    width: calc(20% - 50px); } }

@media (max-width: 2000px) {
  #institutions .institutionbox {
    width: 25%;
    width: calc(25% - 50px);
    margin-right: 25px; } }

@media (max-width: 1600px) {
  #institutions .institutionbox {
    width: 33.333%;
    width: calc(33.333% - 50px); } }

@media (max-width: 1200px) {
  #institutions .institutionbox {
    width: 50%;
    width: calc(50% - 50px); } }

@media (max-width: 750px) {
  #institutions .institutionbox {
    width: 100%;
    width: calc(100% - 50px); }
  #history {
    display: block; }
    #history h1 {
      width: 100%; }
    #history .right {
      width: 100%; } }

@media (max-width: 600px) {
  #history2 h1,
  #history2 h1,
  #institutions h1,
  #features h1 {
    font-size: 20px; }
  #history2 .full,
  #history2 .full,
  #institutions .full,
  #features .full {
    font-size: 20px; }
  #history2 .featurerow,
  #history2 .featurerow,
  #institutions .featurerow,
  #features .featurerow {
    font-size: 20px; } }

body.no-overflow {
  overflow: hidden; }

body.story #overlay_inner,
body.storydetail #overlay_inner,
body.prix #overlay_inner {
  color: #000000; }
  body.story #overlay_inner .white,
  body.storydetail #overlay_inner .white,
  body.prix #overlay_inner .white {
    color: #ffffff; }

#overlay {
  display: none;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  top: 0; }

#overlay_inner .loader {
  width: 200px;
  margin: 100px auto; }

#overlay_inner .contenbox {
  padding: 20px;
  background: #ffffff;
  box-shadow: 0px 0px 16px -4px rgba(0, 0, 0, 0.75);
  margin: 100px auto; }
  #overlay_inner .contenbox.small {
    width: 600px; }
  #overlay_inner .contenbox.small2 {
    width: 750px; }
    #overlay_inner .contenbox.small2 .dvgui-form-group .dvgui-text,
    #overlay_inner .contenbox.small2 .dvgui-form-group .dvgui-select,
    #overlay_inner .contenbox.small2 .dvgui-form-group .dvgui-checkbox,
    #overlay_inner .contenbox.small2 .dvgui-form-group .dvgui-file-upload,
    #overlay_inner .contenbox.small2 .dvgui-form-group .dvgui-radio {
      width: 66%;
      margin-left: 34%; }
      #overlay_inner .contenbox.small2 .dvgui-form-group .dvgui-text ~ label:after,
      #overlay_inner .contenbox.small2 .dvgui-form-group .dvgui-select ~ label:after,
      #overlay_inner .contenbox.small2 .dvgui-form-group .dvgui-checkbox ~ label:after,
      #overlay_inner .contenbox.small2 .dvgui-form-group .dvgui-file-upload ~ label:after,
      #overlay_inner .contenbox.small2 .dvgui-form-group .dvgui-radio ~ label:after {
        left: 34%; }
      #overlay_inner .contenbox.small2 .dvgui-form-group .dvgui-text:focus:not([readonly]) ~ label:after, #overlay_inner .contenbox.small2 .dvgui-form-group .dvgui-text.focus:not([readonly]) ~ label:after,
      #overlay_inner .contenbox.small2 .dvgui-form-group .dvgui-select:focus:not([readonly]) ~ label:after,
      #overlay_inner .contenbox.small2 .dvgui-form-group .dvgui-select.focus:not([readonly]) ~ label:after,
      #overlay_inner .contenbox.small2 .dvgui-form-group .dvgui-checkbox:focus:not([readonly]) ~ label:after,
      #overlay_inner .contenbox.small2 .dvgui-form-group .dvgui-checkbox.focus:not([readonly]) ~ label:after,
      #overlay_inner .contenbox.small2 .dvgui-form-group .dvgui-file-upload:focus:not([readonly]) ~ label:after,
      #overlay_inner .contenbox.small2 .dvgui-form-group .dvgui-file-upload.focus:not([readonly]) ~ label:after,
      #overlay_inner .contenbox.small2 .dvgui-form-group .dvgui-radio:focus:not([readonly]) ~ label:after,
      #overlay_inner .contenbox.small2 .dvgui-form-group .dvgui-radio.focus:not([readonly]) ~ label:after {
        width: 66%;
        background: #00ff00; }
  #overlay_inner .contenbox.mid {
    width: 900px; }
  #overlay_inner .contenbox.full {
    width: 90%; }
  #overlay_inner .contenbox h1 {
    font-size: 20px;
    margin-bottom: 40px; }
  #overlay_inner .contenbox a {
    color: #000000; }
    #overlay_inner .contenbox a.btn:hover {
      color: #ffffff; }
  #overlay_inner .contenbox header {
    display: block;
    border-bottom: solid 1px #000000;
    position: relative;
    top: auto;
    overflow: hidden;
    padding-bottom: 10px;
    margin-bottom: 5px;
    background: #ffffff; }
    #overlay_inner .contenbox header a {
      float: right;
      color: #000000; }
    #overlay_inner .contenbox header .steps {
      width: 200px;
      float: left; }
      #overlay_inner .contenbox header .steps a {
        display: inline-block;
        float: none;
        border: solid 1px #000000;
        border-radius: 50%;
        padding: 6px 5px 5px 5px;
        width: 35px;
        height: 35px;
        text-align: center;
        margin-left: 10px; }
        #overlay_inner .contenbox header .steps a.selected {
          background: #00ff00; }
  #overlay_inner .contenbox .separator {
    display: block;
    border-bottom: solid 1px #000000;
    height: 0.001px;
    padding-top: 27px;
    margin-bottom: 20px; }
  #overlay_inner .contenbox .cols {
    display: flex; }
    #overlay_inner .contenbox .cols .col {
      width: 50%;
      width: calc(50% - 10px);
      margin-right: 20px; }
      #overlay_inner .contenbox .cols .col:nth-of-type(2) {
        margin-right: 0; }
  #overlay_inner .contenbox div.img {
    padding-bottom: 100%;
    width: 100%;
    background: #ccc;
    background-position: center center;
    background-size: cover;
    margin-top: 0;
    margin-bottom: 20px; }
  #overlay_inner .contenbox .link-icons {
    overflow: hidden; }
    #overlay_inner .contenbox .link-icons a {
      float: left;
      margin-right: 10px;
      border: solid 1px #000000;
      border-radius: 50%;
      width: 36px;
      height: 36px; }
      #overlay_inner .contenbox .link-icons a.selected {
        background: #00ff00; }
      #overlay_inner .contenbox .link-icons a svg {
        width: 20px;
        height: 20px;
        margin: 7px; }

.close-icon svg {
  width: 25px;
  height: 25px; }

@media (max-width: 950px) {
  #overlay_inner .contenbox.mid {
    width: 100%;
    width: calc(100% - 40px); } }

@media (max-width: 650px) {
  #overlay_inner .contenbox.small, #overlay_inner .contenbox.small2, #overlay_inner .contenbox.mid {
    width: 100%;
    width: calc(100% - 40px); }
  #overlay_inner .contenbox .cols {
    display: block; }
    #overlay_inner .contenbox .cols .col {
      width: 100%;
      margin-right: 0;
      border-bottom: solid 1px #000000;
      padding-bottom: 27px;
      margin-bottom: 20px; } }

.note {
  margin: 0 0 30px 0;
  border: 0;
  border-left: 5px solid #ccc;
  padding: 20px; }
  .note.note-error {
    background: #ff9999;
    border-left: 5px solid #a6000f; }
  .note.note-success {
    background: #caffca;
    border-left: 5px solid #13a600; }
  .note.note-warning {
    background: #fffdca;
    border-left: 5px solid #ffc71a; }
  .note.inline {
    margin-left: 0;
    margin-right: 0; }
  .note.top {
    margin-top: 0; }

@media (max-width: 1024px) {
  .note {
    margin: 0 0 40px 0; } }

.file-upload {
  display: block;
  visibility: hidden;
  width: 0;
  height: 0; }

.file-upload-result,
.document-upload-result,
.image-upload-result {
  margin-top: 20px; }

.fileupload-progress {
  background: #999999;
  width: 100%; }
  .fileupload-progress div {
    background: #00ff00;
    text-align: right; }
    .fileupload-progress div span {
      padding: 5px; }

.image-item {
  border: solid 1px #000000;
  display: inline-block;
  margin-right: 2%;
  margin-bottom: 20px;
  width: 48%;
  min-height: 150px;
  position: relative; }
  .image-item:nth-child(2n+2) {
    margin-right: 0; }
  .image-item:hover .image-container {
    background-size: contain; }
  .image-item .delete-image, .image-item .paint-image, .image-item .move-image, .image-item .rotate-image {
    position: absolute;
    background: #000000;
    width: 40px;
    height: 40px;
    z-index: 10;
    top: 10px;
    right: 10px;
    cursor: pointer; }
    .image-item .delete-image svg, .image-item .paint-image svg, .image-item .move-image svg, .image-item .rotate-image svg {
      width: 30px;
      height: 30px;
      fill: #ffffff;
      margin: 5px; }
  .image-item .move-image {
    right: auto;
    bottom: auto;
    left: 10px;
    top: 10px; }
  .image-item .rotate-image {
    background: #999999;
    left: auto;
    top: auto;
    right: 10px;
    bottom: 82px; }
  .image-item .paint-image {
    background: #999999;
    left: auto;
    top: auto;
    right: 10px;
    bottom: 37px; }
  .image-item .image-container {
    width: 100%;
    height: 150px;
    border: 0;
    z-index: 1;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #000000; }
  .image-item input {
    border: 0;
    border-top: solid 1px #000000;
    width: 100%;
    font-size: 16px;
    line-height: 12px;
    padding: 5px;
    font-family: "TimesLTStd-Semibold", serif; }
  .image-item .formcheck-icon {
    height: 1rem;
    width: 1rem;
    fill: #00ff00;
    position: absolute;
    bottom: .3rem;
    right: .3rem; }

.document-item {
  border: solid 1px #000000;
  display: inline-block;
  margin-bottom: 20px;
  width: 100%;
  min-height: 30px;
  position: relative;
  background: #cccccc; }
  .document-item .delete-document, .document-item .move-document {
    position: absolute;
    background: #000000;
    width: 40px;
    height: 40px;
    z-index: 10;
    top: 10px;
    right: 10px;
    cursor: pointer; }
    .document-item .delete-document svg, .document-item .move-document svg {
      width: 30px;
      height: 30px;
      fill: #ffffff;
      margin: 5px; }
  .document-item .move-document {
    right: auto;
    bottom: auto;
    left: 10px;
    top: 10px; }
  .document-item .document-container {
    width: 100%;
    min-height: 30px;
    border: 0;
    z-index: 1;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #cccccc;
    padding: 20px 20px 20px 60px;
    overflow: hidden; }
  .document-item input {
    border: 0;
    border-top: solid 1px #000000;
    width: 100%;
    font-size: 16px;
    line-height: 12px;
    padding: 5px;
    font-family: "TimesLTStd-Semibold", serif; }
  .document-item .formcheck-icon {
    height: 1rem;
    width: 1rem;
    fill: #00ff00;
    position: absolute;
    bottom: .3rem;
    right: .3rem; }

.img-upload-4 {
  margin-top: 20px; }
  .img-upload-4 .image-item {
    margin-right: 2%;
    margin-bottom: 20px;
    width: 23%;
    min-height: 150px; }

@media (max-width: 450px) {
  .image-item {
    /*display: block;*/
    margin-right: 0;
    width: 100%;
    min-height: 250px; }
    .image-item .image-container {
      height: 250px; } }

/* calendar */
.ui-datepicker,
.ui-datepicker table,
.ui-datepicker tr,
.ui-datepicker td,
.ui-datepicker th {
  margin: 0;
  padding: 0;
  border: none;
  border-spacing: 0; }

.ui-datepicker {
  display: none;
  width: 400px;
  padding: 0;
  cursor: default;
  font-family: 'TimesLTStd-Semibold', serif;
  font-size: 25px;
  border-radius: 0;
  border: solid 1px #000000; }

.ui-widget.ui-widget-content {
  border: 0; }

.ui-datepicker-header {
  position: relative;
  padding-bottom: 10px;
  border-radius: 0;
  border: 0;
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;
  background: none;
  color: #000000; }

.ui-datepicker-title {
  position: relative;
  text-align: center; }

.ui-datepicker .ui-datepicker-title {
  line-height: 31px; }

.ui-datepicker-month {
  position: relative;
  padding-right: 20px;
  font-weight: normal; }

.ui-datepicker-year {
  padding-left: 20px;
  font-weight: normal; }

select.ui-datepicker-month,
select.ui-datepicker-year {
  font-family: "TimesLTStd-Semibold", serif;
  font-size: 14px;
  background: #ffffff;
  color: #000000; }

.ui-datepicker .ui-datepicker-title select {
  font-size: 14px; }

.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
  position: absolute;
  top: 0px;
  padding: 0;
  cursor: pointer;
  height: 30px;
  width: 30px; }
  .ui-datepicker .ui-datepicker-prev span,
  .ui-datepicker .ui-datepicker-next span {
    display: block;
    width: 34px;
    height: 34px;
    text-indent: -9999px;
    background-size: contain;
    background-position: center center;
    position: relative;
    margin: 0;
    top: 4px;
    left: auto; }
  .ui-datepicker .ui-datepicker-prev.ui-state-hover,
  .ui-datepicker .ui-datepicker-next.ui-state-hover {
    background: none;
    border: none;
    opacity: 0.5; }

.ui-datepicker .ui-datepicker-prev {
  left: 5px;
  padding-left: 0; }

.ui-datepicker .ui-datepicker-next {
  right: 5px;
  padding-right: 0; }

.ui-widget-header .ui-datepicker-prev span.ui-icon {
  background-image: url("/dist/angle-left.svg"); }

.ui-widget-header .ui-datepicker-next span {
  background-image: url("/dist/angle-right.svg"); }

.ui-datepicker-calendar th {
  padding-top: 15px;
  padding-bottom: 10px;
  text-align: center;
  font-weight: normal;
  color: #4d4d4d; }

.ui-datepicker-calendar td {
  padding: 0 7px;
  text-align: center;
  line-height: 26px; }

.ui-datepicker-calendar td {
  padding: 0 7px;
  text-align: center;
  line-height: 26px; }
  .ui-datepicker-calendar td a {
    display: block;
    outline: none;
    width: 34px;
    height: 34px;
    padding: 4px;
    line-height: 27px;
    text-align: center;
    border-radius: 50%;
    background: #000000;
    margin: auto; }
    .ui-datepicker-calendar td a.ui-state-default {
      color: #000000;
      border: 1px solid transparent;
      background: #ffffff; }
    .ui-datepicker-calendar td a.ui-state-highlight {
      color: #000000;
      border: 1px solid #000000;
      background: #ffffff; }
    .ui-datepicker-calendar td a.ui-state-active {
      color: #ffffff;
      border: 1px solid #000000;
      background: #000000; }

.ui-datepicker-calendar td.ui-datepicker-other-month .ui-state-default {
  text-align: center;
  border: 1px solid transparent;
  background: #ffffff;
  color: gray; }

.ui-datepicker-calendar td.ui-datepicker-unselectable .ui-state-default {
  border: none; }

body.home main,
body.featuredetail main {
  margin: 0;
  padding-top: 0; }

body.home header,
body.featuredetail header {
  position: absolute;
  z-index: 990; }
  body.home header .pos,
  body.featuredetail header .pos {
    display: none; }
  body.home header .neg,
  body.featuredetail header .neg {
    display: block; }

body.home.nav-open header,
body.featuredetail.nav-open header {
  display: none; }

body.home.feature-read header .pos,
body.featuredetail.feature-read header .pos {
  display: block; }

body.home.feature-read header .neg,
body.featuredetail.feature-read header .neg {
  display: none; }

body.home.feature-read.nav-open header .pos,
body.featuredetail.feature-read.nav-open header .pos {
  display: none; }

body.home.feature-read.nav-open header .neg,
body.featuredetail.feature-read.nav-open header .neg {
  display: block; }

body.featuredetail main {
  margin: 0;
  padding: 0; }

section.layer.featuredetail {
  padding: 0;
  background: #ffffff; }

#feature-bg {
  width: 100vw;
  height: 100vh;
  background-position: center;
  background-size: cover;
  position: absolute;
  z-index: 15;
  top: 0; }

#feature-content {
  width: 100%;
  min-height: 100vh;
  position: absolute;
  z-index: 20;
  top: 0;
  cursor: pointer;
  transition: all 0.5s ease-in-out; }
  #feature-content .feature-title,
  #feature-content .feature-lead {
    margin: 0;
    width: 100%;
    position: relative;
    height: 10vh; }
    #feature-content .feature-title h1, #feature-content .feature-title h2,
    #feature-content .feature-lead h1,
    #feature-content .feature-lead h2 {
      transition: all 0.5s;
      position: absolute;
      text-align: center;
      font-size: 9vh;
      color: #ffffff;
      text-shadow: 2px 2px 5px #999999; }
  #feature-content .feature-title {
    margin-top: 45vh; }
    #feature-content .feature-title h1 {
      left: 5vw; }
  #feature-content .feature-lead {
    margin-top: 5vh;
    margin-bottom: 20vh; }
    #feature-content .feature-lead h2 {
      right: 5vw; }
  #feature-content .feature-questions {
    display: none;
    font-size: 25px;
    padding-bottom: 50px;
    margin: 0 auto 40px auto;
    max-width: 900px; }
    #feature-content .feature-questions strong {
      display: block;
      margin: 0 50px 20px 50px;
      text-align: right;
      font-weight: normal; }
    #feature-content .feature-questions p {
      display: block;
      text-align: left;
      margin: 0 100px 0 50px; }
  #feature-content .inline-slider {
    display: none;
    font-size: 20px;
    margin: 0 auto 40px auto;
    width: 60%;
    border: none; }

#feature-close {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 560; }
  #feature-close svg {
    fill: #000000; }

body.feature-read #feature-content {
  background: #ffffff;
  color: #000000; }
  body.feature-read #feature-content .feature-title h1, body.feature-read #feature-content .feature-title h2,
  body.feature-read #feature-content .feature-lead h1,
  body.feature-read #feature-content .feature-lead h2 {
    text-align: center;
    color: #000000;
    text-shadow: none;
    width: max-content;
    max-width: 90vw; }
  body.feature-read #feature-content .feature-title {
    margin-top: 45vh; }
    body.feature-read #feature-content .feature-title h1 {
      left: 50%;
      transform: translate(-50%, 0); }
  body.feature-read #feature-content .feature-lead {
    margin-top: 5vh; }
    body.feature-read #feature-content .feature-lead h2 {
      right: 50%;
      transform: translate(50%, 0); }
  body.feature-read #feature-content .feature-questions {
    display: block; }
  body.feature-read #feature-content .inline-slider {
    display: block; }

body.nav-open {
  overflow: hidden; }
  body.nav-open #feature-bg {
    filter: blur(30px);
    transform: scale(1.1); }
  body.nav-open #feature-content {
    display: none; }

@media (max-width: 500px) {
  #feature-content .feature-questions strong {
    margin: 0 30px 20px 30px;
    font-size: 20px; }
  #feature-content .feature-questions p {
    margin: 0 70px 0 30px;
    font-size: 20px; } }

@media (orientation: portrait) and (max-width: 1300px) {
  #feature-content .feature-title,
  #feature-content .feature-lead {
    height: 10vw; }
    #feature-content .feature-title h1, #feature-content .feature-title h2,
    #feature-content .feature-lead h1,
    #feature-content .feature-lead h2 {
      font-size: 9vw; } }

.bx-wrapper {
  box-shadow: none; }
  .bx-wrapper .bx-controls-direction a {
    width: 40px;
    height: 40px; }
  .bx-wrapper img {
    margin: auto;
    /*margin: 0 auto 50px auto;*/ }
  .bx-wrapper .bx-prev {
    left: -50px;
    background: url("/dist/angle-left.svg") no-repeat 50% 50%; }
    .bx-wrapper .bx-prev:hover, .bx-wrapper .bx-prev:focus {
      background-position: 70% 50%; }
  .bx-wrapper .bx-next {
    right: -50px;
    background: url("/dist/angle-right.svg") no-repeat 50% 50%; }
    .bx-wrapper .bx-next:hover, .bx-wrapper .bx-next:focus {
      background-position: 30% 50%; }
  .bx-wrapper .bx-next,
  .bx-wrapper .bx-prev {
    background-size: 50px 50px;
    background-color: transparent;
    width: 32px;
    height: 32px; }
  .bx-wrapper .bx-caption {
    background: #ffffff;
    /*height: 50px;*/
    position: relative;
    overflow: hidden; }
    .bx-wrapper .bx-caption span {
      text-align: center;
      font-family: "TimesLTStd-Semibold", serif;
      color: #000000; }

.inline-slider.full {
  width: 100%;
  height: 100vh;
  height: calc(100vh - 130px); }
  .inline-slider.full .bx-wrapper {
    height: 100%; }
    .inline-slider.full .bx-wrapper .bx-viewport {
      height: 100%;
      overflow: visible; }
      .inline-slider.full .bx-wrapper .bx-viewport .slider {
        height: 100%; }
        .inline-slider.full .bx-wrapper .bx-viewport .slider .slide {
          width: 100%;
          height: 100%;
          background-repeat: no-repeat;
          background-position: center center; }
          .inline-slider.full .bx-wrapper .bx-viewport .slider .slide .dv-caption {
            position: absolute;
            bottom: 0;
            text-align: center;
            left: 50%;
            transform: translate(-50%, 0);
            max-width: 500px; }
    .inline-slider.full .bx-wrapper .bx-pager {
      bottom: -30px; }
    .inline-slider.full .bx-wrapper .bx-prev {
      left: 0; }
    .inline-slider.full .bx-wrapper .bx-next {
      right: 0; }

body.newsletter {
  height: 100%;
  min-height: 100%;
  background: #39fb00;
  background: -moz-linear-gradient(top, #39fb00 0%, #00fdfd 100%);
  background: -webkit-linear-gradient(top, #39fb00 0%, #00fdfd 100%);
  background: linear-gradient(to bottom, #39fb00 0%, #00fdfd 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#39fb00', endColorstr='#00fdfd',GradientType=0 ); }
  body.newsletter main {
    min-height: 100%; }
  body.newsletter section.newsletter-header {
    padding: 20px 0 40px 0;
    width: 83.33%;
    width: calc(83.33% - 50px);
    max-width: 800px;
    margin-left: 16.666%;
    margin-left: calc(16.666% + 12px); }
  body.newsletter section.newsletter {
    border-top: solid 1px #000000;
    width: 100%;
    overflow: hidden;
    border-top: solid 1px #000000;
    display: flex; }
    body.newsletter section.newsletter h1 {
      font-size: 25px;
      padding: 20px 0 40px 0;
      width: 16.666%;
      width: calc(16.666% + 12px); }
    body.newsletter section.newsletter .right {
      font-size: 25px;
      padding: 20px 0 40px 0;
      width: 83.33%;
      width: calc(83.33% - 50px);
      max-width: 800px; }

body.errors section {
  border-top: solid 1px #000000;
  width: 100%;
  overflow: hidden;
  border-top: solid 1px #000000;
  display: flex;
  padding-bottom: 20px; }
  body.errors section h1 {
    font-size: 25px;
    padding: 20px 0 40px 0;
    width: 16.666%;
    width: calc(16.666% + 12px); }
  body.errors section .right {
    padding: 20px 0 40px 0;
    width: 83.33%;
    width: calc(83.33% - 50px);
    max-width: 800px;
    font-size: 16px; }
  body.errors section article {
    margin-bottom: 20px; }
    body.errors section article h2 {
      font-size: 20px;
      margin: 0 0 20px; }
    body.errors section article p {
      font-size: 12px;
      overflow: hidden; }
    body.errors section article a.document, body.errors section article a.link {
      color: #999999;
      font-size: 16px;
      margin-top: 10px;
      display: block; }

@media (max-width: 1000px) {
  body.errors section {
    display: block; }
    body.errors section h1 {
      width: 100%; }
    body.errors section .right {
      width: 100%;
      padding-top: 0; } }

body.special #special {
  border-top: solid 1px #0000cc; }
  body.special #special article {
    max-width: 1000px;
    margin: 20px auto;
    color: #0000cc; }
    body.special #special article a, body.special #special article a:visited, body.special #special article a:hover, body.special #special article a:active {
      color: #0000cc;
      font-style: italic; }
