.btn{
    border: solid 1px $color-black;
    border-radius: 15px;
    padding: 8px 10px 5px 10px;
    width: 100%;
    display: block;
    text-align: center;
    cursor: pointer;
    
    &:hover{
        background: $color-black;
        color: $color-white;
    }
    
    
}
.loadmore{
    padding: 20px 50px;
    
    a{
        max-width: 300px;
        margin: auto;
    }
}
