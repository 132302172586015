body.special{
    
    #special{
        border-top: solid 1px $color-blue;
        
        article{
            max-width: 1000px;
            margin: $space auto;
            color: $color-blue;
            
            a, a:visited, a:hover, a:active{
                color: $color-blue;
                font-style:italic;
            }
        }
    }
    
}