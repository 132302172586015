.bx-wrapper{
    box-shadow: none;
    .bx-controls-direction a {
        width: 40px;
        height: 40px;
    }
    img {
        margin:auto;
        /*margin: 0 auto 50px auto;*/
    }
    .bx-prev {
        left: -50px;
        background: url('/dist/angle-left.svg')  no-repeat 50% 50%;
        &:hover, &:focus{
            background-position: 70% 50%;
        }
    }
    .bx-next {
        right: -50px;
        background: url('/dist/angle-right.svg') no-repeat 50% 50%;
        &:hover, &:focus{
            background-position: 30% 50%;
        }
    }
    .bx-next,
    .bx-prev {
        background-size: 50px 50px;
        background-color: transparent;
        width: 32px;
        height: 32px;
    }
    .bx-caption{
        background: $color-white;
        /*height: 50px;*/
        position: relative;
        overflow: hidden;
        
        span{
            text-align: center;
            font-family: $font;
            color: $color-black;
        }
    }
}
.inline-slider.full{
    width: 100%;
    height: 100vh;
    height: calc(100vh - 130px);
    
    .bx-wrapper{
        height: 100%;
        
        .bx-viewport{
            height: 100%;
            overflow: visible;
            
            .slider{
                height: 100%;
                
                .slide{
                    width: 100%;
                    height: 100%;
                    background-repeat: no-repeat;
                    background-position: center center;
                    
                    .dv-caption{
                        position: absolute;
                        bottom: 0;
                        text-align: center;
                        left: 50%;
                        transform: translate(-50%, 0);
                        max-width: 500px;
                    }
                }
            }
        }
        .bx-pager{
            bottom: -30px;
        }
        .bx-prev {
            left: 0;
        }
        .bx-next {
            right: 0;
        }
    }
}