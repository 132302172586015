#agenda-container{
    display: flex;
    height: 80vh;
    height: calc(100vh - 25vh);
}

#events{
    width: 67%;
   
    overflow: hidden;
    border-top: solid 1px $color-black;
    flex-grow: 2;
    cursor: pointer;
    
    h1{
        font-size: $font-size1;
        font-weight: normal;
        padding: 13px 0 7px 0;
        border-bottom: 1px solid $color-black;
        height: 48px;
        margin-right: 25px;
    }
    
    .inner{
        overflow-y: auto;
        overflow-x: hidden;
        height: 100%;
        width: 110%;
        width: calc(100% + 40px);
        -webkit-overflow-scrolling: touch;
    }
}
#separator-vertical{
    margin-left: 15px;
    padding-right: 15px;
    border-left: 1px solid $color-black;
    flex-grow: 0;
}
#day{
    width: 32%;
    overflow: hidden;
    border-top: solid 1px $color-black;
    flex-grow: 1;
    
    .inner{
        overflow-y: scroll;
        overflow-x: hidden;
        height: 100%;
        width: 110%;
        width: calc(100% + 40px);
    }

}
#calendar{
    padding-bottom: 10px;
    margin-right: 28px;
    border-bottom: solid 1px $color-black;
}
#events_day{
    margin-right: 28px;
    
    .inner{
        overflow: auto;
        height: 100%;
        width: 110%;
        width: calc(100% + 40px);
    }
    
    h2.date{
        padding: 10px 0 10px 0;
        font-size: $font-size1;
        border-bottom: solid 1px $color-black;
    }
    
    article{
        padding-bottom: $space/2;
        small{
            display: none;
        }
    }
}

article.event{
    display: flex;
    border-bottom: solid 1px $color-black;
    padding: $space/2 0 $space/2 0;
    margin-right: 25px;
    min-height: 100px;
    position: relative;
    
    
    h2{
        font-size: $font-size1;
        flex-grow: 1;
        margin: 0;
        padding: 0;
        margin-right: $space;
        padding-bottom: 35px;

    }
    span{
        flex: 0 0 250px;        
        margin-right: $space;
        font-size: $font-size1;
        
        &.time{
            flex: 0 0 100px;        
        }
    }
    div{
        flex: 0 0 150px;
        height: 150px;
        width: 150px;
        background: #ccc;
        background-size: cover;
        margin-right: 15px;
    }
    small{
        position: absolute;
        bottom: 10px;
        font-size: $font-size3;
    }
}

#agenda-content,
#agenda-title{
    border-top: solid 1px $color-black;

    width: 100%;
    overflow: hidden;
    border-top: solid 1px $color-black;
    display: flex;

    h1{
        font-size: $font-size1;
        padding: $space $space $space*2 0;
        width: $scale6; 
        width: calc(#{$scale6} + 12px);
    }
    .right{
        padding: $space 0 $space*2 0;
        width: $scale6*5; 
        width: calc(#{$scale6*5} - 50px);
        max-width: 800px;
        font-size: $font-size1;
        
        &.notitle{
            margin-left: $scale6;
            margin-left: calc(#{$scale6} + 12px);
        }
        .location{
            /*margin-top: $space;*/
        }
        
        .description{
            font-size: $font-size3;
            margin-top: $space;
        }
        .link{
            font-size: $font-size3;
            margin-top: $space;
            text-decoration: underline;
        }
    }
    .inline-slider{
        margin-top: $space;
    }
}

#calendar{
    .ui-datepicker {
        width: 100%;
    }
    .ui-datepicker-header {
        border-top: 0;
    }
    .ui-datepicker-month {
        padding-right: 0;
    }
    .ui-datepicker-year {
        padding-left: 0;
    }
}
@media(max-width:1000px){
    article.event{
        min-height: 170px;
        flex-direction: column;
        
        h2{
            padding-bottom: 10px;
            padding-right: 150px;
            flex-grow: 0;
        }
        span{
            padding-bottom: 30px;
            padding-right: 150px;
            flex-basis: auto;
        }
        div{
            position: absolute;
            top: 10px;
            right: 0;
        }
    }
    
    
    
    #separator-vertical{
        display: none;
    }
    #day{
        display: none;
    }
    #events{
        margin-right: 0;
    }
    #agenda-content,
    #agenda-title{
        display: block;
        h1{
            width: 100%; 
        }
        .right{
            width: 100%;
            &.notitle{
                margin-left: 0;
            }
        }
    }
}
@media(max-width:750px){
    .mbh{
        display: none;
    }
}
@media(max-width:450px){
    #events{
        h1{
            font-size: $font-size2;
        }
    }
    article.event{
        min-height: 120px;

        h2{
            font-size: $font-size2;
            padding-right: 100px;
        }
        span{
            font-size: $font-size2;
            padding-right: 100px;
            flex-basis: auto;
            padding-bottom: 10px;
        }
        div{
            flex: 0 0 100px;
            height: 100px;
            width: 100px;
        }
        small{
            display: none;
        }
    }
}
/*
@media(max-width:450px){
    #agenda-content,
    #agenda-title{
        h1{
            font-size: $font-size2; 
        }
        .right{
            font-size: $font-size2; 
        }
        div{
            &.lead{
                font-size: $font-size2; 
            }
        }
    }
    article.event{
        display: block;
        
        h2{
            width: 100%;
            width: calc(100% - 40px);
            padding-top: 35px;
            padding-bottom: 10px;
            padding-right: 0;
            font-size:  $font-size2
        }
        span{
            margin-right: $space;
            font-size: $font-size2;
            padding-right: 0;

            &.time{
                flex: 0 0 100px;        
            }
        }
        div{
            width: 100vw;
            height: 100vw;
            width: calc(100vw - 40px);
            height: calc(100vw - 40px);
            margin-bottom: 15px;
            position: relative;
        }
        small{
            position: absolute;
            bottom: auto;
            top: 10px
        }
    }
}
*/