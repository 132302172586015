body.about{
    section{
        border-top: solid 1px $color-black;
        
        width: 100%;
        overflow: hidden;
        border-top: solid 1px $color-black;
        display: flex;
        padding-bottom: $space;

        h1{
            font-size: $font-size1;
            padding: $space 0 $space*2 0;
            width: $scale6; 
            width: calc(#{$scale6} + 12px);
        }
        .right{
            padding: $space 0 $space*2 0;
            width: $scale6*5; 
            width: calc(#{$scale6*5} - 50px);
            max-width: 800px;
            font-size: $font-size3;
        }
        .full{
            padding: $space 0 $space*2 0;
            width: $scale6*5; 
            width: calc(#{$scale6*5} - 50px);
            font-size:  $font-size1;

            .right{
                padding: 0;
            }
        }
        article{
            margin-bottom: $space;

            h2{
                font-size: $font-size1;
                margin: 0 0 $space;
            }
            p{
                font-size: 12px;
                overflow: hidden;
            }
            a.document, a.link{
                color: $color-grey;
                font-size: $font-size3;
                margin-top: 10px;
                display: block;
            }
        }
    }
}

#sponsors{
    .sponsors{
        padding: 10px;
        float: left;
        
        img{
            height: 100px;
        }
    }
}

#benefits{
    width: 100%;
    overflow: hidden;
    border-top: solid 1px $color-black;
    display: block;
    
    h1{
        width: 100%;
        font-size: $font-size1;
        padding: $space 0 $space*3 0;
    }

    article{
        width: 100%;
        overflow: hidden;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 30px;

        h2{
            font-size: $font-size1;
            width: $scale6; 
            width: calc(#{$scale6} + 12px);
            margin: 0;
        }
        p{
            width: $scale6*5; 
            width: calc(#{$scale6*5} - 50px);
            max-width: 800px;
            font-size: $font-size3;
        }
        &:nth-of-type(6n + 6){
            margin-right: 0;
        }
        a.document,a.link{
            color: $color-grey;
            font-size: $font-size3;
            margin-top: 10px;
            display: block
        }
    }
}
#disclaimer1,#disclaimer2,#disclaimer3,#disclaimer4,#disclaimer5,#disclaimer6{
    article{
        width: $scale6*5; 
        width: calc(#{$scale6*5} - 50px);
        overflow: hidden;
        display: flex;
        margin-bottom: 20px;

        h2{
            font-size: $font-size3;
            flex: 0 0 50px; 
            margin: 0;
        }
        p{
            max-width: 800px;
            font-size: $font-size3;
            flex-grow: 1;
        }
    }
}
#board{
    width: 100%;
    overflow: hidden;
    border-top: solid 1px $color-black;
    display: block;
    

        .right{
            padding: $space 0 $space*2 0;
            width: $scale6*5; 
            width: calc(#{$scale6*5} - 50px);
            max-width: 800px;
        }

    article{
        margin-bottom: $space;
        display: flex;
        
        h1,h2{
            font-size: $font-size1;
            padding: $space $space $space*2 0;
            width: $scale6; 
            width: calc(#{$scale6} + 12px);
        }
        .right{
            padding: $space 0 $space*2 0;
            width: $scale6*5; 
            width: calc(#{$scale6*5} - 50px);
            max-width: 800px;
        }
    }
}
@media(max-width:1000px){
    body.about{
        section{
            display: block;
            h1{
                width: 100%; 
            }
            .right{
                width: 100%; 
                padding-top: 0;
            }
        }
    }
    #benefits{
        article{
            display: block;
            h2{
                width: 100%; 
                font-size: $font-size2;
                margin-bottom: 10px;
            }
            p{
                width: 100%; 
            }
            a.document, a.link{
                margin-left: 0;
            }
        }
    }
    #board{
        article{
            display: block;

            h2{
                width: 100%; 
                font-size: $font-size2;
                margin-bottom: 10px;
                padding: 0 20px 0 0;
            }
            .right{
                width: 100%; 
                padding-top: 0;
            }
        }
    }
}