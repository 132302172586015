.file-upload{
    display: block; 
    visibility: hidden; 
    width: 0; 
    height: 0;
}
.file-upload-result,
.document-upload-result,
.image-upload-result{
    margin-top: $space;
}
.fileupload-progress {
    background: $color-grey;
    width: 100%;
    div {
        background: $color-highlight;
        text-align: right;
        span{
            padding: 5px;
        }
    }
}
.image-item{
    border: solid 1px $color-black;
    display: inline-block;
    margin-right: 2%;
    margin-bottom: $space;
    width: 48%;
    min-height: 150px;
    position: relative;
    
    &:nth-child(2n+2){
        margin-right: 0;
    }
    &:hover{
        .image-container {
            background-size: contain;
        }
    }
    .delete-image, .paint-image, .move-image, .rotate-image{
        position: absolute;
        background: $color-black;
        width: 40px;
        height: 40px;
        z-index: 10;
        top: $space/2;
        right: $space/2;
        cursor: pointer;
        
        svg {
            width: 30px;
            height: 30px;
            fill: $color-white;
            margin: 5px;
        }
    }
    .move-image{
        right: auto;
        bottom: auto;
        left: $space/2;
        top: $space/2;
    }
    .rotate-image{
        background: $color-grey;
        left: auto;
        top: auto;
        right: $space/2;
        bottom: 82px;
    }
    .paint-image{
        background: $color-grey;
        left: auto;
        top: auto;
        right: $space/2;
        bottom: $space/2+27px;
    }
    
    .image-container {
        width: 100%;
        height: 150px;
        border: 0;
        z-index: 1;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: $color-black;
    }
    
    input{
        border: 0;
        border-top: solid 1px $color-black;
        width: 100%;
        font-size: $font-size3;
        line-height: 12px;
        padding: 5px;
        font-family: $font;
    }
    .formcheck-icon{
        height: 1rem;
        width: 1rem;
        fill: $color-highlight;
        position: absolute;
        bottom: .3rem;
        right: .3rem;
    }

}
.document-item{
    border: solid 1px $color-black;
    display: inline-block;
    margin-bottom: $space;
    width: 100%;
    min-height: 30px;
    position: relative;
    background: $color-grey2;
    
    .delete-document, .move-document{
        position: absolute;
        background: $color-black;
        width: 40px;
        height: 40px;
        z-index: 10;
        top: $space/2;
        right: $space/2;
        cursor: pointer;
        
        
        svg {
            width: 30px;
            height: 30px;
            fill: $color-white;
            margin: 5px;
        }
    }
    .move-document{
        right: auto;
        bottom: auto;
        left: $space/2;
        top: $space/2;
    }
    
    .document-container {
        width: 100%;
        min-height: 30px;
        border: 0;
        z-index: 1;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: $color-grey2;
        padding: $space $space $space 60px;
        overflow: hidden;
    }
    
    input{
        border: 0;
        border-top: solid 1px $color-black;
        width: 100%;
        font-size: $font-size3;
        line-height: 12px;
        padding: 5px;
        font-family: $font;
    }
    .formcheck-icon{
        height: 1rem;
        width: 1rem;
        fill: $color-highlight;
        position: absolute;
        bottom: .3rem;
        right: .3rem;
    }

}
.img-upload-4{
    margin-top: $space;
    .image-item{                
        margin-right: 2%;
        margin-bottom: $space;
        width: 23%;
        min-height: 150px;
    }
}
@media(max-width:450px){
    .image-item{
        /*display: block;*/
        margin-right: 0;
        width: 100%;
        min-height: 250px;
        .image-container {
            height: 250px;
        }
    }
}