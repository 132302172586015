body.service{
    section{
        width: 100%;
        overflow: hidden;
        border-top: solid 1px $color-black;
        display: flex;
        
        &.layer{
            display: block;
        }
        
        padding-bottom: $space;

        h1{
            font-size: $font-size1;
            padding: $space 0 $space*2 0;
            width: $scale6; 
            width: calc(#{$scale6} + 12px);
        }
        .right{
            padding: $space 0 $space*2 0;
            width: $scale6*5; 
            width: calc(#{$scale6*5} - 50px);
            max-width: 800px;
            font-size: $font-size3;
        }
        .full{
            padding: $space 0 $space*2 0;
            width: $scale6*5; 
            width: calc(#{$scale6*5} - 50px);
            font-size:  $font-size1;

            .right{
                padding: 0;
            }
        }
        article{
            margin-bottom: $space;

            h2{
                font-size: $font-size1;
                margin: 0 0 $space;
            }
            p{
                font-size: 12px;
                overflow: hidden;
            }
            a.document, a.link{
                color: $color-grey;
                font-size: $font-size3;
                margin-top: 10px;
                display: block;
            }
        }
    }
    #benefits{
        display: flex;
        
        h1{
            font-size: $font-size1;
            padding: $space 0 $space*2 0;
            width: $scale6; 
            width: calc(#{$scale6} + 12px);
        }
        article{
            width: 100%;
            overflow: hidden;
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 30px;
            border-top: solid 1px $color-black;

            h2{
                padding: $space 0 $space*2 0;
                font-size: $font-size1;
                width: $scale6*2; 
                width: calc(#{$scale6*2} + 12px);
                margin: 0;
            }
            p{
                padding: $space 0 $space 0;
                width: $scale6*4; 
                width: calc(#{$scale6*4} - 50px);
                max-width: 800px;
                font-size: $font-size3;
            }
            &:first-of-type{
                border-top: 0;

                h2,
                p{
                    padding-top: 0;
                }
            }
            &:nth-of-type(6n + 6){
                margin-right: 0;
            }
            a.document,a.link{
                color: $color-grey;
                font-size: $font-size3;
                margin-top: 10px;
                display: block
            }
        }
        .right{
            padding: $space 0 $space*2 0;
            width: $scale6*5; 
            /*width: calc(#{$scale6*5} - 50px);*/
            max-width: 800px;
            font-size: $font-size3;
            
            &.noflex{
                article{
                    display: block;
                }
            }
        }
        
    }
    
    #featured{
        width: 100%;
        overflow: hidden;
        border-top: solid 1px $color-black;

        .full{
            padding: $space 0 $space*2 0;
            width: $scale6*5; 
            width: calc(#{$scale6*5} - 50px);
            font-size:  $font-size1;

            a {
                color: $color-black;
            }
        }
        .featurerow{
            border-top: solid 1px $color-black;
            padding: 15px 0;
            font-size: $font-size1;
            display: flex;

            a{
                flex-grow: 2; 
                display: inline-block;

                &.name{
                    flex: 30% 0 0;
                    margin-right: $space;
                }
                &.podcast{
                    flex: 50px 0 0;
                    margin: -10px 0;
                    height: 50px;
                    
                    svg{
                        width: 50px;
                        height: 50px;
                    }
                }
            }
        }
    }
    
    
    #jobs{
        article.jobs{
            display: flex;
            width: 100%;
            border-top: solid 1px $color-black;
            padding: $space/2 0 0 0;
            position: relative;
            cursor: pointer;


            h2{
                font-size: $font-size1;
                flex-grow: 2;
                margin: 0;
                padding: 0;
                margin-right: 50px;
            }
            .employer{
                flex: 0 0 300px;    
            }
        }
    }
}


@media(max-width:1100px){
    body.service{
        #benefits,
        #featured,
        #jobs,
        section{
            .right{
                max-width: 100%;
            }
            .full{
                width: 100%;
            }
        }
        section{
            .right{
                max-width: 100%;
            }
        }
        
    }
}
@media(max-width:1000px){
    body.service{
        #benefits,
        #featured,
        #jobs,
        section{
            display: block;
            h1 {
                width: 100%;
            }
            .right {
                width: 100%;
            }
        }
        #benefits{
            article{
                border: 0;
                
                h2{
                    font-size: $font-size2;
                }
                p{
                    padding-bottom: 0;
                }
            }
        }

    }
}
@media(max-width:800px){
    body.service{
        #benefits{
            
            article{
                display: block;
                h2{
                    width: 100%; 
                    font-size: $font-size2;
                    margin-bottom: 10px;
                    padding-bottom: 0;
                }
                p{
                    width: 100%; 
                    padding: 0;
                }
                a.document, a.link{
                    margin-left: 0;
                }
            }
        }
        #featured{
            .featurerow{
                font-size: $font-size2;
            }
        }
        #jobs{
            article.jobs{
                font-size: $font-size2;
                h2{
                    font-size: $font-size2;
                }
            }
        }
    }
}
@media(max-width:600px){
    body.service{
        #featured{
            .featurerow{
                a.name{
                    flex: calc(100% - 70px) 0 0;
                }
                a.hm{
                    display: none;
                }
            }
        }
        #jobs{
            article.jobs{
                .employer{
                    display: none;
                }
            }
        }
    }
}