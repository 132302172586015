footer{
    margin: 50px 0;
    padding: $space 0;
    border-top: solid 1px $color-black;
    font-size: $font-size3;
    
    ul{
        overflow: hidden;
        margin: $space 0;
        padding: 0;
        
        li{
            float: left;
            list-style: none;
            margin-right: $space;
            
            a{
                color: $color-grey;
            }
        }
    }
}
body.alumni footer {
    margin-right: 50px;
}
body.prix footer {
    border-top: solid 1px $color-white;
}
body.special.rose footer {
    border-top: solid 1px $color-blue;
}
body.home {
    footer{
        display: none
    }
    &.feature-read{
        footer{
            display: block;
            margin: 50px
        }
    }
}
