body.fixedheader{
    header{
        position: fixed;
        top: 0;
        z-index: 90;
        background: $color-white;
    }
    &.home{
        header{
            background: none;
        }
    }
}
body.noheader{
    header{
        display: none;
    }
}
body.prix,
body.story{
    background: $color-black;
    
    header{
        background: $color-black;
    }
}
body.special.rose{
    background: $color-rose;
    
    header{
        background: $color-rose;
        
        h1{
            svg{
                fill: $color-blue;
            }
        }
    }
}
header{
    position: relative;
    width: 100%;
    
    &.fixed{
        position: fixed;
        top: 0;
    }
    
    .menu-button {
        height: 50px;
        width: 59px;
        position: absolute;
        width: 59px;
        height: 50px;
        top: 40px;
        right: 40px;
        z-index: 1200;
        pointer-events: none; 
        cursor: pointer;
        fill: $color-black;
        filter: drop-shadow(0 1px 2px #999);
    }
    
    h1{
        display: block;
        text-align: center;
        cursor: pointer;
        
        
        svg{
            height: 11vh;
            margin: 3vh auto;
            display: block;
            filter: drop-shadow(2px 3px 4px #CCC);
            &:hover{
                filter: drop-shadow(1px 1px 2px #CCC);
            }
        }
    }
}    
body.home{
    .menu-button {
        fill: $color-white;
    }

}
@media (orientation: portrait) {
    header{
        h1{
            svg{
                height: 7vw;
                /*height: auto;
                width: calc(100vw - 35px);*/
                margin-left: 20px;
            }
        }
    }
}    

@media(max-width:1350px){
    header{
        .menu-button {
        }

        h1{
            svg{
                padding-right: 8vh;
            }
        }
    }    
}
@media(max-width:950px){
    header{
        .menu-button {
            top: 20px;
            right: 20px;
        }
    }    
}